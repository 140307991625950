// ------------------------------------
// Panel utilities
// Used by Panel, Popup, Header Burger Menu
// ------------------------------------
/// @access private
// ------------------------------------

@use 'colors';
@use 'cssvars' as *;

$panel-bg-color: cssvar(box-bg-color) !default;
$panel-border-radius: cssvar(primary-border-radius) !default;
$panel-side-narrow-width: 23.75em !default;

@mixin overflowing-gradient($bg-color: $panel-bg-color) {
  overflow-y: auto;
  background:
    // shadow-covers
    linear-gradient($bg-color, colors.$transparent),
    linear-gradient(colors.$transparent, $bg-color),
    // shadows
    linear-gradient(cssvar(box-shadow-subtle-color), colors.$transparent),
    linear-gradient(colors.$transparent, cssvar(box-shadow-subtle-color));
  background-attachment:
    local,
    local,
    scroll,
    scroll;
  background-repeat: no-repeat;
  background-position:
    0 0,
    0 100%,
    0 0,
    0 100%;
  background-size:
    100% cssvar(spacing-4),
    100% cssvar(spacing-4),
    100% cssvar(spacing-1),
    100% cssvar(spacing-1);
}
