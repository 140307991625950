@use 'sass:list';

// Global variables
$transition-duration: 250ms;
$transition-function: ease;

// ------------------------------------
// Generic transition rule
// ------------------------------------
@mixin transition($rules...) {
  $transition: ();

  @each $rule in $rules {
    $transition: list.append(
        $transition, ($rule $transition-duration $transition-function), $separator: comma
    );
  }
  transition: $transition;
}
