// ------------------------------------
// default browser styles reset mixins
// ------------------------------------
/// @access private
// ------------------------------------
// resets default browser styles for certain elements to achieve consistent look and feel on all platforms
// ------------------------------------

@use 'cssvars' as *;

@mixin btn-reset($padding: 0, $font-size: cssvar(font-size-primary)) {
  @if $padding != false {
    padding: $padding;
  }
  @if $font-size != false {
    font-size: $font-size;
  }
  border: 0;
  background: transparent;
  font-family: cssvar(font-family-primary);
  cursor: pointer;
  appearance: none;
}

@mixin list-reset($inline: false, $padding: 0, $margin: 0) {
  margin: $margin;
  padding: $padding;
  list-style-type: none;

  @if $inline == true {
    & > li {
      display: inline-block;
    }
  }
}
