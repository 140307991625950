// ----------------------------------------------
// Range input (.[prefix-]range & [type='range'])
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;
@use '../helpers/a11y' as *;

// ------------------------------------
// style variables
// ------------------------------------
$input-range-thumb-size: cssvar(spacing-5) !default;
$input-range-thumb-size-small: cssvar(spacing-3) !default;
$input-range-track-height: 2px !default;
$input-range-track-placement: calc(#{$input-range-thumb-size} / 2) !default;
$input-range-track-placement-small: calc(#{$input-range-thumb-size-small} / 2) !default;

// we do not use tracks
@mixin track {
  border: 0;
  background: transparent;
}

@mixin thumb {
  box-sizing: border-box;
  width: $input-range-thumb-size;
  height: $input-range-thumb-size;
  transition: border $transition-duration $transition-function, background $transition-duration $transition-function;
  border: cssvar(spacing-half) solid cssvar(ui-primary-color);
  border-radius: 100%;
  background: cssvar(box-bg-color);
  cursor: pointer;
}

@mixin thumb-focus {
  @include focus-state-style;
}

#{$config-class-prefix} {
  &range {
    $range: &;
    position: relative;
    font-size: 0;

    @include input-info-text;

    &__label {
      display: block;
      color: cssvar(content-primary-color);
      font-size: cssvar(font-size-primary);
      word-break: break-all;

      @include on-bp(xl) {
        color: cssvar(content-secondary-color);
      }

      &--hidden {
        @extend #{$config-class-prefix}sr-only;
      }
    }

    &__value {
      @include show-as-small-text;
      display: inline-block;
      width: 50%;
      margin-bottom: cssvar(spacing-half);
      word-break: break-all;

      &--max {
        text-align: right;
      }
    }

    &__field {
      position: relative;
      z-index: z('base');
      height: $input-range-thumb-size;
      overflow: visible;

      &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        top: 50%;
        right: $input-range-track-placement;
        left: $input-range-track-placement;
        height: $input-range-track-height;
        margin-top: -($input-range-track-height * 0.5);
        border-radius: cssvar(primary-border-radius);
        background: cssvar(input-border-color);
      }
    }

    [type='range'] {
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: 0;
      width: 100%;
      height: $input-range-thumb-size;
      margin: 0;
      padding: 0;
      overflow: visible;
      outline: none;
      background: transparent;
      appearance: none;

      &::-webkit-slider-runnable-track {
        @include track;
        border: 0 solid cssvar(ui-primary-color);
      }

      &::-webkit-slider-thumb {
        @include thumb;
        position: relative;
        z-index: 5;
        outline: 0;
        pointer-events: all;
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-appearance: none;
      }

      &::-moz-range-track {
        @include track;
      }

      &::-moz-range-thumb {
        @include thumb;
        pointer-events: all;
      }

      &::-moz-focus-outer {
        border: 0;
      }

      &::-ms-track {
        @include track;
        color: transparent;
      }

      &::-ms-fill-lower {
        border: 0;
        background: transparent;
      }

      &::-ms-fill-upper {
        border: 0;
        background: transparent;
      }

      &::-ms-thumb {
        @include thumb;
        margin-top: 0;
      }

      &:enabled {
        &:focus-visible {
          &::-webkit-slider-thumb {
            @include thumb-focus;
          }

          &::-moz-range-thumb {
            @include thumb-focus;
          }

          &::-ms-thumb {
            @include thumb-focus;
          }

          & ~ #{$range}__steps {
            opacity: 1;
          }
        }

        &:active {
          &::-webkit-slider-thumb {
            @include pressed-state-style;
          }

          &::-moz-range-thumb {
            @include pressed-state-style;
          }

          &::-ms-thumb {
            @include pressed-state-style;
          }

          & ~ #{$range}__steps {
            opacity: 1;
          }
        }

        &:hover {
          &::-webkit-slider-thumb {
            @include hover-state-style;
          }

          &::-moz-range-thumb {
            @include hover-state-style;
          }

          &::-ms-thumb {
            @include hover-state-style;
          }

          & ~ #{$range}__steps {
            opacity: 1;
          }
        }
      }
    }

    &__steps {
      display: flex;
      position: absolute;
      z-index: 2;
      top: 0;
      right: $input-range-track-placement;
      bottom: 0;
      left: $input-range-track-placement;
      align-items: center;
      justify-content: space-between;
      opacity: 0;
      @include transition(opacity);
    }

    &__step {
      width: cssvar(spacing-1);
      height: cssvar(spacing-1);
      border-radius: 100%;
      background-color: cssvar(input-border-color);

      &--active {
        background-color: cssvar(ui-primary-color);
      }
    }

    &__track-range {
      position: absolute;
      z-index: 1;
      top: calc(#{$input-range-thumb-size} / 2);
      height: $input-range-track-height;
      margin-left: $input-range-track-placement;
      transform: translateY(-1px);
      background: cssvar(ui-primary-color);
    }

    // disabled styles
    &--disabled {
      opacity: cssvar(disabled-element-opacity);

      [type='range'],
      [type='range']:disabled {
        &::-moz-range-thumb {
          cursor: not-allowed;
        }

        &::-ms-thumb {
          cursor: not-allowed;
        }

        &::-webkit-slider-thumb {
          cursor: not-allowed;
        }
      }
    }

    // double (inner range) styles
    &--double {
      pointer-events: none;
    }

    // small styles
    &--small {
      #{$range}__field {
        height: $input-range-thumb-size-small;

        &::before {
          right: $input-range-track-placement-small;
          left: $input-range-track-placement-small;
        }
      }

      [type='range'] {
        height: $input-range-thumb-size-small;

        &::-webkit-slider-thumb {
          width: $input-range-thumb-size-small;
          height: $input-range-thumb-size-small;
        }

        &::-moz-range-thumb {
          width: $input-range-thumb-size-small;
          height: $input-range-thumb-size-small;
        }

        &::-ms-thumb {
          width: $input-range-thumb-size-small;
          height: $input-range-thumb-size-small;
        }
      }

      #{$range}__track-range {
        top: calc(#{$input-range-thumb-size-small} / 2);
        margin-left: $input-range-track-placement-small;
        transform: translateY(-1px);
      }

      #{$range}__steps {
        right: $input-range-track-placement-small;
        left: $input-range-track-placement-small;
        height: $input-range-thumb-size-small;
      }
    }
  }
}
