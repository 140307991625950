// ----------------------------------------------
// Choice Wrapper (.[prefix-]choice-wrapper)
// ----------------------------------------------

@use 'sass:map';
@use '../common/config/all' as *;
@use '../common/utils/all' as *;

$item-padding: cssvar(spacing-2);

#{$config-class-prefix} {
  &choice-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 cssvar(spacing-4);
    border: 0;

    @include input-info-text;

    &__fieldset {
      border: 0;
    }

    &__item {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      border: solid cssvar(line-primary-color);
      border-radius: cssvar(primary-border-radius);

      // ===========================
      // Here starts a clever hack.
      // Explicitly setting the min-width depending on number of wrapper items (from 2 to 4).
      // This prevents items from wrapping to the next line on super small screens.

      // selects two items
      &:first-child:nth-last-of-type(2),
      &:first-child:nth-last-of-type(2) ~ & {
        min-width: 50%;
      }

      // selects three items
      &:first-child:nth-last-of-type(3),
      &:first-child:nth-last-of-type(3) ~ & {
        min-width: 33.3333%;
      }

      // selects four items
      &:first-child:nth-last-of-type(4),
      &:first-child:nth-last-of-type(4) ~ & {
        min-width: 25%;
      }
    }

    // if the __item's are inside an --error wrapper, paint the borders danger-color
    // we will reset the internal borders to grey further on, since it changes according to the layout
    &--error {
      & #{$config-class-prefix}choice-wrapper__item {
        border-color: cssvar(danger-color);
        @include error-state-background-style;
      }

      & #{$config-class-prefix}choice-wrapper__info:first-of-type {
        color: cssvar(danger-dark-color);
      }
    }

    // since we are making the whole item clickable, the label must contain the item-padding
    // for that matter, we must adjust the radio and checkbox sizes and spacings
    #{$config-class-prefix}radio,
    #{$config-class-prefix}checkbox {
      flex-grow: 1;

      &__label {
        display: block;
        height: 100%;
        min-height: calc(#{map.get($choice-sizes, md)} + #{$item-padding} * 2);
        margin: 0; // resetting the margin for "bleeding" out hover state inherited from checkbox and radio
        padding: $item-padding;
        padding-left: calc(#{map.get($choice-sizes, md)} + #{$item-padding} * 2);

        &::before {
          margin-left: calc(-1 * #{map.get($choice-sizes, md)} - #{$item-padding});
          float: left;
        }

        &::after {
          top: $item-padding;
          left: $item-padding;
        }
      }
    }

    // default layout
    &:not(&--column) &__item {
      border-width: 1px 1px 1px 0;

      // we want the items **horizontal** borders to be collapsed and the component to have rounded corners
      // here we add a left border and retouch the right border color for the first element
      &:first-of-type {
        border-left-width: 1px;

        // we want the inner borders to be square and grey only if the first item is not single item
        &:not(:only-of-type) {
          border-radius: cssvar(primary-border-radius) 0 0 cssvar(primary-border-radius);
          border-right-color: cssvar(line-primary-color);

          #{$config-class-prefix}radio__label,
          #{$config-class-prefix}checkbox__label {
            border-radius: cssvar(primary-border-radius) 0 0 cssvar(primary-border-radius);
          }
        }
      }

      // and here we retouch the left border color for the last element only if it's not a single item
      &:last-of-type:not(:only-of-type) {
        border-radius: 0 cssvar(primary-border-radius) cssvar(primary-border-radius) 0;
        border-left-color: cssvar(line-primary-color);

        #{$config-class-prefix}radio__label,
        #{$config-class-prefix}checkbox__label {
          border-radius: 0 cssvar(primary-border-radius) cssvar(primary-border-radius) 0;
        }
      }

      // finally we paint all inner borders grey and remove inner radius
      &:not(:first-of-type):not(:last-of-type) {
        border-radius: 0;
        border-right-color: cssvar(line-primary-color);
        border-left-color: cssvar(line-primary-color);

        #{$config-class-prefix}radio__label,
        #{$config-class-prefix}checkbox__label {
          border-radius: 0;
        }
      }
    }

    // column layout
    &--column {
      flex-direction: column;
    }

    &--column &__item {
      flex-basis: auto; // ie11 fix
      min-width: 100%; // resetting min width limitations from the horizontal layout
      border-width: 0 1px 1px;

      // we want the items **horizontal** borders to be collapsed and the component to have rounded corners
      // here we add a top border and retouch the bottom border color for the first element
      &:first-of-type {
        border-top-width: 1px;

        // we want the inner borders to be square and grey only if the first item is not single item
        &:not(:only-of-type) {
          border-radius: cssvar(primary-border-radius) cssvar(primary-border-radius) 0 0;
          border-bottom-color: cssvar(line-primary-color);

          #{$config-class-prefix}radio__label,
          #{$config-class-prefix}checkbox__label {
            border-radius: cssvar(primary-border-radius) cssvar(primary-border-radius) 0 0;
          }
        }
      }

      // and here we retouch the top border color for the last element
      // we want inner borders to be square and grey only if the last item is not single item
      &:last-of-type:not(:only-of-type) {
        border-radius: 0 0 cssvar(primary-border-radius) cssvar(primary-border-radius);
        border-top-color: cssvar(line-primary-color);

        #{$config-class-prefix}radio__label,
        #{$config-class-prefix}checkbox__label {
          border-radius: 0 0 cssvar(primary-border-radius) cssvar(primary-border-radius);
        }
      }

      // finally we paint all inner borders grey and remove inner radius
      &:not(:first-of-type):not(:last-of-type) {
        border-radius: 0;
        border-top-color: cssvar(line-primary-color);
        border-bottom-color: cssvar(line-primary-color);

        #{$config-class-prefix}radio__label,
        #{$config-class-prefix}checkbox__label {
          border-radius: 0;
        }
      }

      // and remove the top margin from all __items except for the first
      &:not(:first-of-type) {
        margin-top: 0;
      }
    }

    &__content {
      display: none;
      margin: 0 cssvar(spacing-2) cssvar(spacing-2);

      &--expanded {
        display: block;
      }
    }

    &__info {
      flex-basis: 100%;
      flex-shrink: 0;
    }
  }
}
