// ------------------------------------
// Skip Link (.prefix-skip-link)
// ------------------------------------

@use '../common/config/all' as *;
@use '../helpers/a11y' as *;
@use './button' as *;

#{$config-class-prefix}skip-link {
  @extend #{$config-class-prefix}sr-only-focusable;
  @extend #{$config-class-prefix}btn;
  // revert white-space behavior from button to allow multi lines, specially on big fonts
  // @todo consider also removing this for buttons
  white-space: normal;
}
