// ----------------------------------------------
// Input (.[prefix-]input)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix}input {
  $input: &;
  position: relative;

  @include input-info-text;
  @include input-states;

  &__container {
    @include input-container($parent-element: $input);
  }

  &__field {
    order: 3;
    @include input-basic;
    @include input-on-breakpoint-xl;
    @include input-placeholder;
    @include input-decorations($icon-override: #{$input}__icon);
    @include hover-state;

    // focus state is applied to the whole __container when :has(:focus-visible)
    &:focus {
      outline: none;
    }

    #{$input}--disabled & {
      cursor: not-allowed;
    }

    // when has an icons to the left or right of the field
    // increases respective margin and padding to overlap the total size of the icon
    //
    // [1] icon size: spacing-4 (width) + 2 (margin) + 1 (margin) (total icon size to compensate on field)
    #{$input}__icon ~ & {
      margin-left: calc(-1 * (#{$input-clamped-icon-size} + #{cssvar(spacing-2)} + #{cssvar(spacing-1)})); // [1]
      padding-left: calc(#{$input-clamped-icon-size} + #{cssvar(spacing-2)} + #{cssvar(spacing-1)});
    }

    #{$input}--has-icon-right & {
      margin-right: calc(-1 * (#{$input-clamped-icon-size} + #{cssvar(spacing-2)} + #{cssvar(spacing-1)})); // [1]
      padding-right: calc(#{$input-clamped-icon-size} + #{cssvar(spacing-2)} + #{cssvar(spacing-1)});
    }

    // [2] controllers size: spacing-4 * 2 (width of 2 controls) + 2 (margin) + 1 (gap) (total controller size)
    #{$input}--has-controllers & {
      margin-right: calc(-1 * ((#{$input-icon-size} * 2) + #{cssvar(spacing-2)} + #{cssvar(spacing-1)})); // [2]
      padding-right: calc((#{$input-icon-size} * 2) + #{cssvar(spacing-2)} + #{cssvar(spacing-1)});
    }

    // [3] icon size + controllers size: 3 icons + all their margins + controllers gap (please don't do this)
    #{$input}--has-icon-right#{$input}--has-controllers & {
      margin-right: calc(-1 * ((#{$input-icon-size} * 3) + #{cssvar(spacing-4)} + #{cssvar(spacing-1)})); // [3]
      padding-right: calc((#{$input-icon-size} * 3) + #{cssvar(spacing-4)} + #{cssvar(spacing-1)});
    }
    // adding spacing for both feedback icon and custom icon
    // [4] 2 icon sizes + 3 gaps
    #{$input}--error#{$input}--has-icon-right &,
    #{$input}--valid#{$input}--has-icon-right &,
    #{$input}--loading#{$input}--has-icon-right & {
      padding-right: calc(2 * #{$input-clamped-icon-size} + #{cssvar(spacing-6)}); // [4]
    }
    // adding spacing for feedback icon and controllers
    // [5] controllers size + icon + 3 gaps
    #{$input}--error#{$input}--has-controllers &,
    #{$input}--valid#{$input}--has-controllers &,
    #{$input}--loading#{$input}--has-controllers & {
      padding-right: calc(#{$input-clamped-icon-size} + (#{$input-icon-size} * 2) + #{cssvar(spacing-6)});
    }
    // adding spacing for feedback icon + custom icon + controllers (OMG)
    // [5] controllers size + 2 icons + 4 gaps
    #{$input}--error#{$input}--has-controllers#{$input}--has-icon-right &,
    #{$input}--valid#{$input}--has-controllers#{$input}--has-icon-right &,
    #{$input}--loading#{$input}--has-controllers#{$input}--has-icon-right & {
      padding-right: calc( 2 * #{$input-clamped-icon-size} + (#{$input-icon-size} * 2) + #{cssvar(spacing-8)});
    }
  }

  &__feedback-icon {
    z-index: 0;
    flex-shrink: 0;
    order: 3;
    pointer-events: none;

    // [2] feedback-icon size: spacing-4 (width) + 2 (margin-right) (total size to compensate on field)
    #{$input}--loading &,
    #{$input}--error &,
    #{$input}--valid & {
      margin: 0 cssvar(spacing-2) 0 calc(-1 * (#{$input-clamped-icon-size} + #{cssvar(spacing-2)})); // [2]
    }
  }

  &__inline-label {
    @include input-inline-label-base;
    order: 1;
    // handling the case when the label is on the right
    #{$input}__field ~ & {
      order: 6;
      border-right: 0;
      border-left: 1px solid cssvar(input-border-color);
    }
  }

  &__controllers {
    display: flex;
    order: 5;
    gap: cssvar(spacing-1);
    margin: 0 cssvar(spacing-1);

    & #{$input}__icon {
      margin: 0;
    }

    #{$input}--has-icon-right & {
      margin-left: 0; // right icon has a big enough margin already
    }
  }

  // extra specificity to make sure to override icon styles on HC-React
  &__icon,
  &__icon#{$config-class-prefix}icon {
    order: 2;
    @include input-icon-base;

    // when icon is at the right of the field, switches margin values
    #{$input}__field ~ & {
      order: 4;
      margin-right: cssvar(spacing-2);
      margin-left: cssvar(spacing-1);
    }
  }

  // overriding button styles
  & button#{$input}__icon {
    pointer-events: auto;

    // reset style of icons inside of the button
    & #{$config-class-prefix}icon {
      width: $input-icon-size-rem;
      height: $input-icon-size-rem;
      font-size: $input-icon-size;
      line-height: $input-icon-size;
      @include flix-mask-icon-limits($icon-min-size: $input-icon-size);

      &::before {
        width: $input-icon-size-rem;
        height: $input-icon-size-rem;
        margin-right: 0;
        margin-left: 0;
        @include flix-mask-icon-limits($icon-min-size: $input-icon-size);
        @include flix-icon-font-size-limits($icon-font-size: $input-icon-size-rem, $icon-min-font-size: $input-icon-size);
      }
    }
  }
}
