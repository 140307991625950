// ----------------------------------------------
// List Wrapper element
// ----------------------------------------------
// .[prefix-]list-wrapper
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &list-wrapper {
    $list-wrapper: &;
    @include cssvars((
      list-wrapper-min-height: cssvar(spacing-8),
      list-wrapper-content-padding: cssvar(spacing-2),
      list-wrapper-interactive-content-padding: cssvar(spacing-2) cssvar(spacing-8) cssvar(spacing-2) cssvar(spacing-2),
      list-wrapper-font-weight: cssvar(font-weight-semibold),
    ));
    @include list-reset;

    &__item {
      display: flex;
      position: relative;
      align-items: stretch;
      min-height: cssvar(list-wrapper-min-height);
      border: solid cssvar(line-primary-color);
      border-width: 0 1px 1px;
      background-color: cssvar(box-bg-color);
      color: cssvar(content-primary-color);
      font-size: cssvar(font-size-primary);
      line-height: cssvar(line-height-primary);

      &:first-of-type {
        border-top-width: 1px;
      }

      &:first-of-type,
      &:first-of-type #{$list-wrapper}__content {
        border-top-left-radius: cssvar(primary-border-radius);
        border-top-right-radius: cssvar(primary-border-radius);
      }

      &:last-of-type,
      &:last-of-type #{$list-wrapper}__content {
        border-bottom-right-radius: cssvar(primary-border-radius);
        border-bottom-left-radius: cssvar(primary-border-radius);
      }

      &--error {
        border-width: 1px;
        border-color: cssvar(danger-color);

        & + & {
          border-width: 0 1px 1px;
        }
      }

      &--disabled {
        opacity: cssvar(disabled-element-opacity);

        & #{$list-wrapper}__content {
          cursor: not-allowed;
        }
      }

      // excluding disabled items from interaction state styles
      &:not(#{&}--disabled) #{$list-wrapper}__content:is(a, button) {
        @include hover-state($enabled-only: false);
        @include pressed-state($enabled-only: false);

        &:focus-visible {
          @include focus-state-style($force-round-corners: true);
          z-index: z('base');
          text-decoration: none;
        }
      }
    }

    &__content {
      display: block;
      width: 100%;
      padding: cssvar(list-wrapper-content-padding);
      color: cssvar(content-primary-color);
      font-weight: cssvar(list-wrapper-font-weight);

      // content link and button styles
      &:is(a, button) {
        @include btn-reset;
        padding: cssvar(list-wrapper-interactive-content-padding);
        text-align: left;
        text-decoration: none;
        cursor: pointer;


        &::after {
          content: '';
          @include flix-icon-arrow-right;
          position: absolute;
          top: 50%;
          right: cssvar(spacing-2);
          margin-top: calc(-1 * #{cssvar(spacing-2)});
        }
      }
    }

    // Small variation
    &--small {
      @include cssvars((
        list-wrapper-min-height: cssvar(spacing-6),
        list-wrapper-content-padding: cssvar(spacing-1),
        list-wrapper-interactive-content-padding: cssvar(spacing-1) cssvar(spacing-6) cssvar(spacing-1) cssvar(spacing-1),
        list-wrapper-font-weight: cssvar(font-weight-normal),
      ));
    }
  }
}
