// ----------------------------------------------
// (Mobile) Navigation Bar (.[prefix-]nav-bar)
// ----------------------------------------------

@use "sass:map";
@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ------------------------------------
// style variables
// ------------------------------------
$nav-tab-bar-icon-size: cssvar(spacing-4);
$nav-tab-bar-icon-size-rem: map.get($rem-spacing, 4);
$nav-tab-bar-icon-size-clamped: min(max(#{$nav-tab-bar-icon-size}, #{$nav-tab-bar-icon-size-rem}), calc(2 * #{$nav-tab-bar-icon-size}));

#{$config-class-prefix} {
  &nav-tab-bar {
    display: flex;
    position: fixed;
    z-index: z('header');
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 cssvar(spacing-1);
    background-color: cssvar(box-bg-color);
    box-shadow: cssvar(primary-box-shadow);

    &__tab {
      flex: 1;
      color: cssvar(content-secondary-color);

      @include selected-state($position: top, $highlight-bg: false);
    }

    // increased specificity on these rules to override button styles
    & &__tab {
      padding: cssvar(spacing-1);

      @include focus-state($outline-offset: 0);
    }

    &__icon {
      // width and height ensure web components icons work
      width: $nav-tab-bar-icon-size-clamped;
      height: $nav-tab-bar-icon-size-clamped;
      // limits icon-font icons size to not exceed the (original_size * 2)
      font-size: $nav-tab-bar-icon-size-clamped;
      line-height: $nav-tab-bar-icon-size-clamped;
      // makes sure font icons are aligned within the fixed width container
      // @todo remove once icon fonts are deprecated
      &:is([class*="flix-icon-"])::before {
        margin: 0;
      }
    }
  }
}
