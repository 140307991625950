@use 'config/variables' as configVariables;

// having box-sizing border-box is the only global styling requirement for the library to work nice
* {
  box-sizing: border-box;
}

// global styles for all the ".flix-" elements
[class^='#{configVariables.$config-prefix}'] {
  margin: 0;
  padding: 0;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}
