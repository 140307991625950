// ------------------------------------
// Tooltip utilities
// Used by Tooltip and Dropdown components
// ------------------------------------
/// @access private
// ------------------------------------

@use 'cssvars' as *;

// Tooltip position rules
@mixin tooltip-position-left {
  right: 100%;
  left: auto;
}

@mixin tooltip-position-right {
  left: 100%;
}

@mixin tooltip-position-top {
  top: auto;
  bottom: 100%;
}

@mixin tooltip-position-bottom($top: 100%) {
  top: $top;
  bottom: auto;
}

// Tooltip horizontal alignment rules
@mixin tooltip-alignment-start-x {
  right: auto;
  left: calc(-1 * cssvar(spacing-1));
  transform: none;
}

@mixin tooltip-alignment-center-x {
  left: 50%;
  transform: translateX(-50%);
}

@mixin tooltip-alignment-end-x {
  right: calc(-1 * cssvar(spacing-1));
  left: auto;
  transform: none;
}

// Tooltip vertical alignment rules
@mixin tooltip-alignment-start-y {
  top: calc(-1 * cssvar(spacing-1));
  bottom: auto;
  transform: none;
}

@mixin tooltip-alignment-center-y {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}

@mixin tooltip-alignment-end-y {
  top: auto;
  bottom: calc(-1 * cssvar(spacing-1));
  transform: none;
}
