// ----------------------------------------------
// Button group (.prefix-btn-group)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &btn-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: cssvar(spacing-2);
    @include optional-bottom-margin;

    &--half-gap {
      gap: cssvar(spacing-half);
    }

    &--1-gap {
      gap: cssvar(spacing-1);
    }

    &--wrap {
      flex-wrap: wrap;
    }

    &--horizontal {
      flex-direction: row;
    }

    &--vertical {
      flex-direction: column;
    }

    &--align-center {
      align-items: center;
      justify-content: center;
    }

    &--align-end {
      align-items: flex-end;
      justify-content: flex-end;
    }

    @include on-bp(sm) {
      // do not apply responsive rendering when forcing a layout
      &:not(&--vertical, &--horizontal) {
        flex-direction: row;
      }
    }
  }
}
