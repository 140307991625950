// ------------------------------------
// THIS FILE IS AUTOGENERATED
// Contents of this file are generated out of design token declarations
// Do not edit directly, refer to "/assets/tokens" folder if you need to make a change.
// ------------------------------------
@use '../common/config/variables' as *;
@use '../common/utils/cssvars' as *; // imports CSS var proxy helpers

// ------------------------------------
// THEME FILE USAGE:
//
// Theme color abstractions hold purpose bound colors.
// Themes are the way to go most of the times when referencing
// certain colors in your code, this will allow you to support multiple themes in future.
// ------------------------------------

// ------------------------------------
// FLIX-DARK HONEYCOMB THEME VARIABLES
// ------------------------------------
@include cssvars((
  // brand colors should only be used for branding purposes, not UI implementations (logos)
  brand-primary-color: #97d700,
  // primary color with shades
  ui-primary-color: #97d700,
  ui-primary-light-color: #44b300,
  ui-primary-dark-color: #cef38d,
  // gray tones
  grayscale-0-color: #151515,
  grayscale-10-color: #252525,
  grayscale-30-color: #444444,
  grayscale-50-color: #646464,
  grayscale-70-color: #8b8b8b,
  grayscale-90-color: #c8c8c8,
  grayscale-100-color: #ffffff,
  neutral-color: #039fd5,
  success-color: #44b300,
  success-dark-color: #cef38d,
  warning-color: #ff8206,
  warning-dark-color: #ffe393,
  danger-color: #fd5a5a,
  danger-dark-color: #ffc9ce,
  // use mostly for page background
  bg-primary-color: #151515,
  // use to dim over colored box backgrounds (neutral, warning, danger)
  bg-primary-transparent-color: rgba(53, 53, 53, 0.88),
  // use for secondary page background
  bg-secondary-color: #121212,
  // use for element backgrounds, e.g. boxes, notifications, dropdowns
  box-bg-color: #252525,
  highlight-color: #0a3c00,
  // use for overlays
  overlay-bg-color: rgba(0, 0, 0, 0.5),
  // use for focus outlines
  focus-outline-color: #016ae7,
  // Font colors
  content-primary-color: #ffffff,
  content-secondary-color: #c8c8c8,
  heading-font-color: #ffffff,
  link-color: #3bd0de,
  // border colors
  line-primary-color: #646464,
  icon-primary-color: #ffffff,
  icon-secondary-color: #97d700,
  box-shadow-color: rgba(0, 0, 0, 0.18),
  box-shadow-subtle-color: rgba(0, 0, 0, 0.06),
  button-primary-color: #97d700,
  button-label-color: #252525,
  header-bg-color: #252525,
  header-color: #ffffff,
  header-nav-bg-color-tablet: #202020,
  input-border-color: #c8c8c8,
  input-height-mobile: 44px,
  input-height-desktop: 36px,
  // multiplier based spacing variables, 6px acts as a base
  spacing-1: 6px,
  spacing-2: 12px,
  spacing-3: 18px,
  spacing-4: 24px,
  spacing-5: 30px,
  spacing-6: 36px,
  spacing-7: 42px,
  spacing-8: 48px,
  spacing-9: 54px,
  spacing-10: 60px,
  spacing-11: 66px,
  spacing-12: 72px,
  spacing-half: 3px,
  grid-gutter-2: 12px,
  grid-gutter-4: 24px,
  page-min-width: 320px,
  page-max-width: 1200px,
  font-size-base: 1rem,
  font-size-primary: 1rem,
  font-size-small: .875rem,
  font-size-fineprint: .75rem,
  font-size-h1: 1.7rem,
  font-size-h2: 1.3rem,
  font-size-h3: 1.1rem,
  font-size-h4: 1rem,
  line-height-primary: 1.5rem,
  line-height-small: 1.125rem,
  line-height-fineprint: .938rem,
  line-height-h1: 2rem,
  line-height-h2: 1.7rem,
  line-height-h3: 1.5rem,
  line-height-h4: 1.5rem,
  // use as a second layer for hover state color adjustment
  hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)),
  // use as a second layer for pressed state color adjustment
  pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)),
  disabled-element-opacity: 0.5,
  primary-border-radius: 6px,
  primary-box-shadow: "0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18)",
  // preferred browser color scheme
  color-scheme: dark,
  font-family-primary: "Roboto, Arial, sans-serif",
  font-weight-normal: 400,
  font-weight-semibold: 500,
  font-weight-bold: 700
), '#{$config-class-prefix}theme-flix-dark');

#{$config-class-prefix}theme-flix-dark {
  color: cssvar(content-primary-color);
  font-family: cssvar(font-family-primary);
  font-size: cssvar(font-size-base);
  font-weight: cssvar(font-weight-normal);
  line-height: cssvar(line-height-primary);
  color-scheme: cssvar(color-scheme);

  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: cssvar(font-family-primary);
  }
}

