// ----------------------------------------------
// Link (.[prefix-]link)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &link {
    // Remove the gray background on active links in IE 10.
    background-color: transparent;
    color: cssvar(link-color);
    font-weight: cssvar(font-weight-semibold);
    text-decoration: underline;

    @include pressed-state;
    @include hover-state($force-round-corners: true, $text-decoration: underline);
    @include focus-state($force-round-corners: true);
  }
}
