// ------------------------------------
// Text alignment helpers
// ------------------------------------

@use '../common/config/all' as *;

// applies different types of text alignment
#{$config-class-prefix} {
  &has-text-centered {
    text-align: center;
  }

  &has-text-left {
    text-align: left;
  }

  &has-text-right {
    text-align: right;
  }
}
