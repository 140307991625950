// ----------------------------------------------
// Breadcrumbs (.[prefix-]breadcrumbs)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &breadcrumbs {
    display: flex;
    align-items: center;

    &__item {
      display: flex;
      align-items: center;

      & + &::before {
        content: '';
        @include flix-icon-arrow-right($size: $icon-size-small-rem);
        margin: 0 cssvar(spacing-half);
      }
    }

    &__ellipsis::before {
      content: '...';
    }

    &__link:is(button) {
      @include btn-reset($padding: false, $font-size: false);
    }

    &__link {
      @include show-as-small-text($color: cssvar(content-secondary-color));
      max-width: 20ch;
      padding: cssvar(spacing-1) cssvar(spacing-half);
      overflow: hidden;
      border-radius: cssvar(primary-border-radius);
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include focus-state;
      @include pressed-state;
      @include hover-state;

      &[aria-current] {
        @include selected-state-style($position: bottom, $highlight-bg: false);
        border-radius: cssvar(primary-border-radius) cssvar(primary-border-radius) 0 0;

        // adds the paddings to the left and right of the decorative line
        &::after {
          right: cssvar(spacing-half);
          left: cssvar(spacing-half);
        }
      }

      &--no-clamp {
        max-width: fit-content;
        overflow: unset;
      }
    }
  }
}
