// ------------------------------------
// Typography mixins
//
// can be used to inherit certain typography styles in non-typography elements
// e.g. style legend as h4 etc.
// ------------------------------------
/// @access private
// ------------------------------------

@use 'cssvars' as *;

// ------------------------------------
// show-as-text
// ------------------------------------
// defines basic text typography styles
// ------------------------------------

@mixin show-as-text($color: cssvar(container-content-color, $fallback: content-primary-color)) {
  color: $color;
  font-size: cssvar(font-size-primary);
  font-weight: cssvar(font-weight-normal);
  line-height: cssvar(line-height-primary);
}

// ------------------------------------
// show-as-small-text
// ------------------------------------
// defines small text typography styles
// ------------------------------------

@mixin show-as-small-text($color: cssvar(container-content-color, $fallback: content-primary-color)) {
  color: $color;
  font-size: cssvar(font-size-small);
  font-weight: cssvar(font-weight-normal);
  line-height: cssvar(line-height-small);
}

// ------------------------------------
// show-as-fineprint
// ------------------------------------
// defines basic fineprint text typography styles
// ------------------------------------

@mixin show-as-fineprint($color: cssvar(container-content-color, $fallback: content-primary-color)) {
  color: $color;
  font-size: cssvar(font-size-fineprint);
  font-weight: cssvar(font-weight-normal);
  line-height: cssvar(line-height-fineprint);
}

// ------------------------------------
// show-as-interactive-text
// ------------------------------------
// defines interactive text typography styles, e.g.: nav items
// ------------------------------------

@mixin show-as-interactive-text($color: cssvar(content-secondary-color)) {
  @if ($color != false) {
    color: $color;
  }
  font-size: cssvar(font-size-primary);
  font-weight: cssvar(font-weight-semibold);
  line-height: cssvar(line-height-primary);
}

// ------------------------------------
// show-as-h1
// ------------------------------------
// defines basic H1 headline typography styles
// ------------------------------------

@mixin show-as-h1() {
  color: cssvar(container-content-color, $fallback: heading-font-color);
  font-size: cssvar(font-size-h1);
  font-weight: cssvar(font-weight-bold);
  line-height: cssvar(line-height-h1);
}

// ------------------------------------
// show-as-h2
// ------------------------------------
// defines basic H2 headline typography styles
// ------------------------------------

@mixin show-as-h2() {
  color: cssvar(container-content-color, $fallback: heading-font-color);
  font-size: cssvar(font-size-h2);
  font-weight: cssvar(font-weight-bold);
  line-height: cssvar(line-height-h2);
}

// ------------------------------------
// show-as-h3
// ------------------------------------
// defines basic H3 headline typography styles
// ------------------------------------

@mixin show-as-h3() {
  color: cssvar(container-content-color, $fallback: heading-font-color);
  font-size: cssvar(font-size-h3);
  font-weight: cssvar(font-weight-bold);
  line-height: cssvar(line-height-h3);
}

// ------------------------------------
// show-as-h4
// ------------------------------------
// defines basic H4 headline typography styles
// ------------------------------------

@mixin show-as-h4() {
  color: cssvar(container-content-color, $fallback: heading-font-color);
  font-size: cssvar(font-size-h4);
  font-weight: cssvar(font-weight-bold);
  line-height: cssvar(line-height-h4);
}
