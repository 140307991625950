// ----------------------------------------------
// Select group (.[prefix-]select-group)
// ----------------------------------------------
@use "sass:map";
@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ------------------------------------
// style variables
// ------------------------------------
$select-group-checkmark-size: cssvar(spacing-3);
$select-group-checkmark-size-rem: map.get($rem-spacing, 3);

#{$config-class-prefix} {
  &select-group {
    $select-group: &;
    position: relative;
    border: 0;

    @include input-info-text;

    &--error &__item {
      border-color: cssvar(danger-color);

      label {
        @include error-state-background-style;
      }

      label:hover {
        @include error-state-background-style($with-hover: true);
      }
    }

    &--disabled {
      opacity: cssvar(disabled-element-opacity);

      #{$select-group}__item label,
      #{$select-group}__item input {
        cursor: not-allowed;
      }
    }

    &__items {
      display: inline-flex;
      position: relative;
      flex-wrap: wrap;
      padding-top: 1px; // compensating 1px margin for an item
      list-style-type: none;
    }

    &__label {
      z-index: z('form-label') + 1; // moving label 1 layer up in order to be in top of focus state
    }

    // clickable select indicator
    &__item {
      min-width: cssvar(input-height-mobile);
      margin-top: -1px; // hiding duplicating borders for multiline layout
      margin-left: -1px; // hiding duplicating borders on the sides
      border: 1px solid cssvar(input-border-color);
      text-align: center;

      @include on-bp(xl) {
        min-width: cssvar(input-height-desktop);
      }

      &:first-of-type,
      &:first-of-type label {
        border-radius: cssvar(primary-border-radius) 0 0 cssvar(primary-border-radius);
      }

      // ensures top and bottom radius
      &:last-of-type,
      &:last-of-type label {
        border-radius: 0 cssvar(primary-border-radius) cssvar(primary-border-radius) 0;
      }

      // hiding the default inputs
      input {
        position: absolute;
        width: 0;
        height: 0;
        margin: 0;
        opacity: 0;
        cursor: pointer;
      }

      // label tag is required to have checkboxes work properly
      // so we can more or less safely use the tag selector
      label {
        @include show-as-interactive-text($color: cssvar(content-primary-color));
        display: flex;
        position: relative;
        align-items: center;
        // 9px top, bottom to keep 44px total height and vertical central alignment
        padding: calc(#{cssvar(spacing-2)} - #{cssvar(spacing-half)}) cssvar(spacing-3);
        column-gap: cssvar(spacing-half);
        background: cssvar(box-bg-color);
        cursor: pointer;

        @include hover-state($enabled-only: false);

        @include on-bp(xl) {
          min-width: cssvar(input-height-desktop);
          padding-top: calc(#{cssvar(spacing-1)} - 1px); // -1px to compensate borders
          padding-bottom: calc(#{cssvar(spacing-1)} - 1px); // - 1px to compensate borders
        }
      }

      input:focus-visible + label {
        @include focus-state-style($force-round-corners: true);
        z-index: z('base'); // lifting up z-index in order to fight negative margins and make the whole outline visible
      }

      input:active + label {
        @include pressed-state-style;
      }

      // active state styles
      input:checked + label {
        padding-right: cssvar(spacing-2);
        padding-left: cssvar(spacing-2);
        background-color: cssvar(highlight-color);
        @include selected-state-style($position: bottom, $pseudo: after);

        &::before {
          content: '';
          @include flix-icon-checkmark($size: $select-group-checkmark-size-rem, $color: cssvar(ui-primary-color));
          @include flix-mask-icon-limits($icon-min-size: $select-group-checkmark-size);
        }
      }

      &--disabled {
        opacity: cssvar(disabled-element-opacity);

        label,
        input {
          cursor: not-allowed;
        }
      }
    }
  }
}
