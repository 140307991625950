// ----------------------------------------------
// Button (.prefix-btn)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ------------------------------------
// style variables
// ------------------------------------
$button-size: cssvar(input-height-desktop); // defines heights and width for square buttons, used for height only in others
$button-size-mobile: cssvar(input-height-mobile);
$button-radius: cssvar(primary-border-radius);
$button-loading-spinner-size: cssvar(spacing-4);
$button-spinner-bg: rgba(0, 0, 0, 20%);

#{$config-class-prefix} {
  &btn {
    $btn: &;
    @include cssvars((
      button-bg-color: cssvar(box-bg-color),
      button-border-color: cssvar(grayscale-50-color),
      button-text-color: cssvar(content-primary-color)
    ));
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-width: 142px;
    max-width: 100%;
    min-height: $button-size-mobile;
    // splitting padding to individual declarations to simplify styles for variations,
    // as they mostly override side paddings while keeping the vertical ones
    // reducing the vertical paddings to preserve original 44px height while allowing it to scale
    padding-top: calc(#{cssvar(spacing-2)} - #{cssvar(spacing-half)} - 1px);
    padding-right: cssvar(spacing-2);
    // reducing the vertical paddings to preserve original 44px height while allowing it to scale
    padding-bottom: calc(#{cssvar(spacing-2)} - #{cssvar(spacing-half)} - 1px);
    padding-left: cssvar(spacing-2);
    gap: cssvar(spacing-half);
    border: 1px solid cssvar(button-border-color);
    border-radius: $button-radius;
    background: cssvar(button-bg-color);
    font-family: inherit;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    overflow-wrap: anywhere;
    // getting rid of default iOS webkit styles
    appearance: none;

    @include show-as-interactive-text($color: cssvar(button-text-color));
    @include transition(background);
    @include hover-state;
    @include pressed-state;
    @include focus-state;
    @include disabled-state;

    @include on-range(zero, sm) {
      display: flex;
      width: stretch;

      // modified buttons don't stretch on mobile
      // except block that stretches all the time
      &--sm,
      &--md,
      &--lg,
      &--link,
      &--square,
      &--stacked {
        display: inline-flex;
        width: auto;
      }
    }

    @include on-bp(xl) {
      min-height: $button-size;
      // reducing the vertical paddings to preserve original 36px height while allowing it to scale
      padding-top: calc(#{cssvar(spacing-1)} - 1px);
      padding-bottom: calc(#{cssvar(spacing-1)} - 1px);
    }

    &#{$config-class-prefix}btn--loading {
      background-image: cssvar(hover-layer-color);
      text-decoration: none;
    }

    &--primary {
      @include cssvars((
        button-bg-color: cssvar(button-primary-color),
        button-border-color: cssvar(button-primary-color),
        button-text-color: cssvar(button-label-color),
      ));
    }

    &--secondary {
      @include cssvars((
        button-border-color: cssvar(button-primary-color),
      ));
    }

    &--danger {
      @include cssvars((
        button-bg-color: cssvar(danger-color),
        button-border-color: cssvar(danger-color),
        button-text-color: cssvar(grayscale-0-color),
      ));
    }

    &--link {
      @include cssvars((
        button-bg-color: transparent,
        button-border-color: transparent,
        button-text-color: cssvar(container-content-color, $fallback: content-primary-color)
      ));
      min-width: auto;
      padding: 0 cssvar(spacing-half);

      &#{$btn}--loading::before {
        margin-left: 0;
      }
    }

    &--sm {
      min-width: auto;
      min-height: cssvar(spacing-4);
      padding-top: 0;
      padding-bottom: 0;
      font-size: cssvar(font-size-small);
      line-height: cssvar(line-height-small);
    }

    &--md {
      min-height: cssvar(input-height-desktop);
      // forcing smaller paddings to preserve button height on mobile
      padding-top: calc(#{cssvar(spacing-1)} - 1px);
      padding-bottom: calc(#{cssvar(spacing-1)} - 1px);
    }

    &--lg {
      min-height: cssvar(spacing-8);
      padding-top: calc(#{cssvar(spacing-2)} - #{cssvar(spacing-half)});
      padding-right: cssvar(spacing-4);
      padding-bottom: calc(#{cssvar(spacing-2)} - #{cssvar(spacing-half)});
      padding-left: cssvar(spacing-4);
    }

    &--loading {
      // animated rounded spinner
      &::before {
        content: '';
        display: inline-block;
        flex-shrink: 0;
        width: $button-loading-spinner-size;
        height: $button-loading-spinner-size;
        animation: btn-spinner-rotate 1s infinite linear, btn-spinner-grow 250ms;
        border: cssvar(spacing-half) solid $button-spinner-bg;
        border-radius: 50%;
        border-left-color: cssvar(button-text-color);
      }

      // do not animate the spinner grow when has icon because space is already occupied by the icon
      &:has(#{$config-class-prefix}btn__icon)::before,
      &:has([class^="hci-icon"])::before {
        animation: btn-spinner-rotate 1s infinite linear;
      }

      @include anim-grow-fade(
        $anim-name: 'btn-spinner-grow',
        $to-width: $button-loading-spinner-size,
        $to-height: $button-loading-spinner-size
      );
      @include anim-rotate(
        $anim-name: 'btn-spinner-rotate'
      );
    }

    // using attribute selector as icon components in react do not use injecss
    &--loading &__icon,
    &--loading [class^="hci-icon"]  {
      /* stylelint-disable-next-line declaration-no-important */
      display: none !important; // icons get replaced with the loader in square buttons, important is needed to override inline styles from .hci
    }

    &--square {
      min-width: $button-size-mobile;
      padding-right: 0;
      padding-left: 0;
      aspect-ratio: 1; // forcing the button to remain square so the width is mainly defined by the height

      @include on-bp(xl) {
        min-width: $button-size;
      }

      &#{$btn}--sm {
        min-width: cssvar(spacing-4);
      }

      &#{$btn}--md {
        min-width: cssvar(spacing-6);
      }

      &#{$btn}--lg {
        min-width: cssvar(spacing-8);
      }
    }

    &--stacked {
      flex-direction: column;
      min-width: auto;
      padding-right: cssvar(spacing-1);
      padding-left: cssvar(spacing-1);
      font-size: cssvar(font-size-small);
      line-height: cssvar(line-height-small);

      &#{$btn}--lg {
        padding-right: calc(#{cssvar(spacing-2)} - #{cssvar(spacing-half)});
        padding-left: calc(#{cssvar(spacing-2)} - #{cssvar(spacing-half)});
        font-size: cssvar(font-size-primary);
        line-height: cssvar(line-height-primary);
      }
    }

    // block buttons always stretch
    &--block {
      display: flex;
      width: stretch;
    }
  }
}
