// ------------------------------------
// input utilities
// ------------------------------------
/// @access private
// ------------------------------------

@use 'icons' as *; // pulling icon mixins and icon-size variables
@use 'cssvars' as *;
@use 'responsive' as *; // pulling in on-bp()
@use 'spinner' as *; // pulling spinner
@use 'states' as *; // pulling error state style
@use 'typography' as *; // pulling typography mixins

// ------------------------------------
// style variables
// ------------------------------------
$input-icon-size: $icon-size-small !default;
$input-icon-size-rem: $icon-size-small-rem !default;
$input-clamped-icon-size: min(max(#{$input-icon-size}, #{$input-icon-size-rem}), calc(2 * #{$input-icon-size}));

// ------------------------------------
// defines input container styles.
// container contains input field, icons and inline-label.
// ------------------------------------
@mixin input-container($parent-element) {
  display: flex;
  position: relative;
  z-index: 0;
  align-items: center;
  min-height: cssvar(input-height-mobile);
  overflow: hidden;
  border: 1px solid cssvar(input-border-color);
  border-radius: cssvar(primary-border-radius);
  background-color: cssvar(box-bg-color);
  color: cssvar(content-primary-color);

  @include on-bp(xl) {
    min-height: cssvar(input-height-desktop);
  }

  #{$parent-element}--error & {
    border-color: cssvar(danger-color);
    @include error-state-background-style;
  }

  #{$parent-element}--active &,
  &:has(:focus-visible) {
    @include focus-state-style;
  }
}

// ------------------------------------
// defines basic input styles
// ------------------------------------
@mixin input-basic {
  position: relative;
  width: 100%;
  min-height: 100%;
  // reducing the vertical paddings to preserve original 44px height while allowing it to scale
  padding: calc(#{cssvar(spacing-2)} - #{cssvar(spacing-half)} - 1px) cssvar(spacing-2);
  border: 0;
  background: transparent;
  color: cssvar(content-primary-color);
  font-family: inherit;
  font-size: cssvar(font-size-primary);
  font-weight: cssvar(font-weight-normal);
  line-height: cssvar(line-height-primary);
  text-overflow: ellipsis;
  // getting rid of default iOS webkit styles
  appearance: none;

}

// reducing the vertical paddings to preserve original 36px height while allowing it to scale
@mixin input-on-breakpoint-xl {
  @include on-bp(xl) {
    padding-top: calc(#{cssvar(spacing-1)} - 1px);
    padding-bottom: calc(#{cssvar(spacing-1)} - 1px);
    @content;
  }
}

@mixin input-placeholder {
  &::placeholder {
    opacity: 1; // reset FF placeholder opacity
    color: cssvar(content-secondary-color);
    font-style: italic;
  }
}

// ------------------------------------
// adds honeycomb flavor to native browser decorations for specific input types
// hides webkit icons if a custom icon was provided for the input
// $icon-override provides a selector to the custom icon that controls webkit controls visibility
// ------------------------------------
@mixin input-decorations($icon-override) {
  &[type='time'],
  &[type='date'],
  &[type='datetime-local'],
  &[type='month'],
  &[type='week'] {
  // some weird rendering behavior for decorated inputs show a bigger line height than their undercoated counterparts.
  // so we compensate here on the vertical paddings.
  padding-top: calc(#{cssvar(spacing-2)} - 4px);
  padding-bottom: calc(#{cssvar(spacing-2)} - 4px);

  @include on-bp(xl) {
    padding-top: calc(#{cssvar(spacing-1)} - 2px);
    padding-bottom: calc(#{cssvar(spacing-1)} - 2px);
  }

    // hides spinner buttons for dates on older chrome versions
    &::-webkit-inner-spin-button {
      appearance: none;
    }

    &::-webkit-calendar-picker-indicator {
      @include flix-mask-icon-limits($icon-min-size: $input-icon-size);
      // stylelint-disable-next-line declaration-no-important
      display: block !important; // always shows icon on older chrome versions
      order: -1; // moves icon to the left
      width: $input-icon-size;
      height: $input-icon-size;
      margin-right: cssvar(spacing-1);
      margin-left: 0;
      padding: 0;
      opacity: 1; // always shows icon on older chrome versions
      color: transparent; // hides old chrome versions default content text
    }

    &::-webkit-clear-button {
      // older chrome versions show a clear button
      @include flix-icon-close($size: $input-icon-size-rem);
      @include flix-mask-icon-limits($icon-min-size: $input-icon-size);
      background-image: none; // remove browser default background-image
      appearance: none;
    }

    // hides native webkit icon if a custom icon is provided in the input
    // only works in chrome at this point
    @if($icon-override) {
      #{$icon-override} ~ &::-webkit-calendar-picker-indicator {
        // moves browser icon underneath the custom one allowing for native browser controls to be activated on click
        margin-left: calc(-1 * (#{$input-clamped-icon-size} + #{cssvar(spacing-1)}));
        opacity: 0;
      }
    }
  }

  &[type='date'],
  &[type='datetime-local'],
  &[type='month'],
  &[type='week'] {
    &::-webkit-calendar-picker-indicator {
      @include flix-icon-calendar($size: $input-icon-size-rem);
      background-image: none; // remove browser default background-image
    }
  }

  &[type='time']::-webkit-calendar-picker-indicator {
    @include flix-icon-time($size: $input-icon-size-rem);
    background-image: none; // remove browser default background-image
  }

  &[type='search']::-webkit-search-cancel-button {
    @include flix-icon-close($size: $input-icon-size-rem);
    @include flix-mask-icon-limits($icon-min-size: $input-icon-size);
    opacity: 1;
    cursor: pointer;
    appearance: none;
  }
}

// ------------------------------------
// input-states
// ------------------------------------
// defines common appearance params for input loading/error/valid states
// currently requires icon mixins (imported here above)
@mixin input-states {
  &--loading &__field,
  &--error &__field,
  &--valid &__field {
    padding-right: calc(#{$input-clamped-icon-size} + #{cssvar(spacing-2)} + #{cssvar(spacing-1)});
  }
  // valid, error and loading states have feedback icon
  &--loading &__feedback-icon,
  &--error &__feedback-icon,
  &--valid &__feedback-icon {
    @include flix-mask-icon-limits($icon-min-size: $input-icon-size);
  }

  &--loading &__feedback-icon {
    width: $input-icon-size-rem;
    height: $input-icon-size-rem;
    @include spinner;
  }

  &--error &__feedback-icon {
    @include flix-icon-attention-solid($color: cssvar(danger-color), $size: $input-icon-size-rem);
  }

  &--valid &__feedback-icon {
    @include flix-icon-checkmark-strong-solid($color: cssvar(success-color), $size: $input-icon-size-rem);
  }

  &--error &__field {
    @include error-state-background-style;

    &:enabled:hover {
      @include error-state-background-style($with-hover: true);
    }
  }

  &--disabled {
    opacity: cssvar(disabled-element-opacity);
  }
}

// ------------------------------------
// input-info-text
// ------------------------------------
// adds and style info feedback element for form inputs
@mixin input-info-text {
  &__info:not(:empty) {
    @include show-as-small-text;
    display: block; // element needs to be a block one to have the proper line height applied
    margin-top: cssvar(spacing-1);
  }

  &__info:not(:empty) ~ &__info {
    margin-top: cssvar(spacing-half);
  }

  &--error &__info {
    color: cssvar(danger-dark-color);
  }

  &__info ~ &__info {
    // to have only the first error info using error color and all subsequent info text have content color
    // independent of the number of span elements there are in the same hierarchy level
    color: cssvar(content-primary-color);
  }
}

@mixin input-icon-base($size: $input-icon-size, $size-rem: $input-icon-size-rem) {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  width: $size-rem;
  height: $size-rem;
  margin-right: cssvar(spacing-1);
  margin-left: cssvar(spacing-2);
  pointer-events: none;

  @include flix-mask-icon-limits($icon-min-size: $size);
  @include flix-icon-font-size-limits($icon-font-size: $size-rem, $icon-min-font-size: $size);

  &::before {
    width: $size-rem;
    height: $size-rem;
    margin-right: 0;
    margin-left: 0;

    @include flix-mask-icon-limits($icon-min-size: $size);
  }
}

@mixin input-inline-label-base() {
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  align-self: stretch;
  padding: 0 cssvar(spacing-2);
  border-right: 1px solid cssvar(input-border-color);
  line-height: cssvar(line-height-primary);
}
