// ----------------------------------------------
// Image link (.[prefix-]img-link)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ------------------------------------
// style variables
// ------------------------------------
$img-box-bg-color: cssvar(box-bg-color);
$img-box-border-radius: cssvar(primary-border-radius);
$img-box-border-color: cssvar(line-primary-color);
$img-box-image-height: 198px; // 34 * 6
$img-box-image-height-bp-xl: 168px; // 28 * 6
$img-box-sm-image-height: 96px; // 16 * 6

#{$config-class-prefix} {
  &img-box {
    display: block;
    overflow: hidden;
    border-radius: $img-box-border-radius;
    font-family: inherit;
    text-decoration: none;

    @include optional-bottom-margin;

    &:is(a):focus-visible {
      @include focus-state-style;
    }

    &__img-wrapper {
      position: relative;
      min-height: $img-box-image-height;
      overflow: hidden;
      border-radius: cssvar(primary-border-radius);

      @include on-bp(xl) {
        min-height: $img-box-image-height-bp-xl;
      }
    }

    &__img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);

      #{$config-class-prefix}img-box--sm & {
        width: auto;
        height: 100%;
      }

      @supports (object-fit: cover) {
        #{$config-class-prefix}img-box--sm & {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    // adds proper image resizing for those browsers that support object-fit
    @supports (object-fit: cover) {
      &__img {
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      position: relative;

      // we only need background and padding for outlined and linked version
      #{$config-class-prefix}img-box--outlined &,
      a#{$config-class-prefix}img-box & {
        padding: cssvar(spacing-2) cssvar(spacing-4);
        background: cssvar(box-bg-color);
      }

      #{$config-class-prefix}img-box--sm &,
      a#{$config-class-prefix}img-box--sm & {
        padding-left: cssvar(spacing-2);
      }

      a#{$config-class-prefix}img-box & {
        padding-right: cssvar(spacing-8);
      }

      // arrow in content area, only for the links
      a#{$config-class-prefix}img-box &::after {
        content: '';
        @include flix-icon-arrow-right;
        position: absolute;
        top: 50%;
        right: cssvar(spacing-2);
        margin-top: -11px;
      }
    }

    a#{&}:hover &__content {
      @include hover-state-style;
    }

    a#{&}:active &__content {
      @include pressed-state-style;
    }

    @at-root &--outlined,
    a#{&} {
      border: 1px solid $img-box-border-color;

      #{$config-class-prefix}img-box__img-wrapper {
        border-radius: cssvar(primary-border-radius) cssvar(primary-border-radius) 0 0;
      }
    }

    // smaller version with a square image on the side
    &--sm {
      position: relative;
      min-height: $img-box-sm-image-height + 2; // compensating borders

      #{$config-class-prefix}img-box__img-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: $img-box-sm-image-height;
        min-height: $img-box-sm-image-height;
      }

      @at-root a#{&} #{$config-class-prefix}img-box__img-wrapper,
      &#{$config-class-prefix}img-box--outlined #{$config-class-prefix}img-box__img-wrapper {
        border-radius: cssvar(primary-border-radius) 0 0 cssvar(primary-border-radius);
      }

      #{$config-class-prefix}img-box__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% - #{$img-box-sm-image-height});
        min-height: $img-box-sm-image-height;
        margin-left: $img-box-sm-image-height;
      }
    }
  }
}
