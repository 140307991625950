// ----------------------------------------------
// blockquote (.[prefix-]blockquote)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ------------------------------------
// style variables
// ------------------------------------
$blockquote-quote-path: 'M159.4,62.9A32.2,32.2,0,0,0,129,35.7c3.7-13.8,12.8-19.4,23.8-22.5a1.3,1.3,0,0,0,.9-1.4l-1.8-11s-0.2-.9-1.6-0.7c-38,4.3-63.7,33.2-58.9,68.3,4.4,24.3,23.4,33.7,41,31.2a32.4,32.4,0,0,0,27-36.7h0ZM38.1,35.7c3.6-13.8,13-19.4,23.8-22.5a1.3,1.3,0,0,0,.9-1.4l-1.6-11S61-.2,59.6,0C21.6,4.1-3.9,33,.5,68.2c4.6,24.5,23.8,33.9,41.2,31.4A32.1,32.1,0,0,0,68.5,62.9,31.7,31.7,0,0,0,38.1,35.7Z';
$blockquote-icon-width: cssvar(spacing-4);
$blockquote-icon-height: cssvar(spacing-2);
$blockquote-icon-width-desktop: cssvar(spacing-8);
$blockquote-icon-height-desktop: cssvar(spacing-4);

#{$config-class-prefix} {
  &blockquote {
    position: relative;
    margin: 0 0 cssvar(spacing-2);
    padding: cssvar(spacing-2) 0 0 calc(#{$blockquote-icon-width} + #{cssvar(spacing-2)});
    color: cssvar(content-primary-color);
    font-family: inherit;

    @include on-bp(sm) {
      padding: cssvar(spacing-2) 0 0 calc(#{$blockquote-icon-width-desktop} + #{cssvar(spacing-2)});
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      @include flix-icon-mask(
        $path: $blockquote-quote-path,
        $color: cssvar(icon-primary-color),
        $width: $blockquote-icon-width,
        $height: $blockquote-icon-height,
        $maskPosition: left center,
        $maskSize: contain,
        $viewBox: '0 0 159.8 100'
      );

      @include on-bp(sm) {
        width: $blockquote-icon-width-desktop;
        height: $blockquote-icon-height-desktop;
      }
    }
  }
}
