// Skeleton loader placeholders styles
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ------------------------------------
// style variables
// ------------------------------------
$skeleton-color: cssvar(grayscale-30-color);
$skeleton-border-radius: cssvar(primary-border-radius);
// @todo replace with one design token, here 2px tunes it up to 18px to replace the hardcoded value and avoids breaking change
$skeleton-height: cssvar(line-height-primary);
$skeleton-xs-height: 1px; // matches divider
$skeleton-md-height: calc(#{cssvar(line-height-primary)} + #{cssvar(spacing-1)}); // matches text, tags and similarly sized elements
$skeleton-lg-height: calc(#{cssvar(line-height-primary)} + #{cssvar(spacing-3)} + 2px); // matches inputs and buttons
$skeleton-lg-height-desktop: calc(#{cssvar(line-height-primary)} + #{cssvar(spacing-2)});
$skeleton-xs-width: 25%;
$skeleton-sm-width: 33%;
$skeleton-md-width: 50%;
$skeleton-lg-width: 75%;

#{$config-class-prefix} {
  &skeleton {
    content: '';
    display: block;
    width: 100%;
    height: $skeleton-height;
    margin-bottom: cssvar(spacing-2);
    animation: skeleton-blink 1.6s infinite linear;
    border-radius: $skeleton-border-radius;
    background: $skeleton-color;

    // skeleton width options
    &--w-xs {
      width: $skeleton-xs-width;
    }

    &--w-sm {
      width: $skeleton-sm-width;
    }

    &--w-md {
      width: $skeleton-md-width;
    }

    &--w-lg {
      width: $skeleton-lg-width;
    }

    // skeleton height options
    &--h-xs {
      height: $skeleton-xs-height;
      margin-bottom: cssvar(spacing-2);
    }

    &--h-md {
      height: $skeleton-md-height;
      margin-bottom: cssvar(spacing-2);
    }

    // mimics height change of form controls depending on the screen size
    &--h-lg {
      height: $skeleton-lg-height;
      margin-bottom: cssvar(spacing-2);

      @include on-bp(xl) {
        height: $skeleton-lg-height-desktop;
      }
    }

    // inline variation for stacking several skeletons on one line
    &--inline {
      display: inline-flex;
      vertical-align: middle;
    }

    // increases border radius to mimic tag like elements
    &--round {
      border-radius: cssvar(spacing-5);
    }

    // skipping the bottom margin if needed
    &--flush-bottom {
      margin-bottom: 0;
    }
  }

  @include anim-blink($anim-name: 'skeleton-blink');
}
