// ----------------------------------------------
// Progress (.[prefix-]progress-tracker)
// ----------------------------------------------
@use 'sass:math';
@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &progress-tracker {
    $progress-tracker: &;
    display: flex;
    justify-content: center;
    list-style-type: none;
    counter-reset: hc-progress-tracker-counter;

    &__item {
      position: relative;
      height: cssvar(spacing-6);
      border: solid cssvar(ui-primary-color);
      border-width: 1px 0;
      background: cssvar(highlight-color);
      counter-increment: hc-progress-tracker-counter;

      &:first-child {
        border-left-width: 1px;
        border-radius: cssvar(primary-border-radius) 0 0 cssvar(primary-border-radius);
      }

      &:last-child {
        border-right-width: 1px;
        border-radius: 0 cssvar(primary-border-radius) cssvar(primary-border-radius) 0;
      }

      // current step thick bottom border pseudo-element
      &[aria-current] {
        &::before {
          content: '';
          position: absolute;
          z-index: 2;
          right: calc(-1 * #{cssvar(spacing-half)});
          bottom: 0;
          left: 0;
          height: cssvar(spacing-half);
          background: cssvar(ui-primary-color);
          clip-path: polygon(#{cssvar(spacing-half)} 0, 100% 0, calc(100% - #{cssvar(spacing-half)}) 100%, 0 100%);
        }

        // invert bot-left corner on first current item
        &:first-child::before {
          clip-path: polygon(0 0, 100% 0, calc(100% - #{cssvar(spacing-half)}) 100%, cssvar(spacing-half) 100%);
        }

        // remove bot-right overlap on last current item
        &:last-child::before {
          right: 0;
        }
      }

      // current and next steps have same background color
      &[aria-current],
      &[aria-current] ~ & {
        background: cssvar(bg-primary-color);

        #{$progress-tracker}__link::after {
          background-color: cssvar(bg-primary-color);
        }
      }

      // next steps have dimmed text color and grey line
      &[aria-current] ~ & {
        border-color: cssvar(line-primary-color);
        color: cssvar(content-secondary-color);

        #{$progress-tracker}__link::after {
          border-color: cssvar(line-primary-color);
        }
      }
    }

    &__link {
      display: flex;
      justify-content: center;
      min-width: calc(#{cssvar(spacing-7)} * 2);
      height: 100%;
      padding: 0 cssvar(spacing-3);
      overflow: hidden;
      color: cssvar(content-primary-color);
      font-weight: cssvar(font-weight-semibold);
      line-height: cssvar(spacing-6);
      text-align: center;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;

      // adds arrow pseudo-element
      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 13%;
        right: calc(-1 * #{cssvar(spacing-2)} - 1px);
        // calculating the hypotenuse of rectangular triangle
        // the height of the item is spacing-6, adding 1px to compensate partially for border width and align elements nicely
        width: calc((#{cssvar(spacing-6)} + 1px) * math.sin(45deg));
        height: calc((#{cssvar(spacing-6)} + 1px) * math.sin(45deg));
        transform: rotate(45deg);
        border: solid cssvar(ui-primary-color);
        border-width: 1px 1px 0 0;
        background-color: cssvar(highlight-color);
        clip-path: polygon(100% 0, 0 0, 100% 100%);
      }

      @include on-bp(sm) {
        min-width: calc(#{cssvar(spacing-12)} * 2);
      }

      &:is(a, button) {
        @include btn-reset(
          $padding: 0 cssvar(spacing-3)
        );
        @include hover-state(false);
        @include pressed-state;
        @include focus-state(true);

        &:hover::after {
          background-image: cssvar(hover-layer-color);
        }

        &:active::after {
          background-image: cssvar(pressed-layer-color);
        }
      }
    }

    // removes arrow from last item
    &__item:last-child &__link::after {
      display: none;
    }

    // links that follow arrows have increased padding
    &__item:not(:first-child) &__link {
      padding-left: cssvar(spacing-5);
    }

    &__text {
      display: flex;
      align-items: center;
      overflow: hidden;
      column-gap: cssvar(spacing-half);
      font-size: 0; // hiding the text on mobile
      text-overflow: ellipsis;
      white-space: nowrap;

      @include on-bp(sm) {
        align-items: center;
        font-size: cssvar(font-size-primary);
      }

      &::before {
        content: counter(hc-progress-tracker-counter);
        font-size: cssvar(font-size-primary);

        @include on-bp(sm) {
          content: counter(hc-progress-tracker-counter) '.';
        }
      }

      // completed steps
      #{$progress-tracker}__item--completed &::after {
        content: '';
        @include flix-icon-checkmark($size: cssvar(spacing-3), $color: cssvar(ui-primary-color));
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
