// ------------------------------------
// Reset helpers
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix}reset {
  &-btn {
    @include btn-reset;
  }

  &-list {
    @include list-reset;
  }

  &-list--inline {
    @include list-reset($inline: true);
  }
}
