// ------------------------------------
// PAGE LAYOUTS
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &page-container {
    min-width: cssvar(page-min-width);
    max-width: cssvar(page-max-width);
    margin: 0 auto;
    padding: 0 cssvar(spacing-2); // as of the styleguide recommendation we need to have a 12px gutters on the sides

    &--has-fixed-header,
    &--has-fixed-header-no-nav {
      // header height
      padding-top: cssvar(spacing-10);
    }

    @include on-range(md, xl) {
      &--has-fixed-header {
        // header height + tablet nav
        padding-top: calc(#{cssvar(spacing-10)} + #{cssvar(spacing-8)});
      }
    }
  }
}
