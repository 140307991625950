// ------------------------------------
// state mixins
// ------------------------------------
/// @access private
// ------------------------------------

@use 'cssvars' as *;
// base icon mixins to get icons
@use 'icons' as *;

// ------------------------------------
// HOVER
// ------------------------------------
@mixin hover-state-style($force-round-corners: false, $text-decoration: none) {
  background-image: cssvar(hover-layer-color);
  text-decoration: $text-decoration;

  @if $force-round-corners {
    border-radius: cssvar(primary-border-radius);
  }
}

@mixin hover-state($enabled-only: true, $force-round-corners: false, $text-decoration: none) {
  @if $enabled-only {
    &:enabled:hover,
    &[href]:hover {
      @include hover-state-style($force-round-corners, $text-decoration);
    }
  } @else {
    &:hover {
      @include hover-state-style($force-round-corners, $text-decoration);
    }
  }
}

// ------------------------------------
// PRESSED
// ------------------------------------
@mixin pressed-state-style() {
  background-image: cssvar(pressed-layer-color);
  text-decoration: none;
}

@mixin pressed-state($enabled-only: true) {
  @if $enabled-only {
    &:enabled:active {
      @include pressed-state-style;
    }
  } @else {
    &:active {
      @include pressed-state-style;
    }
  }
}

// ------------------------------------
// FOCUS
// ------------------------------------
@mixin focus-state-style($force-round-corners: false, $outline-offset: 1px) {
  outline: 2px solid cssvar(focus-outline-color);
  outline-offset: $outline-offset;
  box-shadow: 0 0 0 calc(cssvar(spacing-half) + ($outline-offset)) cssvar(grayscale-0-color);

  @if $force-round-corners {
    border-radius: cssvar(primary-border-radius);
  }
}

@mixin focus-state($force-round-corners: false, $outline-offset: 1px, $visible-only: true) {
  @if $visible-only {
    &:focus-visible {
      @include focus-state-style($force-round-corners, $outline-offset);
    }
  } @else {
    &:focus {
      @include focus-state-style($force-round-corners, $outline-offset);
    }
  }
}

// ------------------------------------
// DISABLED
// ------------------------------------
@mixin disabled-state-style() {
  opacity: cssvar(disabled-element-opacity);
  cursor: not-allowed;
}

@mixin disabled-state() {
  &:disabled,
  &[aria-disabled="true"],
  &--disabled {
    @include disabled-state-style;
  }
}

// ------------------------------------
// ERROR
// ------------------------------------
// layer stacking achieving a11y friendly error background color
// @todo this might need to be replaced with dedicated design token in the future
@mixin error-state-background-style($with-hover: false) {
  @if $with-hover {
    background-image: cssvar(hover-layer-color), linear-gradient(#{cssvar(bg-primary-transparent-color)}, #{cssvar(bg-primary-transparent-color)}), linear-gradient(#{cssvar(danger-color)}, #{cssvar(danger-color)});
  } @else {
    background-image: linear-gradient(#{cssvar(bg-primary-transparent-color)}, #{cssvar(bg-primary-transparent-color)}), linear-gradient(#{cssvar(danger-color)}, #{cssvar(danger-color)});
  }
}

// ------------------------------------
// SELECTED STATE
// ------------------------------------
@mixin selected-state-style($position: left, $highlight-bg: true, $color: cssvar(content-primary-color), $border-color: cssvar(ui-primary-color), $pseudo: before) {
  position: relative;
  color: $color;

  @if $highlight-bg {
    background-color: cssvar(highlight-color);
  }

  @if $position == left {
    // left line
    &::#{$pseudo} {
      content: '';
      position: absolute;
      top: calc(50% - calc(#{cssvar(spacing-4)} / 2)); // centering the marker
      bottom: 0;
      left: 0;
      height: cssvar(spacing-4);
      border-left: cssvar(spacing-half) solid $border-color;
      border-radius: 0 cssvar(primary-border-radius) cssvar(primary-border-radius) 0;
    }
  }

  @if $position == bottom {
    // bottom line
    &::#{$pseudo} {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 0;
      border-bottom: cssvar(spacing-half) solid $border-color;
      border-radius: cssvar(primary-border-radius) cssvar(primary-border-radius) 0 0;
    }
  }

  @if $position == top {
    // top line
    &::#{$pseudo} {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      border-top: cssvar(spacing-half) solid $border-color;
      border-radius: cssvar(primary-border-radius) cssvar(primary-border-radius) 0 0;
    }
  }
}

@mixin selected-state($position: left, $highlight-bg: true, $color: cssvar(content-primary-color), $border-color: cssvar(ui-primary-color), $pseudo: before) {
  &[aria-current] {
    @include selected-state-style($position, $highlight-bg, $color, $border-color, $pseudo);
  }
}
