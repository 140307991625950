// ------------------------------------
// Headers (.[prefix-]h#)
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &h1,
  &h2,
  &h3,
  &h4 {
    // margin ensures helpers can be used to adjust bottom spacing
    margin: 0 0 cssvar(spacing-2);
    // top spacing is required for non section headings, adding it with paddings to avoid margin collapse with the upper elements
    padding: cssvar(spacing-2) 0 0;

    &--section-header,
    &--space-flush-top {
      @extend %no-top-padding-headline;
    }

    &--space-flush-bottom {
      @extend %no-bottom-spacing-headline;
    }

    &--space-flush {
      @extend %no-outer-spacing-headline;
    }

    &:is(strong) {
      display: block;
    }
  }

  &h1 {
    @include show-as-h1;
    @extend %no-top-padding-headline;
    margin: 0 0 cssvar(spacing-4);
  }

  &h2 {
    @include show-as-h2;
  }

  &h3 {
    @include show-as-h3;
  }

  &h4 {
    @include show-as-h4;
  }
}

// headline placeholder needs to be here to overwrite the normal headline styles
// related to the way SASS compiles placeholder styles when @extend is used
%no-top-padding-headline {
  padding-top: 0;
}

%no-bottom-spacing-headline {
  margin-bottom: 0;
}

%no-outer-spacing-headline {
  margin-bottom: 0;
  padding-top: 0;
}
