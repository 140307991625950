// ------------------------------------
// Spinner mixin
// ------------------------------------
/// @access private
// ------------------------------------

@use 'animations' as *;
@use 'cssvars' as *;

@mixin spinner {
  animation: spinner-rotate 1s infinite linear;
  border: cssvar(spacing-half) solid cssvar(ui-primary-light-color);
  border-radius: 50%;
  border-left-color: cssvar(ui-primary-dark-color);

  @include anim-rotate($anim-name: 'spinner-rotate');
}
