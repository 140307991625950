/* stylelint-disable declaration-no-important */
// ------------------------------------
// Spacing helpers
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  // $s-map comes from spacing utils
  @each $size, $value in $s-map {
    // adds margins of predefined values to specified directions
    &space-#{$size}-top {
      margin-top: $value !important;
    }

    &space-#{$size}-bottom {
      margin-bottom: $value !important;
    }

    &space-#{$size}-left {
      margin-left: $value !important;
    }

    &space-#{$size}-right {
      margin-right: $value !important;
    }
  }

  &space-flush-top {
    margin-top: 0 !important;
  }

  &space-flush-bottom {
    margin-bottom: 0 !important;
  }

  &space-flush-left {
    margin-left: 0 !important;
  }

  &space-flush-right {
    margin-right: 0 !important;
  }
}
