// ------------------------------------
// Fieldset
// ------------------------------------
// .[prefix-]fieldset
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &fieldset {
    $fieldset: &;
    margin: 0;
    padding: 0;
    border: 0;

    &:where(:not(:last-child)) {
      margin-bottom: cssvar(spacing-4);
    }

    &__item {
      &:where(:not(:first-of-type)) {
        margin-top: cssvar(spacing-2);
      }

      @each $size, $value in $s-map {
        &--space-#{$size} {
          margin-top: $value;
        }
      }

      &--space-flush {
        margin-top: 0;
      }
    }

    &--horizontal {
      // clearfix FTW
      &::after {
        content: '';
        display: table;
        clear: both;
      }

      #{$fieldset}__item {
        margin-top: 0;
        float: left; // going old fashioned in here as older Chrome and certain web-kit browsers do not apply display flex to fieldsets

        &:where(:not(:first-of-type)) {
          margin-left: cssvar(spacing-4);
        }

        @each $size, $value in $s-map {
          &--space-#{$size} {
            margin-left: $value;
          }
        }

        &--space-flush {
          margin-left: 0;
        }
      }
    }

    @include input-info-text;

    &__info {
      width: 100%;
      float: left;
      clear: both; // forcing the new float context if info is used in horizontal variation
    }
  }
}
