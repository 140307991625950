// ------------------------------------
// Header
// ------------------------------------
// .[prefix-]header
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix}header {
  @include cssvars((
    container-content-color: cssvar(header-color),
  ));
  position: fixed;
  z-index: z('header');
  top: 0;
  right: 0;
  left: 0;
  padding: 0 $header-side-paddings;
  background: cssvar(header-bg-color);
  color: $header-text-color;

  &__inner {
    display: grid;
    position: relative; // necessary for header-skip-links positioning
    grid-template-areas: "burger burger brand brand brand brand brand brand brand widget widget widget"; // 2fr 7fr 3fr
    grid-template-columns: repeat(12, 1fr);
    min-height: $header-min-height;
    margin: 0 auto;
    column-gap: cssvar(spacing-2);

    @include on-bp(md) {
      grid-template-areas:
        "brand brand brand brand brand brand brand brand widget widget widget widget"
        "nav nav nav nav nav nav                             nav nav nav nav nav nav";
    }

    @include on-bp(xl) {
      grid-template-areas: "brand brand nav nav nav nav nav nav nav nav widget widget"; // 2fr 8fr 2fr
      min-width: calc(#{cssvar(page-min-width)} - calc(2 * #{$header-side-paddings})); // 2 paddings for each side
      max-width: calc(#{cssvar(page-max-width)} - calc(2 * #{$header-side-paddings})); // 2 paddings for each side
    }
  }
  // modifiers for widget slot size distribution
  &--widget-m &__inner {
    @include on-bp(xl) {
      grid-template-areas: "brand brand nav nav nav nav nav nav nav widget widget widget"; // 2fr 7fr 3fr
    }
  }

  &--widget-l &__inner {
    @include on-bp(xl) {
      grid-template-areas: "brand brand nav nav nav nav nav nav widget widget widget widget"; // 2fr 6fr 4fr
    }
  }

  &--fullwidth &__inner {
    max-width: none;
  }

  &--unfixed {
    position: static;
  }

  // controls header components rendering based on breakpoints
  &-nav {
    display: none;
  }

  &-burger-menu {
    display: block;
  }

  @include on-bp(md) {
    &-nav {
      display: block;
    }

    &-burger-menu {
      display: none;
    }
  }
}
