// ------------------------------------
// Acessibility helpers
// Based on: https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// stylelint-disable declaration-no-important
#{$config-class-prefix}sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  border-width: 0 !important;
  opacity: 0 !important;
  white-space: nowrap !important;
  clip-path: inset(50%) !important;
}

#{$config-class-prefix}sr-only-focusable {
  @include transition(opacity);
  opacity: 1 !important;

  &:not(:focus) {
    @extend #{$config-class-prefix}sr-only;
  }
}
