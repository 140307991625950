/* You can add global styles to this file, and also import other style files */
@import url('@flixbus/honeycomb/dist/css/honeycomb.css');
@import url('@flixbus/honeycomb/dist/css/honeycomb-themes.css');
@import url('https://honeycomb-icons.hive.flixbus.com/5.8.0/fonts/flix-icon.css');


::-webkit-scrollbar {
    height: 8px;
    width: 10px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 6px;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 4px var(--flix-input-border-color);
    box-shadow: inset 0 0 4px var(--flix-input-border-color);
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background: inherit;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: var(--flix-bg-primary-color);
}