// ------------------------------------
// Form label
//
// Important note!!!
// Labels rely on having a position:relative container
// so DON"T meant to be used on their own outside of form inputs
// ------------------------------------
// .[prefix-]label
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &label {
    display: block;
    position: absolute;
    z-index: z('form-label');
    top: calc(#{cssvar(font-size-primary)}/2 + 2px); // 50% of the label line-height + 2px padding compensation to center the label on the same spot it was before
    left: cssvar(spacing-1);
    max-width: calc(100% - #{cssvar(spacing-2)}); // compensating for left position shift + inner padding
    padding: 1px cssvar(spacing-1);
    transform: translateY(-100%); // making the label grow upwards if more content is added
    border-radius: cssvar(primary-border-radius);
    background: cssvar(bg-primary-color);
    color: cssvar(content-secondary-color);
    font-family: inherit;
    font-size: cssvar(font-size-small);
    font-weight: cssvar(font-weight-normal);
    line-height: cssvar(font-size-primary);
    text-align: left;

    @include on-bp(xl) {
      position: static;
      top: auto;
      left: auto;
      max-width: none;
      padding: 0 0 cssvar(spacing-half);
      transform: none;
      background: transparent;
      font-size: cssvar(font-size-primary);
      line-height: cssvar(line-height-primary);
    }
  }
}
