// ------------------------------------
// spacing mixins to provide common spacing patterns
// ------------------------------------
/// @access private
// ------------------------------------

@use 'cssvars' as *;

// creating a map of spaces to allow iteration through and simplify helper generation with looping
$s-map: (
  half: cssvar(spacing-half),
  1: cssvar(spacing-1),
  2: cssvar(spacing-2),
  3: cssvar(spacing-3),
  4: cssvar(spacing-4),
  5: cssvar(spacing-5),
  6: cssvar(spacing-6),
  7: cssvar(spacing-7),
  8: cssvar(spacing-8),
  9: cssvar(spacing-9),
  10: cssvar(spacing-10),
  11: cssvar(spacing-11),
  12: cssvar(spacing-12),
);

// creating a map of rem values equivalent to 6px based spaces
// @todo tokenize this
$rem-spacing: (
  half: 0.1875rem,
  1: 0.375rem,
  2: 0.75rem,
  3: 1.125rem,
  4: 1.5rem,
  5: 1.875rem,
  6: 2.25rem,
  7: 2.2625rem,
  8: 3rem,
);

// applies bottom margin only for multiple components of the same kind, skips last/only child components
@mixin optional-bottom-margin() {
  // where() removes the specificity increase
  &:where(:not(:last-child)) {
    margin-bottom: cssvar(spacing-2);
  }
}
