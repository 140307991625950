// ------------------------------------
// Legend
// ------------------------------------
// .[prefix-]legend
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &legend {
    display: block;
    width: 100%;
    padding-bottom: cssvar(spacing-half);
    color: cssvar(content-secondary-color);
    font-family: inherit;
    font-size: cssvar(font-size-primary);
    font-weight: cssvar(font-weight-normal);
  }
}
