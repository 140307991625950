// ------------------------------------
// Header widgets
// ------------------------------------
// .[prefix-]header-widgets
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix}header-widgets {
  grid-area: widget;
  align-self: center;
  justify-self: end;

  &:not(&--no-responsive-rendering) {
    display: none;

    @include on-bp(md) {
      display: block;
    }
  }
}

// ------------------------------------
// Header user widget
// ------------------------------------
// .[prefix-]header-user-widget
// ------------------------------------
#{$config-class-prefix}header-user-widget {
  display: flex;
  position: relative;
  align-items: center;
  gap: cssvar(spacing-1);

  &__toggle {
    color: $header-text-color;
    @include header-link;
  }
}
