// ------------------------------------
// Form elements
// ------------------------------------
// .[prefix-]control
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &form-row {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: cssvar(spacing-2);

    @each $size, $value in $s-map {
      &--space-#{$size} {
        margin-bottom: $value;
      }
    }

    &--space-flush,
    &:last-child:not(:only-child) {
      margin-bottom: 0;
    }

    &--small {
      width: 50%;
    }
  }
}
