@use '../config/variables';

// Use this mixin to declare a set of CSS Custom Properties.
// The variables in $css-variables will be properly prefixed.
// The use of this mixin is encouraged to keep a good scalability.
//
// Usage:
//
// @include cssvars((
//    ui-primary-color: $uranium-green-color,
//    ui-primary-light-color: $matcha-green-color,
//    ui-primary-dark-color: $cucumber-green-color,
// ), ':root');
//
// Will result in
//
// :root {
//    --flix-ui-primary-color: #73d700;
//    --flix-ui-primary-light-color: #a6ec55;
//    --flix-ui-primary-dark-color: #5cac00;
// }
//
@mixin cssvars($css-variables, $selector: false, $prefix: variables.$config-prefix) {
  @if $selector == false {
    @each $name, $value in $css-variables {
      #{--#{$prefix}}#{$name}: #{$value};
    }
  } @else {
    #{$selector} {
      @each $name, $value in $css-variables {
        #{--#{$prefix}}#{$name}: #{$value};
      }
    }
  }
}

// Retrieve a css variable value with prefix
//
// Usage
//
// .selector {
//   color: cssvar(ui-primary-color);
// }
//
// Will result in
//
// .selector {
//    color: var(--flix-ui-primary-color);
// }
//
// Can also have a fallback value, for overriding component colors mostly
//
// Usage
//
// .selector {
//   color: cssvar(header-content-color, heading-font-color);
// }
//
// Will result in
//
// .selector {
//    color: var(--theme-ui-primary-color, var(--heading-font-color));
// }
@function cssvar($name, $prefix: variables.$config-prefix, $fallback: false) {
  @if $fallback == false {
    @return var(--#{$prefix}#{$name});
  } @else {
    @return var(--#{$prefix}#{$name}, var(--#{$prefix}#{$fallback}));
  }
}
