// ------------------------------------
// Input select (.[prefix-]select)
// ------------------------------------
@use "sass:map";

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// internal vars making position calculations easier to understand
$select-label-height-desktop: calc(#{cssvar(line-height-primary)} + #{cssvar(spacing-half)});
$select-field-height-mobile: calc((#{cssvar(line-height-primary)} / 2) + #{cssvar(spacing-2)} - #{cssvar(spacing-half)});
$select-field-height-desktop: calc((#{cssvar(line-height-primary)} / 2) + #{cssvar(spacing-1)} - 1px);
$select-clamped-icon-size: $input-clamped-icon-size;

#{$config-class-prefix}select {
  $select: &;
  position: relative;
  @include input-info-text;

  &::after,
  &::before {
    // half of field line-height - half size the icon
    top: calc(#{$select-field-height-mobile} - #{$select-clamped-icon-size} / 2);
    right: cssvar(spacing-2);
    pointer-events: none; // let arrow clicks pass through

    @include on-bp(xl) {
      // adjust top label-height + half size the field - half size the icon
      top: calc(#{$select-label-height-desktop} + #{$select-field-height-desktop} - #{$select-clamped-icon-size} / 2);
    }
  }

  // adding arrow
  &::after {
    @include flix-icon-arrow-down($size: $select-icon-size-rem);
    @include flix-mask-icon-limits($icon-min-size: $select-icon-size);
    content: '';
    position: absolute;
  }

  // move arrow to give space to validation icon
  &--error::after,
  &--valid::after {
    right: calc(#{$select-clamped-icon-size} + #{cssvar(spacing-3)});
  }

  &--no-label::after,
  &--no-label::before {
    // same as mobile
    top: calc(#{$select-field-height-mobile} - #{$select-clamped-icon-size} / 2);

    @include on-bp(xl) {
      // same as mobile but including smaller field paddings
      top: calc(#{$select-field-height-desktop} - #{$select-clamped-icon-size} / 2);
    }
  }

  &__container {
    @include input-container($parent-element: $select);
  }

  &__icon,
  &__icon#{$config-class-prefix}icon {
    @include input-icon-base($size: $select-icon-size, $size-rem: $select-icon-size-rem);
  }

  &__inline-label {
    @include input-inline-label-base;
  }

  &__field {
    @include input-basic;
    padding-right: cssvar(spacing-6);
    @include input-on-breakpoint-xl;
    @include input-placeholder;

    &:enabled {
      &:hover {
        @include hover-state-style;
      }

      &:focus {
        outline: none;
      }
    }

    &::-ms-expand {
      display: none;
    }

    &[disabled] {
      cursor: not-allowed;
    }

    &:not([multiple]) option,
    &:not([multiple]) optgroup {
      background: cssvar(box-bg-color);
      color: cssvar(content-primary-color);
    }
    // Allows hover state to cover the icon
    #{$select}__icon ~ & {
      margin-left: calc(-1 * (#{$select-clamped-icon-size} + #{cssvar(spacing-2)} + #{cssvar(spacing-1)})); // [1]
      padding-left: calc(#{$select-clamped-icon-size} + #{cssvar(spacing-2)} + #{cssvar(spacing-1)});
    }
  }

  // field states needs to come after hover states so its able to override the bg image
  @include select-states;

  // when has multiple modifier and multiple attribute
  &--multiple#{$select} {
    &::after {
      display: none;
    }

    #{$select}__field {
      height: auto;
      padding-right: cssvar(spacing-2);

      option {
        margin: cssvar(spacing-1) 0;
        padding: cssvar(spacing-half);
        border-radius: cssvar(primary-border-radius);
      }

      &:enabled option {
        @include hover-state;
        @include pressed-state;
      }

      &:disabled {
        overflow: hidden;
      }
    }

    &--valid,
    &--error {
      #{$select}__field {
        padding-right: cssvar(spacing-8);
      }

      &::before {
        // move validation icon to give space for scroll bar
        right: cssvar(spacing-4);
      }
    }
  }
}
