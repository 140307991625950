// ----------------------------------------------
// Popup dialogs (.[prefix-]popup)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

$popup-padding: cssvar(spacing-4) !default;

#{$config-class-prefix} {
  &popup {
    display: flex;
    place-items: center;
    place-content: center;
    position: fixed;
    z-index: z('popup');
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    @include transition(opacity);

    &[hidden] {
      display: none;
    }

    &--active {
      opacity: 1;
    }

    &__body {
      display: flex;
      z-index: z('popup') + 1;
      flex-direction: column;
      width: 95%;
      max-width: 600px;
      max-height: 80vh;
      padding: 0 $popup-padding $popup-padding;
      overflow: hidden;
      border-radius: cssvar(primary-border-radius);
      background: cssvar(box-bg-color);
      text-align: center;
      scale: .95;
      @include transition(scale);

      @include on-bp(xs) {
        width: 80%;
      }
    }

    &--active &__body {
      scale: 1;
    }

    &__title {
      @include show-as-h1;
    }

    &__icon,
    &__title,
    &__content {
      margin: 0 0 cssvar(spacing-2);

      &:first-child {
        padding-top: $popup-padding;
      }
    }

    &__content &__icon:first-child,
    &__content &__title:first-child {
      padding-top: 0; // reset first child top padding when icon and title are inside of content, content has it's own top padding.
    }

    &__content {
      @include show-as-text;
      @include overflowing-gradient($bg-color: cssvar(box-bg-color));
      // negative margin + padding to allow the gradients to expand over the side paddings
      margin-right: calc(#{$popup-padding} * -1);
      margin-left: calc(#{$popup-padding} * -1);
      padding-right: $popup-padding;
      padding-left: $popup-padding;

      &:focus-visible {
        @include focus-state-style($outline-offset: -2px);
      }
    }

    &__actions {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      gap: cssvar(spacing-2);
    }
  }
}
