// ----------------------------------------------
// Numbered list element
// ----------------------------------------------
// .[prefix-]list-numbered
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &list-numbered {
    @include list-reset($margin: 0 0 cssvar(spacing-2));
    padding-left: cssvar(spacing-2);
    counter-reset: numbered-list-counter;

    &__item {
      display: table; // achieving a correct behaviour for multi-line list items, they need to be aligned in one line
      position: relative;
      color: cssvar(content-primary-color);

      &::before {
        // number should be followed with a dot "."
        content: counters(numbered-list-counter, '.') '.';
        display: table-cell;
        padding-right: cssvar(spacing-1); // to achieve the same horizontal spacing as with bullet lists, so they look consistent
        font-weight: cssvar(font-weight-bold);
        counter-increment: numbered-list-counter;
      }
    }

    &__item > & {
      width: 100%; // make sure nested list gets a new line
      margin: 0; // removes bottom margins for nested lists
      padding-left: 0;
    }

    &__item > & &__item {
      &::before {
        content: counters(numbered-list-counter, '.'); // no need for an ending dot for sub items
        font-weight: cssvar(font-weight-normal);
      }
    }
  }
}
