// ------------------------------------
// select input mixins
// ------------------------------------
/// @access private
// ------------------------------------

// base icon mixins to get icons
@use '../config/all' as *;
@use 'icons' as *;
@use 'states' as *;
@use 'cssvars' as *;

// ------------------------------------
// style variables
// ------------------------------------
$select-icon-size: $icon-size-small !default;
$select-icon-size-rem: $icon-size-small-rem !default;

@mixin select-states {
  &--error::before,
  &--valid::before {
    @include flix-mask-icon-limits($icon-min-size: $select-icon-size);
    content: '';
    position: absolute;
    z-index: 1; // show validation icon over the field
  }

  &--error::before {
    @include flix-icon-attention-solid($size: $select-icon-size-rem, $color: cssvar(danger-color));
  }

  &--valid::before {
    @include flix-icon-checkmark-strong-solid($size: $select-icon-size-rem, $color: cssvar(success-color));
  }

  // stacking multiple layers to achieve a lighter danger color to be consistent with the Box component
  // not using the state mixing as icon in here is a part of background
  &--error &__field {
    @include error-state-background-style;

    &:enabled:hover {
      @include error-state-background-style($with-hover: true);
    }
  }

  &--disabled {
    opacity: cssvar(disabled-element-opacity);
  }

  &--disabled &__field {
    cursor: not-allowed;
  }
}
