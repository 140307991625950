// ------------------------------------
// PAGE LAYOUTS
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &main-wrapper {
    position: relative;
    min-height: 100%;
    margin-top: 0;
    padding-bottom: 0;
    background: cssvar(bg-primary-color);

    &--full {
      min-height: 100vh;
    }
  }
}
