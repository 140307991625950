// ------------------------------------
// Header brand
// ------------------------------------
// .[prefix-]header-brand
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix}header-brand {
  display: flex;
  grid-area: brand;
  // occupies all columns to have the logo centered on mobile
  grid-column: 1 / -1;
  align-items: center;
  align-self: center;
  justify-content: center;
  height: $header-min-height;

  @include on-bp(xl) {
    grid-column: brand-start / brand-end;
    justify-content: flex-start;
  }

  &__link {
    display: block;
    width: fit-content;
    // compensating horizontal shift from paddings which we need for a nice looking focus state
    margin: 0 calc(-1 * #{cssvar(spacing-1)});
    padding: cssvar(spacing-1);

    @include focus-state($force-round-corners: true);
  }

  &__img {
    display: block;
    width: auto;
    height: cssvar(spacing-4);
  }

  &--tall #{$config-class-prefix}header-brand__img {
    height: cssvar(spacing-6);
  }

  &--square #{$config-class-prefix}header-brand__img {
    width: cssvar(spacing-6);
    height: cssvar(spacing-6);
  }
}
