// ------------------------------------
// Grid utilities
// ------------------------------------
/// @access public
// ------------------------------------

@use 'sass:math';
@use 'sass:map';

@use '../config/all' as *;
@use '../utils/cssvars' as *;

// Grid variables
// @todo in it's current state grid can't be themed, figure out a proper CSS var based theming implementation
$grid-columns: 12;
$grid-gutter-map: (
  2: cssvar(grid-gutter-2),
  4: cssvar(grid-gutter-4)
);

// ------------------------------------
// Grid mixins
// ------------------------------------

// ------------------------------------
// grid-container mixin
// ------------------------------------
// Creates grid container compensating the negative margins in rows,
// useful for root elements where this might produce horizontal scrolling
// ------------------------------------
@mixin grid-container($gutter-size: 2) {
  $grid-gutter: grid-gutter($gutter-size);
  padding-right: calc(#{$grid-gutter} * 0.5);
  padding-left: calc(#{$grid-gutter} * 0.5);
}

// ------------------------------------
// grid-row mixin
// ------------------------------------
// Creates grid row container (based on flexbox)
// ------------------------------------
@mixin grid-row($inline: false, $gutter-size: 2) {
  @if ($inline) {
    display: inline-flex;
  } @else {
    display: flex;
  }
  flex-wrap: wrap;

  $grid-gutter: grid-gutter($gutter-size);
  // adds negative margin to align content
  width: calc(100% + #{$grid-gutter});
  margin-right: calc(-1 * #{$grid-gutter} * 0.5);;
  margin-left: calc(-1 * #{$grid-gutter} * 0.5);
}

// ------------------------------------
// grid-col mixin
// ------------------------------------
// Creates grid column
//
/// @param {number} $size - column size in columns from 1 to 12
// ------------------------------------

@mixin grid-col($size, $gutter-size: 2) {
  width: grid-span($size);

  $grid-gutter: grid-gutter($gutter-size);
  padding-right: calc(#{$grid-gutter} * 0.5);;
  padding-left: calc(#{$grid-gutter} * 0.5);;
}

@mixin grid-push($size) {
  margin-left: grid-push($size);
}

// ------------------------------------
// Grid helpers
// ------------------------------------

// ------------------------------------
// grid-gutter function
// ------------------------------------
// Grid gutter getter
// ------------------------------------

@function grid-gutter($gutter-size: 2) {
  @if (map.get($grid-gutter-map, $gutter-size)) {
    @return map.get($grid-gutter-map, $gutter-size);
  } @else {
    @return map.get($grid-gutter-map, 2);
  }
}

// ------------------------------------
// grid-span function
// ------------------------------------
// Grid calc helper, calculates column span width
//
/// @param $size {Integer} - number of columns (from 1 to 12)
/// @return {String} - calc() providing the width of the column
// ------------------------------------

@function grid-span($size) {
  // exact percentage of width - 1 grid gutter (note: negative margin on wrapper is required)
  @return math.div(100%, math.div($grid-columns, $size));
}

// ------------------------------------
// grid-push function
// ------------------------------------
// Grid calc helper, calculates column push width
//
/// @return {String} - calc() providing the width of the push gap
// ------------------------------------

@function grid-push($size) {
  @return math.div(100%, math.div($grid-columns, $size));
}
