// ----------------------------------------------
// Horizontal navigation (.[prefix-]nav-horizontal)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &nav-horizontal {
    margin: 0;
    margin-bottom: cssvar(spacing-4);
    padding: 0;

    &--stretch &__item {
      flex: 1 1 0; // allows items to grow and sets basis to 0 so they all have same width
    }

    &--stretch &__link {
      width: stretch;
    }

    &__items {
      display: flex;
      align-items: flex-end;
      margin: 0;
      padding: 0;
    }

    &__item {
      position: relative;
      max-width: 50%;
      list-style-type: none;
      white-space: nowrap;

      // divider
      &:not(:first-child)::before {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: cssvar(spacing-2);
        left: 0;
        height: cssvar(spacing-3);
        border-left: 1px solid cssvar(line-primary-color);
      }
    }

    &__link {
      @include show-as-interactive-text;
      justify-content: center;
      margin: cssvar(spacing-1) cssvar(spacing-2);
      padding: cssvar(spacing-1) cssvar(spacing-half);
      border-radius: cssvar(primary-border-radius);
      text-decoration: none;

      @include icon-label($gap: cssvar(spacing-half));

      &:is(button) {
        @include btn-reset($padding: false, $font-size: false);
      }

      @include hover-state;
      @include pressed-state;
      @include focus-state($force-round-corners: true);

      &[aria-current],
      &[aria-selected="true"] {
        @include selected-state-style($position: bottom, $highlight-bg: false);
        position: static; // here the border needs to be positioned relative to the nav item li element and not the link itself
      }
    }

    &__panel {
      @include focus-state($force-round-corners: true);
    }
  }
}
