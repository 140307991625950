// ----------------------------------------------
// Bullet list element
// ----------------------------------------------
// .[prefix-]list
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &list {
    @include list-reset($margin: 0 0 cssvar(spacing-2));
    padding-left: cssvar(spacing-2);

    &__item {
      position: relative;
      padding-left: calc(#{cssvar(spacing-2)} + #{cssvar(spacing-half)});
      color: cssvar(content-primary-color);
      line-height: cssvar(line-height-primary);

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: calc(#{cssvar(line-height-primary)} / 2); // ensure it's positioned in in the middle of the first line of text vertically
        left: 0;
        width: cssvar(spacing-half);
        height: cssvar(spacing-half);
        margin-top: calc(-1 * #{cssvar(spacing-half)} / 2); // half of the size
        border-radius: 50%;
        background: cssvar(content-primary-color);
      }

      @supports (width: clamp(#{cssvar(spacing-half)}, .188rem, #{cssvar(spacing-2)})) {
        $list-item-bullet-size: clamp(#{cssvar(spacing-half)}, .188rem, #{cssvar(spacing-2)}); // bullet size goes from min=3px to max=12px
        padding-left: calc(#{cssvar(spacing-2)} + #{$list-item-bullet-size});

        &::before {
          width: $list-item-bullet-size;
          height: $list-item-bullet-size;
          margin-top: calc(-1 * #{$list-item-bullet-size} / 2); // half of the size
        }
      }
    }

    &__item > & {
      margin: 0; // removes bottom margins for nested lists
      padding-left: 0;
    }
  }
}
