// ----------------------------------------------
// Textarea (.[prefix-]textarea)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ------------------------------------
// style variables
// ------------------------------------
// roughly makes the textarea height the size of 2 text input elements
// 30px (sm spacing) is the control bottom margin we need to include as well
$textarea-height-mobile: calc(#{cssvar(input-height-mobile)} * 2 + #{cssvar(spacing-4)});
// 24px is the label height, spacing(xs) is the control bottom margin we need to include as well
$textarea-height-desktop: calc(#{cssvar(input-height-desktop)} * 2 + #{cssvar(spacing-4)} + 24px);
$textarea-label-height-desktop: calc(#{cssvar(line-height-primary)} + #{cssvar(spacing-half)});
$textarea-clamped-icon-size: $input-clamped-icon-size;

#{$config-class-prefix} {
  &textarea {
    $textarea: &;
    position: relative;

    @include input-info-text;

    &__field {
      position: relative;
      width: 100%;
      min-height: $textarea-height-mobile;
      padding: cssvar(spacing-1) cssvar(spacing-2);
      // Remove the default vertical scrollbar in IE 10+
      overflow: auto;
      border: 1px solid cssvar(input-border-color);
      border-radius: cssvar(primary-border-radius);
      background-color: cssvar(box-bg-color);
      color: cssvar(content-primary-color);
      font-family: inherit;
      font-size: cssvar(font-size-primary);
      font-weight: cssvar(font-weight-normal);
      line-height: cssvar(line-height-primary);
      // Removes spacing underneath the textarea, the inline-block issue
      vertical-align: top;
      appearance: none;

      @include input-placeholder;
      @include hover-state;

      @include on-bp(xl) {
        min-height: $textarea-height-desktop;
      }

      #{$textarea}--valid & {
        // icon size + spacing-2 padding right + spacing-1 padding left
        padding-right: calc(#{$textarea-clamped-icon-size} + #{cssvar(spacing-3)});
      }

      #{$textarea}--error & {
        // icon size + spacing-2 padding right + spacing-1 padding left
        padding-right: calc(#{$textarea-clamped-icon-size} + #{cssvar(spacing-3)});
        border: 1px solid cssvar(danger-color);
      }

      &:focus,
      #{$textarea}--active & {
        @include focus-state-style;
      }

      &[disabled],
      #{$textarea}--disabled & {
        cursor: not-allowed;
      }
    }

    // field states needs to come after hover states so its able to override the bg image
    @include select-states;

    // validation icon
    &::before {
      // half of field line-height + field top padding - half the icon size
      top: calc(#{cssvar(spacing-1)} + #{cssvar(line-height-primary)} / 2 - #{$textarea-clamped-icon-size} / 2);
      right: cssvar(spacing-2);

      @include on-bp(xl) {
        // adjust top to include a bigger label
        top: calc(#{$textarea-label-height-desktop} + #{cssvar(spacing-1)} + #{cssvar(line-height-primary)} / 2 - #{$textarea-clamped-icon-size} / 2);
      }
    }

    &--no-label::before {
      @include on-bp(xl) {
        // adjust top to bigger input height
        top: calc(#{cssvar(spacing-1)} + (#{cssvar(line-height-primary)} - #{$textarea-clamped-icon-size}) / 2);
      }
    }
  }
}
