// ----------------------------------------------
// Info box (.[prefix-]infobox)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &infobox {
    $infobox: &;
    @include cssvars((
      infobox-icon-size-rem: $icon-size-large-rem,
      infobox-icon-size-px: $icon-size-large,
    ));
    @extend %box;
    @extend %box-highlighted;
    display: flex;
    align-items: center;
    gap: cssvar(spacing-2);

    @include optional-bottom-margin;

    &--small {
      @extend %box-small;
    }

    &--small-icon {
      @include cssvars((
        infobox-icon-size-rem: $icon-size-small-rem,
        infobox-icon-size-px: $icon-size-small,
      ));
    }

    &--strong {
      @extend %box-neutral;
      @extend %box-state-color;
    }

    &__content {
      flex-grow: 1;
      min-width: 0;
      overflow-wrap: anywhere;
    }

    &::before {
      content: '';
      @include flix-icon-info($size: cssvar(infobox-icon-size-rem), $color: cssvar(neutral-color));
      flex-shrink: 0;
      align-self: flex-start;
      @include flix-mask-icon-limits($icon-min-size: cssvar(infobox-icon-size-px), $line-height-centralized: true);
    }

    &--success {
      @extend %box-success;

      &::before {
        @include flix-icon-checkmark-strong($size: cssvar(infobox-icon-size-rem), $color: cssvar(success-color));
      }
    }

    &--warning {
      @extend %box-warning;

      &::before {
        @include flix-icon-attention($size: cssvar(infobox-icon-size-rem), $color: cssvar(warning-color));
      }
    }

    &--danger {
      @extend %box-danger;

      &::before {
        @include flix-icon-crossed($size: cssvar(infobox-icon-size-rem), $color: cssvar(danger-color));
      }
    }
  }
}
