// ----------------------------------------------
// avatar (.[prefix-]avatar)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ------------------------------------
// style variables
// ------------------------------------

#{$config-class-prefix} {
  &avatar {
    display: block;
    // prevents flex box to skew avatar dimensions
    flex-shrink: 0;
    width: cssvar(spacing-6);
    height: cssvar(spacing-6);

    &__image {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 100%;

      // covering the possibility of embedding inline SVG as image,
      // consumers need to either embed SVG into "__image" container or assign an "__image" class to SVG element
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &:is(a, button):focus-visible {
      @include focus-state-style($force-round-corners: true);
    }

    &--sm {
      width: cssvar(spacing-4);
      height: cssvar(spacing-4);
    }

    &--lg {
      width: cssvar(spacing-8);
      height: cssvar(spacing-8);
    }

    &--xl {
      width: calc(#{cssvar(spacing-8)} * 2);
      height: calc(#{cssvar(spacing-8)} * 2);
    }
  }
}
