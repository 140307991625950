// ----------------------------------------------
// Notification (.[prefix-]notification)
// ----------------------------------------------
@use "sass:map";

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ------------------------------------
// style variables
// ------------------------------------
$notification-toast-animation-time: 4s !default;

#{$config-class-prefix} {
  &notification {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    padding: cssvar(spacing-2);
    border-radius: cssvar(primary-border-radius);
    background: cssvar(box-bg-color);
    box-shadow: cssvar(primary-box-shadow);
    color: cssvar(content-primary-color);
    font-family: inherit;
    font-size: cssvar(font-size-primary);
    font-weight: cssvar(font-weight-bold);
    gap: cssvar(spacing-2);

    @include optional-bottom-margin;

    &::before,
    &__close {
      flex-shrink: 0;
      align-self: flex-start;
    }

    &::before {
      content: '';
      @include flix-icon-info-solid($size: $icon-size-small-rem, $color: cssvar(neutral-color));
      @include flix-mask-icon-limits($icon-min-size: $icon-size-small, $line-height-centralized: true);
    }

    &--success::before {
      @include flix-icon-checkmark-strong-solid($size: $icon-size-small-rem, $color: cssvar(success-color));
    }

    &--warning::before {
      @include flix-icon-attention-solid($size: $icon-size-small-rem, $color: cssvar(warning-color));
    }

    &--danger::before {
      @include flix-icon-crossed-solid($size: $icon-size-small-rem, $color: cssvar(danger-color));
    }

    &--toast {
      z-index: 1;
      animation: $notification-toast-animation-time sneak-peek-notification-top;
      opacity: 0;
      background: cssvar(grayscale-100-color);
      color: cssvar(grayscale-0-color);

      // forcing text color for other elements inside
      #{$config-class-prefix}notification__content * {
        color: cssvar(grayscale-0-color);
      }

      #{$config-class-prefix}notification__close::before {
        background-color: cssvar(grayscale-0-color);
      }

      // notifications can should have different animations depending on container positioning
      #{$config-class-prefix}notification-container--bottom-left &,
      #{$config-class-prefix}notification-container--bottom-right & {
        animation: $notification-toast-animation-time sneak-peek-notification-bottom;
      }
    }

    &__content {
      flex-grow: 1;
      min-width: 0; // Prevents overflow of close button when has big words as content in small screens
      overflow-wrap: anywhere;
    }

    &__close {
      margin: calc(-1 * #{cssvar(spacing-1)}) calc(-1 * #{cssvar(spacing-1)}) calc(-1 * #{cssvar(spacing-1)}) 0;

      &::before {
        content: '';
        @include flix-icon-close($size: map.get($rem-spacing, 3), $color: cssvar(grayscale-100-color));
        @include flix-mask-icon-limits($icon-min-size: cssvar(spacing-3));
      }
    }
  }

  &notification-container {
    position: fixed;
    z-index: z('side-menu');
    width: 100%;

    &:not(:empty) {
      padding: cssvar(spacing-2);
    }

    @include on-bp(sm) {
      width: auto;

      &:not(:empty) {
        padding: cssvar(spacing-6);
      }
    }

    // top right is the default positioning
    &,
    &--top-right {
      top: 0;
      right: 0;
    }

    &--top-left {
      top: 0;
      right: auto;
      left: 0;
    }

    &--bottom-left {
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
    }

    &--bottom-right {
      top: auto;
      right: 0;
      bottom: 0;
    }
  }
}

// makes element slide into view from the top and then slide back again
@keyframes sneak-peek-notification-top {
  0% {
    transform: translateY(calc(-1 * #{cssvar(spacing-4)}));
    opacity: 0;
  }

  // making overall fade-in duration around 300ms
  7.5% {
    transform: translateY(0);
    opacity: 1;
  }

  92.5% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(calc(-1 * #{cssvar(spacing-4)}));
    opacity: 0;
  }
}
// makes element slide into view from the bottom and then slide back again
@keyframes sneak-peek-notification-bottom {
  0% {
    transform: translateY(#{cssvar(spacing-4)});
    opacity: 0;
  }

  // making overall fade-in duration around 300ms
  7.5% {
    transform: translateY(0);
    opacity: 1;
  }

  92.5% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(#{cssvar(spacing-4)});
    opacity: 0;
  }
}
