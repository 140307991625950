// ------------------------------------
// Icon+Label
//
// Common styles for all labels that can be paired with icons, such as navigation links.
// Used on dropdown, nav-side, nav-horizontal, header, etc.
// ------------------------------------
/// @access private
// ------------------------------------

@use '../config/variables' as *;
@use 'cssvars' as *;

@mixin icon-label($gap: cssvar(spacing-1)) {
  display: flex;
  align-items: center;
  gap: $gap;

  #{$config-class-prefix}icon,
  [class^="hci-icon"] {
    line-height: 1; // sets same height as font-size to stop icons affecting the regular line-height

    &::before {
      margin: 0;  // reset default icon margin cause space is given by flex gap
    }
  }
}
