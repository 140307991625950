@use "sass:map";
@use 'cssvars' as *;
@use 'spacing' as *;

// ------------------------------------
// path variables used by choice styles (checkbox, radio, choice-wrapper)
// ------------------------------------
$icon-checkmark-path: 'M21.7 6.2l-13 13a1 1 0 01-1.4 0l-5-5a1 1 0 111.4-1.4L8 17.08l12.3-12.3a1 1 0 111.4 1.42z';
$icon-circle-solid-path: 'M12 2a10.01 10.01 0 000 20 10.01 10.01 0 000-20z';
$icon-minus-path: 'M21 11H3a1 1 0 000 2h18a1 1 0 000-2z';
$icon-default-color: cssvar(icon-primary-color) !default;
$icon-size-small: cssvar(spacing-4) !default;
$icon-size-small-rem: map.get($rem-spacing, 4) !default;
$icon-size-large: cssvar(spacing-8) !default;
$icon-size-large-rem: map.get($rem-spacing, 8) !default;

// Mixin used to limit mix and max size of the icons when using rem based icon size
@mixin flix-mask-icon-limits($icon-min-size: $icon-size-small, $icon-max-size-factor: 2, $line-height-centralized: false) {
  min-width: $icon-min-size;
  max-width: calc(#{$icon-min-size} * #{$icon-max-size-factor});
  min-height: $icon-min-size;
  max-height: calc(#{$icon-min-size} * #{$icon-max-size-factor});

  // can keep icon centralized with the first line if browser supports max() css function
  @if $line-height-centralized == true {
    @supports (min-height: max(#{$icon-min-size}, #{cssvar(line-height-primary)})) {
      min-height: max(#{$icon-min-size}, #{cssvar(line-height-primary)});
    }
  }
}

@mixin flix-icon-mask($path, $color: $icon-default-color, $width: $icon-size-small, $height: $icon-size-small, $maskPosition: center, $maskSize: cover, $viewBox: '0 0 24 24') {
  mask: url("data:image/svg+xml,%3Csvg viewBox='#{$viewBox}' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='#{$path}' /%3E%3C/svg%3E") no-repeat $maskPosition / $maskSize;
  width: $width;
  height: $height;
  background-color: $color;
};

@mixin flix-icon-arrow-down($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z',
    $color: $color,
    $width: $size,
    $height: $size
  )
};

@mixin flix-icon-arrow-right($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M15.7 11.3L9.72 5.32a1 1 0 00-1.42 0 1 1 0 000 1.42L13.58 12 8.3 17.27a1 1 0 000 1.41 1 1 0 001.41 0l5.99-5.97a1 1 0 000-1.41z',
    $color: $color,
    $width: $size,
    $height: $size
  )
};

@mixin flix-icon-attention($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm-1-7.5V7a1 1 0 012 0v5.5a1 1 0 11-2 0zm2.5 4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z',
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-attention-solid($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z',
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-burger($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M2 5a1 1 0 011-1h18a1 1 0 010 2H3a1 1 0 01-1-1zm19 6H3a1 1 0 000 2h18a1 1 0 000-2zm0 7H3a1 1 0 000 2h18a1 1 0 000-2z',
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-calendar($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M19 5h-1V4a1 1 0 00-2 0v1H8V4a1 1 0 00-2 0v1H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V8a3 3 0 00-3-3zm1 13c0 .54-.46 1-1 1H5c-.54 0-1-.46-1-1v-7h16v7zM4 9V8c0-.54.46-1 1-1h14c.54 0 1 .46 1 1v1H4zm2 4.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm-10 3a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z',
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-checkmark($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: $icon-checkmark-path,
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-checkmark-strong($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M16.89 8.3a1 1 0 010 1.4l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 011.4 0zM22 12a10 10 0 11-20 0 10 10 0 0120 0zm-2 0a8 8 0 00-16 0 8 8 0 0016 0z',
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-checkmark-strong-solid($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z',
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-circle-solid($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: $icon-circle-solid-path,
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-close($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z',
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-crossed($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm4.95-3.05a1 1 0 01-1.41 0L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54a1 1 0 010 1.41z',
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-crossed-solid($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M12 2a10 10 0 100 20 10 10 0 000-20zm4.95 13.54a1 1 0 11-1.41 1.41L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54z',
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-info($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm1-8.5V17a1 1 0 01-2 0v-5.5a1 1 0 112 0zm.5-4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z',
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-info-solid($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M12 2a10 10 0 100 20 10 10 0 000-20zm1 15a1 1 0 01-2 0v-5.5a1 1 0 112 0V17zm-1-8a1.5 1.5 0 110-3 1.5 1.5 0 010 3z',
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-meatball($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M6.5 12a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM19 10.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-7 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3z',
    $color: $color,
    $width: $size,
    $height: $size
  )
};

@mixin flix-icon-minus($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: $icon-minus-path,
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-plus($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M21 11h-8V3a1 1 0 00-2 0v8H3a1 1 0 000 2h8v8a1 1 0 002 0v-8h8a1 1 0 000-2z',
    $color: $color,
    $width: $size,
    $height: $size
  );
};

@mixin flix-icon-time($color: $icon-default-color, $size: $icon-size-small) {
  @include flix-icon-mask(
    $path: 'M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm3.2-6.2a1 1 0 11-1.4 1.4l-2.5-2.5a1 1 0 01-.3-.7V7a1 1 0 012 0v4.59l2.2 2.2z',
    $color: $color,
    $width: $size,
    $height: $size
  );
};

// if an icon font icon is used we want to manage its size through the font-size property
// this makes it possible to enforce scaling limits, for now we only allow it to scale twice its size
@mixin flix-icon-font-size-limits($icon-font-size: $icon-size-small, $icon-min-font-size: $icon-size-small) {
  font-size: $icon-font-size;
  line-height: $icon-font-size;
  @supports (font-size: min($icon-font-size, $icon-min-font-size)) {
    font-size: min(max($icon-min-font-size, $icon-font-size), calc(2 * $icon-min-font-size));
    line-height: min(max($icon-min-font-size, $icon-font-size), calc(2 * $icon-min-font-size));
  }
}
