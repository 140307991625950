// ------------------------------------
// Header skip links
// ------------------------------------
// .[prefix-]header-skip-links
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix}header-skip-links {
  position: absolute;
  z-index: z('header');
  grid-column: 1 / span 12;
  grid-row: 1 / span 1;
  align-self: end;
  transform: translateY(80%);
  justify-self: center;

  @supports (width: fit-content) {
    width: 0;
    height: 0;

    &:focus-within {
      width: auto;
      height: auto;
    }
  }

  & > #{$config-class-prefix}skip-link {
    width: auto;
  }

  @include on-range(md, xl) {
    align-self: center;
    justify-self: start;
    transform: translateY(0);

    @supports (width: fit-content) {
      &:focus-within {
        width: fit-content;
      }
    }
  }

  @include on-bp(xl) {
    align-self: end;
    justify-self: start;
  }
}
