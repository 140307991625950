// ----------------------------------------------
// dropdown (.[prefix-]dropdown)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &dropdown {
    $dropdown: &;
    display: inline-block;
    position: relative;

    &__items {
      @include dropdown-items-bottom;
    }

    &--active #{$dropdown}__items:not([hidden]) {
      @extend %balloon-display;
    }

    &__item {
      @include dropdown-item;
    }

    &__link {
      @include dropdown-link-content;

      &:is(a, button) {
        @include dropdown-link-interactions;
      }
    }

    // cover different positioning options
    // this includes repositioning of the box and the arrow
    &--top #{$dropdown}__items {
      @include dropdown-items-top;
    }

    &--left #{$dropdown}__items {
      @include dropdown-items-left;
    }

    &--right #{$dropdown}__items {
      @include dropdown-items-right;
    }
  }
}
