// ----------------------------------------------
// Typography elements
// ----------------------------------------------
// .[prefix-]text
// .[prefix-]text a
// .[prefix-]text blockquote
// .[prefix-]text h1
// .[prefix-]text h2
// .[prefix-]text h3
// .[prefix-]text ul
// .[prefix-]list-general
// ------------------------------------
// @TODO: this stylesheet is more a set of scope styles under the .[prefix-]text class
// we need to find a way to split this into proper independent components

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// importing typography related components
@use 'blockquote' as *;
@use 'link' as *;
@use 'heading' as *;
@use 'list' as *;
@use 'list-numbered' as *;
@use 'list-description' as *;

#{$config-class-prefix} {
  // Text
  // ----------------------------------------------

  &text,
  &text p {
    @include show-as-text;
    margin: 0;
    @include optional-bottom-margin;
  }

  &text--small,
  &text--small p {
    @include show-as-small-text;
    margin: 0;
    @include optional-bottom-margin;
  }

  // Links
  // ----------------------------------------------

  &text a {
    @extend #{$config-class-prefix}link;
  }

  // Headers
  // ----------------------------------------------

  &text h1 {
    @extend #{$config-class-prefix}h1;
  }

  &text h2 {
    @extend #{$config-class-prefix}h2;
  }

  &text h3 {
    @extend #{$config-class-prefix}h3;
  }

  &text h4 {
    @extend #{$config-class-prefix}h4;
  }

  // Blockquote
  // ----------------------------------------------

  &text blockquote {
    @extend #{$config-class-prefix}blockquote;
  }

  // List
  // ----------------------------------------------

  &text ul {
    @extend #{$config-class-prefix}list;
  }

  &text ul > li {
    @extend #{$config-class-prefix}list__item;
  }

  // List numbered
  // ----------------------------------------------

  &text ol {
    @extend #{$config-class-prefix}list-numbered;
  }

  &text ol > li {
    @extend #{$config-class-prefix}list-numbered__item;
  }

  // List description
  // ----------------------------------------------

  &text dl > dt {
    @extend #{$config-class-prefix}list-description__term;
  }

  &text dl > dd {
    @extend #{$config-class-prefix}list-description__details;
  }
}
