// ----------------------------------------------
// Radio input (.[prefix-]radio)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix}radio {
  @include basic-choice(
    $checkmark: $icon-circle-solid-path,
    $checkmark-indent: calc(cssvar(spacing-1) + 2px), // 2px to fine adjust and keep an even number, avoids browser rendering a misaligned checkmark with odd size
    $border-radius: 100%
  );
  @include input-info-text;
}
