// ----------------------------------------------------------------
// Panel dialog (aka: bottom sheet, drawer) (.[prefix-]panel)
// ----------------------------------------------------------------

@use "sass:map";

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix}panel {
  $panel: &;
  $panel-breakpoint: sm;
  position: fixed;
  z-index: z('side-menu');
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  // total transition equals $transition-duration
  transition: transform ($transition-duration * 0.25) ($transition-duration * 0.25), opacity ($transition-duration * 0.5);
  opacity: 0; // hiding element from view, on mobile

  &:not([aria-modal="true"]) {
    pointer-events: none; // Allows interaction through the panel div
  }

  &--active {
    transform: translateY(0);
    // total transition equals $transition-duration, swapping properties to hide sliding overlay animation
    transition: transform ($transition-duration * 0.5), opacity ($transition-duration * 0.25) ($transition-duration * 0.25);
    opacity: 1;
  }

  // centering things with flexbox and opt out of absolute position juggling for this case
  &--center {
    @include on-bp($panel-breakpoint) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__body {
    display: flex;
    position: absolute;
    z-index: z('side-menu');
    top: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: stretch;
    width: 85%;
    max-width: $panel-side-narrow-width;
    overflow: hidden;
    transform: translate(100%, 0); // element slides from the right side by default
    border-radius: $panel-border-radius 0 0 $panel-border-radius;
    background: $panel-bg-color;
    box-shadow: cssvar(primary-box-shadow);
    pointer-events: all; // resets pointer events from the non-modal overlay
    @include transition(transform);

    #{$panel}--left & {
      right: auto;
      left: 0;
      transform: translate(-100%, 0);
      border-radius: 0 $panel-border-radius $panel-border-radius 0;
    }

    #{$panel}--bottom &,
    #{$panel}--center & {
      top: auto;
      width: 100%;
      max-width: 100%;
      max-height: 85vh;
      transform: translate(0, 100vh);
      border-radius: $panel-border-radius $panel-border-radius 0 0;
    }

    // Center position should only be applied on wider screens,
    // For other cases "bottom variation is used"
    #{$panel}--center & {
        @include on-bp($panel-breakpoint) {
          position: static;
          width: 100%;
          max-width: 600px;
          border-radius: $panel-border-radius;
        }
    }

    #{$panel}--full & {
      width: 100%;
      max-width: 100%;
      border-radius: 0;
    }

    #{$panel}--full#{$panel}--bottom & {
      height: 100vh;
    }

    // active modifier needs to be at the bottom of modifiers chain to overwrite positioning variations
    #{$panel}--active & {
      transform: translate(0, 0); // show the panel
      transition: transform $transition-duration $transition-duration $transition-function; // wait for wrapper animation to end
    }
  }

  &__header {
    display: flex;
    z-index: z('header');
    align-items: center;
    padding: cssvar(spacing-2);
    border-bottom: 1px solid cssvar(line-primary-color);
    gap: cssvar(spacing-2);

    @include on-bp($panel-breakpoint) {
      padding: cssvar(spacing-2) cssvar(spacing-4);
    }
  }

  &__title {
    flex: 1;
    align-self: center;
  }

  &__back,
  &__close {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__back {
    margin-left: calc(-1 * #{cssvar(spacing-1)}); // optically align icons and ensure gutter between the icon and the heading
  }

  &__close {
    margin-right: calc(-1 * #{cssvar(spacing-1)}); // optically align icons and ensure gutter between the icon and the heading

    &::before {
      content: '';
      @include flix-icon-close($size: $icon-size-small-rem);
      @include flix-mask-icon-limits($icon-min-size: $icon-size-small);
    }
  }

  &__content {
    @include overflowing-gradient($bg-color: $panel-bg-color);
    flex-basis: 100%;
    padding: cssvar(spacing-2);

    #{$panel}--bottom & {
      flex-basis: auto;
    }

    #{$panel}--full#{$panel}--bottom & {
      flex-basis: 100%;
    }

    @include on-bp($panel-breakpoint) {
      padding: cssvar(spacing-4);
    }

    &:focus-visible {
      @include focus-state-style($outline-offset: -2px);
    }
  }

  &__footer {
    display: flex;
    flex-shrink: 0;
    align-items: baseline;
    justify-content: space-between;
    min-height: cssvar(input-height-mobile); // footer is as high as the buttons in it
    padding: cssvar(spacing-2);
    gap: cssvar(spacing-2);

    &--justify-start {
      justify-content: flex-start;
    }

    &--justify-end {
      justify-content: flex-end;
    }

    &--justify-center {
      justify-content: center;
    }

    @include on-bp($panel-breakpoint) {
      min-height: cssvar(input-height-desktop); // footer is as high as the buttons in it
      padding: cssvar(spacing-2) cssvar(spacing-4);
    }

    #{$config-class-prefix}btn {
      &:not(&--block) {
        width: auto;
      }
    }

    &-column {
      flex-basis: 100%;
      text-align: center;

      &--narrow {
        flex-basis: auto;
      }
    }
  }
}
