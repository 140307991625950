// ----------------------------------------------
// Bullet list element
// ----------------------------------------------
// .[prefix-]list
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &list-description {
    &__details {
      margin-left: cssvar(spacing-4);
    }

    &__details + &__term {
      margin-top: cssvar(spacing-2);
    }
  }
}
