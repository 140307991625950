// ------------------------------------
// animations utilities
// ------------------------------------
/// @access private
// ------------------------------------

// ------------------------------------
// anim-rotate
// ------------------------------------
// mixin to generate a rotating animation
//
/// @param {Degree} $from - valid css degree value
/// @param {Degree} $to - valid css degree value
/// @param {String} $anim-name - the name of the resulting animation
// ------------------------------------

@mixin anim-rotate(
  $from: 0deg,
  $to: 360deg,
  $anim-name: 'rotate'
) {
  @keyframes #{$anim-name} {
    0% {
      transform: rotate($from);
    }

    100% {
      transform: rotate($to);
    }
  }
}

// ------------------------------------
// anim-grow-fade
// ------------------------------------
// mixin to generate a grow and fade animation
//
/// @param {Size} $from-width - starting width
/// @param {Size} $from-height - starting height
/// @param {Float|Integer} $from-opacity - starting opacity
/// @param {Size} $to-width - ending width
/// @param {Size} $to-height - ending height
/// @param {Float|Integer} $to-opacity - ending opacity
/// @param {String} $anim-name - the name of the resulting animation
// ------------------------------------

@mixin anim-grow-fade(
  $from-width: 0,
  $from-height: 0,
  $from-opacity: 0,
  $to-width: 10px,
  $to-height: 10px,
  $to-opacity: 1,
  $anim-name: 'grow-fade'
) {
  @keyframes #{$anim-name} {
    0% {
      width: $from-width;
      height: $from-height;
      opacity: $from-opacity;
    }

    100% {
      width: $to-width;
      height: $to-height;
      opacity: $to-opacity;
    }
  }
}

// ------------------------------------
// anim-blink
// ------------------------------------
// mixin to generate opacity blinking animation
//
/// @param {Float} $from - opacity to start from
/// @param {Float} $to - opacity to end with
/// @param {String} $anim-name - custom name of the resulting animation, if needed
// ------------------------------------
@mixin anim-blink(
  $from: 1,
  $to: 0.5,
  $anim-name: 'anim-blink'
) {
  @keyframes #{$anim-name} {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
}
