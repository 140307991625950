// ----------------------------------------------
// Data Table
// ----------------------------------------------
// .[prefix-]data-table
// .[prefix-]data-table-wrapper
// .[prefix-]data-table-controls

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix}data-table {
  $data-table: &;
  position: relative;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;

  @include table-caption;

  // the data-table-controls wraps data table and pager components and applies uniform styles to them
  &-controls {
    text-align: center;

    & #{$data-table}-wrapper {
      margin-bottom: cssvar(spacing-3);
    }
  }

  // data-table-wrapper needed to get border radius and horizontal scrolling working
  &-wrapper {
    max-width: 100%;
    height: 100%;
    border: 1px solid cssvar(line-primary-color);
    border-radius: cssvar(primary-border-radius);
    background-color: cssvar(box-bg-color);

    // making responsive scroll optional due to possible tooltip element cuts with overflow setting
    &--responsive {
      overflow-x: auto;

      // toggling table scroll only on smaller screens
      // @todo figure out if we need breakpoint options
      @include on-bp(sm) {
        overflow-x: visible;
      }
    }

    &--collapsed {
      display: inline-block;
    }

    &--scrollable {
      overflow-y: auto;
    }
  }

  &__header {
    td,
    th {
      @include table-cell;
      @include show-as-h4;
      white-space: nowrap; // @todo this might be too harsh, let's wait for feedback
    }

    &-btn {
      @include btn-reset;
      color: inherit;
    }
  }

  &__footer {
    td,
    th {
      @include table-cell;
      @include show-as-h4;
      border-width: 1px 0 0;
    }
  }

  > tbody > tr {
    background-color: cssvar(bg-primary-color);

    &:nth-of-type(odd) {
      background-color: cssvar(grayscale-10-color);
    }

    td,
    th {
      @include table-cell;
      @include show-as-text;
    }

    &:last-child > {
      td,
      th {
        border-width: 0;

        &:first-child {
          border-bottom-left-radius: cssvar(primary-border-radius);
        }

        &:last-child {
          border-bottom-right-radius: cssvar(primary-border-radius);
        }
      }
    }

    &:hover {
      @include hover-state-style;
    }

    &#{$data-table}__row--selected {
      background-color: cssvar(highlight-color);
    }
  }

  &--plain > tbody > tr:nth-of-type(odd):not(#{$data-table}__row--selected) {
    background-color: cssvar(bg-primary-color);
  }

  &--sticky-header &__header {
    position: sticky;
    z-index: z('sticky-header');
    top: 0;
    background-color: cssvar(box-bg-color);

    & > tr > th,
    & > tr > td {
      border-bottom: cssvar(spacing-half) solid cssvar(line-primary-color);
    }
  }

  &--small caption {
    padding: cssvar(spacing-1);
  }

  &--small &__header,
  &--small &__row,
  &--small &__footer {
    td,
    th {
      padding: cssvar(spacing-1);
    }
  }
}
