// ----------------------------------------------
// Tooltip (.[prefix-]toooltip)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *; // import tooltip utils

#{$config-class-prefix} {
  &tooltip {
    @extend %balloon;
    @extend %balloon-position-right;
    @include tooltip-position-right;
    @include tooltip-alignment-center-y;
    @include transition(opacity);
    display: none;
    position: absolute;
    z-index: z(dropdown);
    opacity: 0;

    // `::before` pseudo element is used to create a faux background
    &::before {
      @extend %balloon-faux-background;
    }

    // `::after` pseudo element is used to create the balloon arrow
    &::after {
      @extend %balloon-arrow;
      @extend %balloon-arrow-left;
      @extend %balloon-arrow-center-y;
    }

    &--active:not([hidden]) {
      @extend %balloon-display;
      opacity: 1;
    }

    &--content-fit {
      @extend %balloon-content-fit;
    }

    &--medium {
      @extend %balloon-medium;
    }

    &--large {
      @extend %balloon-large;
    }

    &--danger {
      @extend %balloon-danger;
    }

    &--start {
      @include tooltip-alignment-start-y;

      &::after {
        @extend %balloon-arrow-start-y;
      }
    }

    &--end {
      @include tooltip-alignment-end-y;

      &::after {
        @extend %balloon-arrow-end-y;
      }
    }

    // Makes tooltip to appear to the left
    &--left {
      @extend %balloon-position-left;
      @include tooltip-position-left;
      @include tooltip-alignment-center-y;

      &::after {
        @extend %balloon-arrow-right;
      }

      &#{$config-class-prefix}tooltip--start {
        @include tooltip-alignment-start-y;
      }

      &#{$config-class-prefix}tooltip--end {
        @include tooltip-alignment-end-y;
      }
    }

    // Makes tooltip to appear on top
    &--top {
      @extend %balloon-position-top;
      @include tooltip-position-top;
      @include tooltip-alignment-center-x;

      &::after {
        @extend %balloon-arrow-down;
        @extend %balloon-arrow-center-x;
      }

      &#{$config-class-prefix}tooltip--start {
        @include tooltip-alignment-start-x;

        &::after {
          @extend %balloon-arrow-start-x;
        }
      }

      &#{$config-class-prefix}tooltip--end {
        @include tooltip-alignment-end-x;

        &::after {
          @extend %balloon-arrow-end-x;
        }
      }

    }

    // Makes tooltip to appear at the bottom
    &--bottom {
      @extend %balloon-position-bottom;
      @include tooltip-position-bottom;
      @include tooltip-alignment-center-x;

      &::after {
        @extend %balloon-arrow-up;
        @extend %balloon-arrow-center-x;
      }

      &#{$config-class-prefix}tooltip--start {
        @include tooltip-alignment-start-x;

        &::after {
          @extend %balloon-arrow-start-x;
        }
      }

      &#{$config-class-prefix}tooltip--end {
        @include tooltip-alignment-end-x;

        &::after {
          @extend %balloon-arrow-end-x;
        }
      }
    }

    &__content {
      @extend %balloon-content;
    }

    &__close {
      @extend %balloon-close;
    }
  }

  // wrapper that makes is possible to position the tooltip correctly
  // should be added around or to the element that triggers tooltip
  &tooltip-target {
    position: relative;

    &--inline {
      display: inline-block;
    }
  }
}
