// ----------------------------------------------
// Checkbox (.[prefix-]checkbox)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix}checkbox {
  @include basic-choice($checkmark: $icon-checkmark-path);
  @include input-info-text;

  // coloring and icon for indeterminate state
  &__input:indeterminate + &__label::before,
  &--indeterminate > &__label::before {
    border-color: cssvar(ui-primary-color);
  }

  &__input:indeterminate + &__label::after,
  &--indeterminate > &__label::after {
    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='#{$icon-minus-path}' /%3E%3C/svg%3E");
    transform: scale(1);
    background-color: cssvar(ui-primary-color);
  }

  // overriding the border radius as smaller checkboxes need to have a different one
  &--sm &__label::before {
    border-radius: 3px;
  }

  // overriding checkbox alignment when has invisible label
  &--no-label &__label::before,
  &--no-label &__label::after {
    margin-top: 0;
  }
}
