// ----------------------------------------------
// Divider (.prefix-divider)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ------------------------------------
// style variables
// ------------------------------------

#{$config-class-prefix} {
  &divider {
    position: relative;
    height: 1px;
    border-style: none;
    background-color: cssvar(line-primary-color);
  }
}
