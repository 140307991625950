// ------------------------------------
// Header utilities
// ------------------------------------
/// @access private
// ------------------------------------

@use '../config/all' as *;
@use 'icons' as *;
@use 'cssvars' as *;
@use 'icon-label' as *;
@use 'reset' as *;
@use 'states' as *;
@use 'typography' as *;

// ------------------------------------
// Shared header variables
// ------------------------------------
$header-min-height: cssvar(spacing-10);
$header-side-paddings: cssvar(spacing-2);
$header-text-color: cssvar(header-color);

@mixin header-expandable-menu {
  &[aria-expanded]::after {
    content: '';
    @include flix-icon-arrow-down($color: $header-text-color);
  }

  &[aria-expanded='true'] {
    &::after {
      transform: rotate(180deg);
    }

    + #{$config-class-prefix}header-burger-menu-subnav:not([hidden]),
    + #{$config-class-prefix}header-nav-subnav:not([hidden]) {
      display: block;
    }
  }
}

@mixin header-link($padding: cssvar(spacing-1)) {
  @include show-as-interactive-text($color: false);
  padding: $padding;
  border-radius: cssvar(primary-border-radius);
  text-decoration: none;

  @include icon-label;

  &:is(button) {
    @include btn-reset($padding: false, $font-size: false);
  }

  @include header-expandable-menu;
  @include hover-state;
  @include pressed-state;
  @include focus-state;
}
