// ----------------------------------------------
// Side navigation (.[prefix-]nav-side)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &nav-side {
    &__item {
      display: block;
    }

    &__link:is(button) {
      @include btn-reset($padding: false);
    }

    &__link {
      @include nav-side-link;

      &--has-subnav,
      &[aria-expanded] {
        &::after {
          content: '';
          @include flix-icon-arrow-down;
        }
      }

      &--has-subnav-open,
      &[aria-expanded='true'] {
        &::after {
          transform: rotate(180deg);
        }
      }
    }

    // Subnavigation
    // ----------------------------------------------

    &-subnav {
      padding-left: cssvar(spacing-3);

      &__item {
        @extend #{$config-class-prefix}nav-side__item;
      }

      &__link {
        @extend #{$config-class-prefix}nav-side__link;
      }
    }
  }
}
