// ------------------------------------
// Box placeholder extensions
// ------------------------------------
/// @access private
// ------------------------------------

@use 'cssvars' as *;

%box {
  padding: cssvar(spacing-4);
  border: 1px solid cssvar(line-primary-color);
  border-radius: cssvar(primary-border-radius);
  background-color: cssvar(box-bg-color);
}

%box-small {
  padding: cssvar(spacing-2);
}

%box-highlighted {
  border: 0;
  box-shadow: cssvar(primary-box-shadow);
}

%box-state-color {
  border-color: cssvar(box-state-color);
  background-image:
    linear-gradient(cssvar(bg-primary-transparent-color), cssvar(bg-primary-transparent-color)),
    linear-gradient(cssvar(box-state-color), cssvar(box-state-color));
}

%box-dimmed {
  @include cssvars((
    box-state-color: cssvar(grayscale-50-color),
  ));
}

%box-neutral {
  @include cssvars((
    box-state-color: cssvar(neutral-color),
  ));
}

%box-warning {
  @include cssvars((
    box-state-color: cssvar(warning-color),
  ));
}

%box-success {
  @include cssvars((
    box-state-color: cssvar(success-color),
  ));
}

%box-danger {
  @include cssvars((
    box-state-color: cssvar(danger-color),
  ));
}
