// ----------------------------------------------
// Tag (.[prefix-]tag)
// ----------------------------------------------
@use "sass:map";

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ------------------------------------
// style variables
// ------------------------------------
$tag-color: cssvar(grayscale-100-color);
$tag-color-variations: cssvar(grayscale-0-color);

#{$config-class-prefix} {
  &tag {
    $tag: &;
    @include cssvars((
      tag-color: cssvar(grayscale-50-color),
      tag-text-color: cssvar(grayscale-100-color),
    ));
    @include show-as-interactive-text($color: cssvar(tag-text-color));
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    // compensating for 1px borders vertically
    padding: calc(#{cssvar(spacing-half)} - 1px) cssvar(spacing-2);
    column-gap: cssvar(spacing-half);
    border: 1px solid cssvar(tag-color);
    border-radius: cssvar(spacing-5);
    background-color: cssvar(tag-color);
    font-family: inherit;
    text-decoration: none;
    text-overflow: ellipsis;
    // makes multi-line layout the same in different browsers, aligns tags with text in the same container
    vertical-align: middle;
    // no multiline text is allowed within the tag element
    white-space: nowrap;

    &--subtle,
    &--outlined {
      background-color: cssvar(grayscale-0-color);
    }

    &--subtle {
      box-shadow:
        inset 0 0 0 100vh cssvar(bg-primary-transparent-color),
        inset 0 0 0 100vh cssvar(tag-color);
    }

    &--success,
    &--warning,
    &--danger {
      @include cssvars((
        tag-text-color: cssvar(grayscale-0-color)
      ));

      #{$tag}__icon {
        color: inherit;
      }
    }

    // adding borders of the same background color to different types to achieve a consistent height without playing with paddings
    &--success {
      @include cssvars((
        tag-color: cssvar(success-dark-color)
      ));

      &#{$tag}--subtle,
      &#{$tag}--outlined {
        @include cssvars((
          tag-text-color: cssvar(success-dark-color)
        ));
      }
    }

    &--warning {
      @include cssvars((
        tag-color: cssvar(warning-dark-color)
      ));

      &#{$tag}--subtle,
      &#{$tag}--outlined {
        @include cssvars((
          tag-text-color: cssvar(warning-dark-color)
        ));
      }

      &#{$tag}--subtle {
        @include cssvars((
          tag-color: cssvar(warning-color)
        ));
        // warning subtle is a special case because we dont want to use the dark color for the background
        border-color: cssvar(warning-dark-color);
      }
    }

    &--danger {
      @include cssvars((
        tag-color: cssvar(danger-dark-color)
      ));

      &#{$tag}--subtle,
      &#{$tag}--outlined {
        @include cssvars((
          tag-text-color: cssvar(danger-dark-color)
        ));
      }
    }

    &__close {
      position: relative;
      z-index: z(base);

      &::before {
        content: '';
        @include flix-icon-close($size: map.get($rem-spacing, 3), $color: cssvar(tag-text-color));
        @include flix-mask-icon-limits($icon-min-size: cssvar(spacing-3));
      }
    }

    &--small {
      padding: calc(#{cssvar(spacing-half)} - 1px) cssvar(spacing-1);
      font-size: cssvar(font-size-small);
      line-height: cssvar(font-size-small); // hacking around to line height of 1 to achieve the desired 18px height

      // adjust icon size to match the tiny tag
      #{$tag}__icon {
        font-size: 1em;
      }

      // smaller tags need smaller buttons
      // overriding some buttons styles in here while also trying to stretch to more space vertically using negative margins
      #{$tag}__close {
        width: map.get($rem-spacing, 3);
        min-width: cssvar(spacing-3);
        max-width: calc(#{cssvar(spacing-3)} * 2);
        height: map.get($rem-spacing, 3);
        min-height: cssvar(spacing-3);
        max-height: calc(#{cssvar(spacing-3)} * 2);
        margin: calc(-1 * #{cssvar(spacing-half)} - 1px) 0;
      }
    }

    &:is(a, button) {
      cursor: pointer;
      @include hover-state;
      @include pressed-state;
      @include focus-state;
    }

    @include disabled-state;
  }
}
