// ----------------------------------------------
// Pager element
// ----------------------------------------------
// .[prefix-]pager
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ------------------------------------
// style variables
// ------------------------------------
$pager-item-size: cssvar(spacing-8);
$pager-item-size-xl: cssvar(spacing-6);

#{$config-class-prefix}pager {
  $pager: &;

  &__list {
    display: inline-flex;
    flex-wrap: wrap;
    max-width: 100%;
    list-style: none;
  }

  &__item {
    min-width: $pager-item-size;
    height: $pager-item-size;
    margin: -1px -1px 0 0;
    border: 1px solid cssvar(input-border-color);
    text-align: center;

    @include on-bp(xl) {
      min-width: $pager-item-size-xl;
      height: $pager-item-size-xl;
    }

    &:first-child {
      border-radius: cssvar(primary-border-radius) 0 0 cssvar(primary-border-radius);
    }

    &:last-child {
      margin-right: 0;
      border-radius: 0 cssvar(primary-border-radius) cssvar(primary-border-radius) 0;
    }
  }

  &__link {
    @include show-as-interactive-text($color: cssvar(content-primary-color));
    @include btn-reset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: cssvar(spacing-half) cssvar(spacing-1);
    color: cssvar(content-primary-color);
    text-decoration: none;

    @include focus-state($force-round-corners: true);
    @include pressed-state;
    @include hover-state;
    @include disabled-state;

    &[aria-current] {
      padding-bottom: 0;
      @include selected-state-style($position: bottom);
    }

    &--prev,
    &--next {
      font-size: 0; // achieving proper icon vertical alignment

      &,
      #{$pager}__link--disabled:hover {
        &::after {
          content: '';
          @include flix-icon-arrow-right;
        }
      }
    }

    &--prev {
      &,
      #{$pager}__link--disabled:hover {
        &::after {
          transform: rotate(180deg);
        }
      }

      &:hover {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }

  &--content-fit {
    #{$pager}__item {
      height: auto;
    }

    #{$pager}__link {
      padding: cssvar(spacing-1) cssvar(spacing-2);

      &--prev,
      &--next {
        padding: cssvar(spacing-1);
      }
    }
  }
}
