@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ----------------------------------------------
// Footer (.[prefix-]footer)
// ----------------------------------------------

#{$config-class-prefix}footer {
  position: sticky;
  top: 100%;
  max-width: cssvar(page-max-width);
  margin: 0 auto;
  padding: 0 cssvar(spacing-2);
  overflow: hidden;

  &--fullwidth {
    max-width: none;
  }

  &__illustration {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    place-items: end stretch;
    margin-top: cssvar(spacing-2);
  }

  &__img {
    display: block;
    object-fit: cover;
    height: 100%;
    min-height: 150px; // 150px is the height of all mobile footer illustrations as of v. 4.1.0

    &--desktop {
      display: none;
      min-height: 207px; // 207px is the height of all desktop footer illustrations as of v. 4.1.0
    }

    @include on-bp(sm) {
      display: none;

      &--desktop {
        display: block;
      }
    }
  }
}
