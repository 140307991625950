// ------------------------------------
// Footer Navigation
// ------------------------------------
// .[prefix-]footer-nav
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &footer-nav {
    $footer-nav: &;
    position: relative;
    border-bottom: 1px solid cssvar(line-primary-color);
    font-family: inherit;

    @include on-bp(sm) {
      border-bottom: 0;
    }

    &--first {
      border-top: 1px solid cssvar(line-primary-color);

      @include on-bp(sm) {
        border-top: 0;
      }
    }

    &__items {
      display: flex;
      flex-direction: column;
      padding: 0 cssvar(spacing-2) cssvar(spacing-1);
      list-style: none;
      gap: cssvar(spacing-1);

      // items are only hidden if there is title
      @at-root #{$footer-nav}__title ~ &:not(&--open) {
        display: none;

        @include on-bp(sm) {
          display: flex;
        }
      }

      @include on-bp(sm) {
        padding-right: 0;
        padding-left: 0;
      }

      @at-root #{$footer-nav}--horizontal & {
        flex-direction: row;
        flex-wrap: wrap;
        gap: cssvar(spacing-2);
      }

      // if there is no title we wanna add some spacing on top to push it back from the divider line
      &:first-child {
        padding-top: cssvar(spacing-2);
      }

      &--open {
        display: flex;
      }
    }

    &__link:is(button) {
      @include btn-reset($padding: false, $font-size: false);
    }

    &__link {
      @include show-as-interactive-text($color: cssvar(content-primary-color));
      width: stretch;
      margin-left: calc(-1 * #{cssvar(spacing-half)}); // compensate paddings to align with title
      padding: cssvar(spacing-1) cssvar(spacing-half);
      border-radius: cssvar(primary-border-radius);
      text-decoration: none;

      @include icon-label;
      @include hover-state;
      @include pressed-state;
      @include focus-state;

      #{$footer-nav}__item:first-child & {
        margin-top: calc(-1 * #{cssvar(spacing-half)}); // compensate paddings to align with title
      }

      #{$footer-nav}--horizontal #{$footer-nav}__item:first-child & {
        margin-top: 0; // vertical padding compensation is not needed on horizontal layout
      }
    }

    &__title {
      @include show-as-h4;
      position: relative;
      padding: cssvar(spacing-2);

      @include icon-label;
      @include hover-state;

      &::after {
        content: '';
        @include flix-icon-arrow-down;
        position: absolute;
        top: 50%;
        right: cssvar(spacing-2);
        margin-top: -11px;
      }

      @include on-bp(sm) {
        padding-right: 0;
        padding-left: 0;

        &::after {
          display: none;
        }

        &:hover {
          background: transparent; // hover state is only needed in mobile view
        }
      }

      &--open {
        &::after {
          transform: rotate(180deg); // optimizing the file size achieving the same icon with transform
        }
      }
    }
  }
}
