// ------------------------------------
// GRID CLASSES
// ------------------------------------

@use 'sass:list';
@use 'sass:map';

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &grid-container {
    @include grid-container;
  }

  &grid-container--gutter-4 {
    @include grid-container(4);
  }

  &grid {
    @include grid-row;

    &--align-bottom {
      align-items: flex-end;
    }

    &--align-top {
      align-items: flex-start;
    }

    &--align-center {
      align-items: center;
    }

    &--justify-center {
      justify-content: center;
    }

    &--justify-left {
      justify-content: flex-start;
    }

    &--justify-right {
      justify-content: flex-end;
    }

    &--justify-space-between {
      justify-content: space-between;
    }
  }

  &grid--gutter-4 {
    @include grid-row($gutter-size: 4);
  }

  @each $size, $value in $breakpoints {
    // skips breakpoints for the smallest "zero" breakpoint
    @if list.index(map.keys($breakpoints), $size) == 1 {
      @for $i from 1 through $grid-columns {
        // grid column classes follow .flix-col-{$columns} naming pattern
        // default grid col without modifier
        &col-#{$i} {
          @include grid-col($i);
        }

        // grid col with grid gutter 4
        &grid--gutter-4 &col-#{$i} {
          @include grid-col($i, 4);
        }

        // margin push utility classes follow .flix-push-{$columns} naming pattern
        &push-#{$i} {
          @include grid-push($i);
        }
      }
    } @else {
      @include on-bp($size) {
        @for $i from 1 through $grid-columns {
          // grid column classes follow .flix-col-{$columns}-{$breakpoint} naming pattern
          // default grid col without modifier
          &col-#{$i}-#{$size} {
            @include grid-col($i);
          }

          // grid col with grid gutter 4
          &grid--gutter-4 &col-#{$i}-#{$size} {
            @include grid-col($i, 4);
          }

          // margin push utility classes follow .flix-push-{$columns}-{$breakpoint} naming pattern
          &push-#{$i}-#{$size} {
            @include grid-push($i);
          }
        }
      }
    }
  }

  // flexible columns that fill in the whole row
  // default grid gutter without modifier
  &col {
    flex: 1;
    margin: 0 calc(#{grid-gutter()} * 0.5);

    &--inline {
      flex-basis: auto;
      flex-grow: 0;
    }
  }

  // with grid gutter 4 modifier
  &grid--gutter-4 &col {
    margin: 0 calc(#{grid-gutter(4)} * 0.5);
  }
}
