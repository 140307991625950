// ------------------------------------
// Dropdown quarks
// Used by dropdown and header-nav-subnav
// ------------------------------------
/// @access private
// ------------------------------------

@use 'balloon' as *;
@use 'cssvars' as *;
@use 'icon-label' as *;
@use 'reset' as *;
@use 'states' as *;
@use 'tooltip' as *;
@use 'typography' as *;

@mixin dropdown-items-bottom($top: 100%) {
  @extend %balloon;
  @extend %balloon-position-bottom;
  @include tooltip-position-bottom($top: $top);
  @include tooltip-alignment-center-x;
  display: none;
  position: absolute;
  flex-direction: column;
  margin: calc(-1 * #{cssvar(spacing-1)}) 0 0; // dropdowns have smaller space between target and balloon
  padding: cssvar(spacing-4) 0 cssvar(spacing-2);

  // `::before` pseudo element is used to create a faux background
  &::before {
    @extend %balloon-faux-background;
  }

  // `::after` pseudo element is used to create the balloon arrow
  &::after {
    @extend %balloon-arrow;
    @extend %balloon-arrow-up;
    @extend %balloon-arrow-center-x;
  }
}

@mixin dropdown-items-top {
  @extend %balloon-position-top;
  @include tooltip-position-top;
  margin: 0 0 calc(-1 * #{cssvar(spacing-1)}); // dropdowns have smaller space between target and balloon
  padding: cssvar(spacing-2) 0 cssvar(spacing-4);

  // `::after` pseudo element is used to create the balloon arrow
  &::after {
    @extend %balloon-arrow-down;
    @extend %balloon-arrow-center-x;
  }
}

@mixin dropdown-items-left {
  @include tooltip-alignment-end-x;

  &::after {
    @extend %balloon-arrow-end-x;
  }
}

@mixin dropdown-items-right {
  @include tooltip-alignment-start-x;

  &::after {
    @extend %balloon-arrow-start-x;
  }
}

// dropdown list item
@mixin dropdown-item {
  list-style: none;
  text-align: left; // override text-align rules from parent elements

  &:not(:last-of-type) {
    margin-bottom: cssvar(spacing-1);
  }
}

// dropdown item content general styles (applies also to text nodes)
@mixin dropdown-link-content {
  @include show-as-text($color: cssvar(content-secondary-color));
  position: relative;
  width: stretch;
  padding: cssvar(spacing-1) cssvar(spacing-4);
  text-decoration: none;
  @include icon-label;
  @include selected-state;
}

// dropdown link or button
@mixin dropdown-link-interactions {
  @include btn-reset($padding: false, $font-size: false);
  font-weight: cssvar(font-weight-semibold);
  @include hover-state;
  @include pressed-state;

  &:focus-visible {
    @include focus-state-style($force-round-corners: true, $outline-offset: -2px);

    &::before {
      // since the active line has `position: absolute;` it always shows over the outline
      // so we hide the line when is focused
      opacity: 0;
    }
  }
}
