// ----------------------------------------------
// Switch (checkbox) (.[prefix-]switch)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ------------------------------------
// style variables
// ------------------------------------
$input-switch-thumb-size: cssvar(spacing-5);
$input-switch-thumb-border: 2px solid cssvar(input-border-color);
$input-switch-track-width: cssvar(spacing-8);
$input-switch-track-height: cssvar(spacing-5);
$input-switch-checked-thumb-displacement: calc($input-switch-track-width - $input-switch-thumb-size);
$input-switch-bg-color: cssvar(input-border-color);
$input-switch-control-bg-color: cssvar(grayscale-0-color);
$input-switch-color: cssvar(ui-primary-color);
$input-switch-track-radius: $input-switch-track-height;
$input-switch-hover-state-displacement: cssvar(spacing-half);

// smaller version
// @todo consider refactoring to maps as it's done in radios and checkboxes if more sizes are needed
$input-switch-thumb-size-sm: cssvar(spacing-3);
$input-switch-track-width-sm: cssvar(spacing-5);
$input-switch-track-height-sm: cssvar(spacing-3);
$input-switch-track-radius-sm: $input-switch-track-height-sm;
$input-switch-checked-thumb-displacement-sm: calc($input-switch-track-width-sm - $input-switch-thumb-size-sm);

#{$config-class-prefix} {
  &switch {
    $switch: &;
    display: block;
    line-height: $input-switch-thumb-size;

    @include input-info-text;

    // on mobile only take full width and align right
    @include on-bp(sm) {
      display: inline-block;
    }

    // hides original checkbox input, while still keeping it accessible to screen readers
    &__input {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
    }

    &__input:enabled + &__label:hover {
      @include hover-state-style;
    }

    &__label {
      @include show-as-text;
      display: grid;
      grid-template-columns: auto $input-switch-track-width;
      align-items: center;
      min-width: 100%;
      min-height: calc(#{$input-switch-track-height} + #{$input-switch-hover-state-displacement} * 2);
      margin: calc(-1 * #{$input-switch-hover-state-displacement});
      padding: $input-switch-hover-state-displacement;
      column-gap: cssvar(spacing-2);
      border-radius: cssvar(primary-border-radius);
      cursor: pointer;

      @include on-bp(sm) {
        grid-template-columns: $input-switch-track-width auto;
      }

      &::before,
      &::after {
        content: '';
        grid-column: 2;
        grid-row: 1;
        align-self: center;
        justify-self: end;

        @include on-bp(sm) {
          grid-column: 1;
        }
      }

      // slider
      &::before {
        width: $input-switch-track-width;
        height: $input-switch-track-height;
        border-radius: $input-switch-track-radius;
        background-color: $input-switch-bg-color;
        @include transition(background-color);
      }

      // thumb
      &::after {
        @include flix-icon-circle-solid($size: $input-switch-thumb-size, $color: $input-switch-control-bg-color);
        transform: translateX(calc($input-switch-checked-thumb-displacement * -1));

        @include transition(transform, mask-image);
      }

      // focus state
      #{$switch}__input:focus-visible + & {
        @include focus-state-style($force-round-corners: true);
      }

      // checked state
      #{$switch}__input:checked + &::after {
        // switch circle for checkmark
        @include flix-icon-checkmark-strong-solid($size: $input-switch-thumb-size, $color: $input-switch-control-bg-color);
        // moves thumb right to the other end of the track
        transform: translateX(0);
      }

      #{$switch}__input:checked + &::before {
        background-color: $input-switch-color;
      }
    }

    // smaller version of the switch
    &--sm {
      min-height: $input-switch-track-height-sm;
      line-height: $input-switch-thumb-size-sm;

      #{$switch}__label {
        @include on-bp(sm) {
          grid-template-columns: $input-switch-track-width-sm auto;
        }

        // slider
        &::before {
          width: $input-switch-track-width-sm;
          height: $input-switch-track-height-sm;
          border-radius: $input-switch-track-radius-sm;
        }

        // thumb
        &::after {
          width: $input-switch-thumb-size-sm;
          height: $input-switch-thumb-size-sm;
          transform: translateX(calc($input-switch-checked-thumb-displacement-sm * -1));
        }
      }

      #{$switch}__input:checked + #{$switch}__label::after {
        width: $input-switch-thumb-size-sm;
        height: $input-switch-thumb-size-sm;
      }
    }

    &--stacked {
      #{$switch}__label {
        grid-template-columns: auto;

        &::before,
        &::after {
          grid-column: 1;
          grid-row: 2;
          justify-self: start;
        }

        // thumb
        &::after {
          transform: translateX(0);
        }
      }

      // when checked, move thumb right
      #{$switch}__input:checked + #{$switch}__label::after {
        transform: translateX($input-switch-checked-thumb-displacement);
      }

      @include on-bp(xl) {
        color: cssvar(content-secondary-color); // we want switch label to be same color with inputs in this case
      }
    }

    &--disabled {
      opacity: cssvar(disabled-element-opacity);

      #{$switch}__label {
        cursor: not-allowed;
      }
    }
  }
}
