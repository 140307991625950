// ----------------------------------------------
// Balloon (.[prefix-]balloon)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &balloon {
    @extend %balloon;
    @extend %balloon-position-right;

    &:not([hidden]) {
      @extend %balloon-display;
    }

    // `::before` pseudo element is used to create a faux background
    &::before {
      @extend %balloon-faux-background;
    }

    // `::after` pseudo element is used to create the balloon arrow
    &::after {
      @extend %balloon-arrow;
      @extend %balloon-arrow-left;
      @extend %balloon-arrow-center-y;
    }

    &--content-fit {
      @extend %balloon-content-fit;
    }

    &--medium {
      @extend %balloon-medium;
    }

    &--large {
      @extend %balloon-large;
    }

    &--danger {
      @extend %balloon-danger;
    }

    &--left {
      @extend %balloon-position-left;

      &::after {
        @extend %balloon-arrow-right;
      }
    }

    &--top::after,
    &--bottom::after {
      @extend %balloon-arrow-center-x;
    }

    &--top {
      @extend %balloon-position-top;

      &::after {
        top: auto;
        @extend %balloon-arrow-down;
      }
    }

    &--bottom {
      @extend %balloon-position-bottom;

      &::after {
        @extend %balloon-arrow-up;
      }
    }

    &--start::after {
      @extend %balloon-arrow-start-y;
    }

    &--end::after {
      @extend %balloon-arrow-end-y;
    }

    &--start#{&}--top::after,
    &--start#{&}--bottom::after {
      @extend %balloon-arrow-start-x;
    }

    &--end#{&}--top::after,
    &--end#{&}--bottom::after {
      @extend %balloon-arrow-end-x;
    }

    &__content {
      @extend %balloon-content;
    }

    &__close {
      @extend %balloon-close;
    }
  }
}
