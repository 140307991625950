// ----------------------------------------------
// Connection (.[prefix-]connection)
// ----------------------------------------------

@use "sass:map";
@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ------------------------------------
// style variables
// ------------------------------------
$connection-item-left-margin: cssvar(spacing-7);
$connection-item-icon-size: cssvar(spacing-2);
$connection-item-icon-current-size: cssvar(spacing-5);
$connection-horizontal-line-displacement: calc(-1 * ($connection-item-left-margin - $connection-item-icon-size - $connection-item-icon-size / 2));

#{$config-class-prefix} {
  &connection {
    $connection: &;
    @include cssvars((
      connection-status-color: cssvar(icon-primary-color),
    ));

    &__title {
      @include show-as-h4;
      margin-bottom: cssvar(spacing-1);
      text-align: left;

      &-arrival {
        font-weight: cssvar(font-weight-normal);
      }
    }

    &__day {
      font-size: 0.65em; // computes to 10px when __time font is 16px, scales properly if __time font size changes
      font-weight: cssvar(font-weight-normal);
      line-height: 1;
      vertical-align: text-top;
    }

    // drawing bus/icon marker indicating current position of the bus
    &__icon {
      display: none;
      position: absolute;
      z-index: z('connection-decor');
      top: calc((#{cssvar(line-height-primary)} - $connection-item-icon-current-size) / 2);
      // margin to the connection item + half the size of the icon to center it properly
      left: calc($connection-horizontal-line-displacement - $connection-item-icon-current-size / 2);
      width: $connection-item-icon-current-size;
      height: $connection-item-icon-current-size;
      padding: cssvar(spacing-half);
      animation-name: connection-marker-pulsing-outline;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      border: 3px solid cssvar(grayscale-0-color);
      border-radius: 100%;
      background-color: cssvar(icon-primary-color);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cssvar(spacing-3);
      box-shadow: 0 0 0 1px cssvar(icon-primary-color);
      color: cssvar(grayscale-0-color);
    }
    // leaving the animation in here for now as it's solely used within this component
    @keyframes connection-marker-pulsing-outline {
      100% {
        box-shadow: 0 0 0 cssvar(spacing-1) rgba(255, 255, 255, 0%);
      }
    }

    // making the icon position nicely within an empty slot
    &__icon:only-child {
      top: calc(-1 * #{cssvar(spacing-2)});;
    }

    &__item {
      position: relative; // making the borders work
      flex-wrap: wrap;
      justify-content: space-between;
      margin: cssvar(spacing-2) 0 0 $connection-item-left-margin;

      &:not([hidden]) {
        display: flex;
      }

      &:first-child {
        margin: 0 0 0 $connection-item-left-margin;

        // if there are only 2 elements - simple connection, no spacing between items is needed
        + #{$connection}__item:last-child {
          margin: 0 0 0 $connection-item-left-margin;
        }

        &::after {
          // @todo replace with rem tokens once introduced
          top: map.get($rem-spacing, 2); // making sure vertical position of the dot matches font size scaling
        }
      }

      &:last-child {
        &::after {
          bottom: calc(100% - #{map.get($rem-spacing, 2)});
        }
      }

      // horizontal line going to connection items
      &::before {
        content: '';
        position: absolute;
        z-index: z('connection-decor');
        top: calc(#{cssvar(line-height-primary)} / 2);
        // @todo this calculations are quite complex, find a way to optimize that if performance is affected
        left: $connection-horizontal-line-displacement;
        width: cssvar(spacing-1);
        height: 2px;
        background-color: cssvar(icon-primary-color);
        vertical-align: middle;
      }

      // first and last elements have dots instead of lines
      &:not(&--current):first-child::before,
      &:not(&--current):last-child::before {
        display: inline-block;
        // @todo replace with rem tokens once introduced
        top: map.get($rem-spacing, 2); // making sure vertical position of the dot matches font size scaling
        left: calc(-1 * ($connection-item-left-margin - $connection-item-icon-size));
        width: $connection-item-icon-size;
        height: $connection-item-icon-size;
        margin-top: calc(-1 * #{cssvar(spacing-1)});
        border: 2px solid cssvar(icon-primary-color);
        border-radius: 50%;
        background: cssvar(bg-primary-color);
      }

      // Continuous vertical line (a.k.a. route) of this connection
      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: z('base');
        top: calc(-1 * #{cssvar(line-height-primary)} / 2);
        bottom: calc(-1 * #{cssvar(line-height-primary)} / 2);
        left: calc(-1 * ($connection-item-left-margin - $connection-item-icon-size - $connection-item-icon-size / 2) - 1px); // 1px here compensates track width
        width: 2px;
        background-color: cssvar(icon-primary-color);
      }

      // hiding station markers for slot items
      &--slot:not(&--current)::before {
        display: none;
      }

      &--current {
        // drawing bus marker indicating current position of the bus
        #{$connection}__icon {
          display: initial;
          box-shadow: 0 0 0 1px cssvar(connection-status-color);
        }
        // leaving the animation in here for now as it's solely used within this component
        @keyframes connection-marker-pulsing-outline {
          100% {
            box-shadow: 0 0 0 cssvar(spacing-1) rgba(255, 255, 255, 0%);
          }
        }

        &::after {
          border-top: cssvar(spacing-2) solid cssvar(connection-status-color);
          background: linear-gradient(#{cssvar(connection-status-color)} 0 #{cssvar(line-height-primary)}, cssvar(icon-primary-color) #{cssvar(line-height-primary)} 100%);
        }
      }

      &::before,
      &::after,
      &--current:first-child::before,
      &--current:last-child::before,
      #{$connection}__icon {
        background-color: cssvar(connection-status-color);
      }

      &:not(#{$connection}__item--current):first-child::before,
      &:not(#{$connection}__item--current):last-child::before {
        border-color: cssvar(connection-status-color);
        background-color: cssvar(bg-primary-color);
      }

      // status colors for route and markers
      #{$connection}--success & {
        @include cssvars((
          connection-status-color: cssvar(success-color),
        ));
      }

      #{$connection}--warning & {
        @include cssvars((
          connection-status-color: cssvar(warning-color),
        ));
      }

      #{$connection}--danger & {
        @include cssvars((
          connection-status-color: cssvar(danger-color),
        ));
      }

      // using nested selectors to keep the same specificity as with the status variations
      #{$connection} &--current ~ & {
        &::before,
        &::after {
          background-color: cssvar(icon-primary-color);
        }

        &:first-child::before,
        &:last-child::before {
          border-color: cssvar(icon-primary-color);
          background-color: cssvar(bg-primary-color);
        }
      }
    }

    // default text styles for stations as a fallback when no typography was provided from the user
    &__station {
      @include show-as-text;
      flex: 1;
    }

    &__time {
      margin-left: cssvar(spacing-2);
      text-align: right;
      // remove default underscore from `ins` element
      ins {
        text-decoration: none;
      }
      // if only one set of times were provided we want every item to be place on a separate row
      > time,
      > ins,
      > del {
        display: block;
      }
      // for the case of arrival and departure time sets (2 lines) we want the new times to be spaced out from the old ones
      del + ins {
        margin-left: cssvar(spacing-1);
      }
    }

    &__content {
      width: 100%;
    }
  }
}
