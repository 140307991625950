// ----------------------------------------------
// Calendar (.prefix-calendar)
// ----------------------------------------------

@use "sass:map";
@use '../common/config/all' as *;
@use '../common/utils/all' as *;

$calendar: #{$config-class-prefix}calendar;
$calendar-btn: #{$config-class-prefix}calendar__btn;

#{$config-class-prefix} {
  &calendar {
    z-index: z('dropdown');
    min-width: calc(#{cssvar(spacing-1)} * 46); // 276px
    border-radius: cssvar(primary-border-radius);
    background-color: cssvar(box-bg-color);
    box-shadow: cssvar(primary-box-shadow);

    &--flat {
      border-radius: 0;
      box-shadow: none;
    }

    &--horizontal {
      display: grid;
      grid-auto-flow: column;
    }

    &__month {
      padding: cssvar(spacing-2);
    }

    &__table {
      width: 100%;
      border-spacing: 0 cssvar(spacing-half);
    }

    &__caption {
      @include show-as-h3;
    }

    &__btn,
    &__cell {
      position: relative;
      font-family: cssvar(font-family-primary);
      line-height: map.get($rem-spacing, 6);
      text-align: center;
    }

    &__cell {
      &--range:not(:empty) {
        background-color: cssvar(highlight-color);
      }

      &--start #{$calendar-btn},
      &--end #{$calendar-btn},
      &--selected #{$calendar-btn}{
        background-color: cssvar(button-primary-color);
        color: cssvar(button-label-color);
      }

      &--end #{$calendar-btn}::before,
      &--start #{$calendar-btn}::before {
        content: ' ';
        position: absolute;
        top: 50%;
        width: cssvar(spacing-2);
        height: cssvar(spacing-2);
        margin-top: calc(-1 * #{cssvar(spacing-1)});
        transform: rotate(45deg);
        background-color: cssvar(button-primary-color);
        pointer-events: none;
      }

      &--start {
        &:not(:empty) {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0%) 0%,
            cssvar(highlight-color) 50%
          );
        }

        #{$calendar-btn}::before {
          left: 100%;
          margin-left: calc(-1 * #{cssvar(spacing-1)});
        }
      }

      &--end {
        &:not(:empty) {
          background-image: linear-gradient(
            to right,
            cssvar(highlight-color) 50%,
            rgba(255, 255, 255, 0%) 50%
          );
        }

        #{$calendar-btn}::before {
          right: 100%;
          margin-right: calc(-1 * #{cssvar(spacing-1)});
        }
      }

      // if start and end are the same date - remove arrows
      &--start#{$calendar}__cell--end {
        background-image: none;

        #{$calendar-btn}::before {
          display: none;
        }
      }

      &--start #{$calendar-btn} {
        z-index: 1; // the arrow needs to overlap the following day
      }

      &--current #{$calendar-btn}::after {
        content: '';
        display: block;
        position: absolute;
        bottom: cssvar(spacing-half);
        left: 50%;
        width: map.get($rem-spacing, 3);
        height: map.get($rem-spacing, half);
        // using rem spacing vars as the marker needs to scale respectively with the text
        transform: translateX(calc(-1 * #{map.get($rem-spacing, 3)}/2));
        border-radius: cssvar(primary-border-radius);
        background-color: cssvar(content-primary-color);
      }

      &--selected#{$calendar}__cell--current #{$calendar-btn}::after,
      &--start#{$calendar}__cell--current #{$calendar-btn}::after,
      &--end#{$calendar}__cell--current #{$calendar-btn}::after {
        background-color: cssvar(button-label-color);
      }
    }

    &__btn {
      @include btn-reset($padding: cssvar(spacing-1) 0, $font-size: false);
      @include show-as-interactive-text($color: cssvar(content-primary-color));
      display: block;
      width: stretch;
      min-width: cssvar(spacing-6);
      min-height: cssvar(spacing-6);
      border-radius: cssvar(primary-border-radius);

      &:focus-visible {
        @include focus-state-style;
        z-index: 1;
      }
      // using style mixins to also style the arrow pseudo-element
      &:hover,
      &:hover::before {
        @include hover-state-style;
      }

      &:active,
      &:active::before {
        @include pressed-state-style;
      }

      &:disabled,
      &[aria-disabled="true"],
      &--disabled {
        @include disabled-state-style;

        &::before {
          @include disabled-state-style;
        }
      }
    }

    &-header {
      display: grid;
      grid-template-columns: min-content auto min-content;
      margin-bottom: cssvar(spacing-2);
      gap: cssvar(spacing-2);

      &--birthday {
        grid-template-columns: auto auto;
      }
    }
  }
}
