// ------------------------------------
// Spinner (.prefix-spinner)
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &spinner {
    display: inline-block;
    width: calc(#{cssvar(spacing-12)} + #{cssvar(spacing-3)}); // 90px
    height: calc(#{cssvar(spacing-12)} + #{cssvar(spacing-3)}); // 90px
    @include spinner;

    &--sm {
      width: cssvar(spacing-4);
      height: cssvar(spacing-4);
    }

    &--md {
      width: cssvar(spacing-6);
      height: cssvar(spacing-6);
    }

    &--lg {
      width: cssvar(spacing-8);
      height: cssvar(spacing-8);
    }

    // 72px
    &--xl {
      width: cssvar(spacing-12);
      height: cssvar(spacing-12);
    }

    // 96px
    &--xxl {
      width: calc(#{cssvar(spacing-12)} + #{cssvar(spacing-4)});
      height: calc(#{cssvar(spacing-12)} + #{cssvar(spacing-4)});
    }
  }
}
