:root {
  --flix-brand-primary-color: #97d700;
  --flix-ui-primary-color: #31a100;
  --flix-ui-primary-light-color: #5cc500;
  --flix-ui-primary-dark-color: #187d00;
  --flix-grayscale-0-color: #ffffff;
  --flix-grayscale-10-color: #f7f7f7;
  --flix-grayscale-30-color: #e1e1e1;
  --flix-grayscale-50-color: #c8c8c8;
  --flix-grayscale-70-color: #646464;
  --flix-grayscale-90-color: #444444;
  --flix-grayscale-100-color: #353535;
  --flix-neutral-color: #016ae7;
  --flix-success-color: #228f00;
  --flix-success-dark-color: #136b00;
  --flix-warning-color: #ff5704;
  --flix-warning-dark-color: #c42d00;
  --flix-danger-color: #dd2828;
  --flix-danger-dark-color: #b31414;
  --flix-bg-primary-color: #ffffff;
  --flix-bg-primary-transparent-color: rgba(255, 255, 255, 0.88);
  --flix-bg-secondary-color: #f7f7f7;
  --flix-box-bg-color: #ffffff;
  --flix-highlight-color: #e5f9c0;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #353535;
  --flix-content-secondary-color: #646464;
  --flix-heading-font-color: #353535;
  --flix-link-color: #0047de;
  --flix-line-primary-color: #c8c8c8;
  --flix-icon-primary-color: #8b8b8b;
  --flix-icon-secondary-color: #97d700;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #97d700;
  --flix-button-label-color: #353535;
  --flix-header-bg-color: #97d700;
  --flix-header-color: #353535;
  --flix-header-nav-bg-color-tablet: #5cc500;
  --flix-input-border-color: #8b8b8b;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-grid-gutter-2: 12px;
  --flix-grid-gutter-4: 24px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-base: 1rem;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.875rem;
  --flix-font-size-fineprint: 0.75rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.125rem;
  --flix-line-height-fineprint: 0.938rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-color-scheme: normal;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 500;
  --flix-font-weight-bold: 700;
}

:root {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-base);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  color-scheme: var(--flix-color-scheme);
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  :root {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: var(--flix-font-family-primary);
  }
}

.flix-theme-flix-dark {
  --flix-brand-primary-color: #97d700;
  --flix-ui-primary-color: #97d700;
  --flix-ui-primary-light-color: #44b300;
  --flix-ui-primary-dark-color: #cef38d;
  --flix-grayscale-0-color: #151515;
  --flix-grayscale-10-color: #252525;
  --flix-grayscale-30-color: #444444;
  --flix-grayscale-50-color: #646464;
  --flix-grayscale-70-color: #8b8b8b;
  --flix-grayscale-90-color: #c8c8c8;
  --flix-grayscale-100-color: #ffffff;
  --flix-neutral-color: #039fd5;
  --flix-success-color: #44b300;
  --flix-success-dark-color: #cef38d;
  --flix-warning-color: #ff8206;
  --flix-warning-dark-color: #ffe393;
  --flix-danger-color: #fd5a5a;
  --flix-danger-dark-color: #ffc9ce;
  --flix-bg-primary-color: #151515;
  --flix-bg-primary-transparent-color: rgba(53, 53, 53, 0.88);
  --flix-bg-secondary-color: #121212;
  --flix-box-bg-color: #252525;
  --flix-highlight-color: #0a3c00;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #ffffff;
  --flix-content-secondary-color: #c8c8c8;
  --flix-heading-font-color: #ffffff;
  --flix-link-color: #3bd0de;
  --flix-line-primary-color: #646464;
  --flix-icon-primary-color: #ffffff;
  --flix-icon-secondary-color: #97d700;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #97d700;
  --flix-button-label-color: #252525;
  --flix-header-bg-color: #252525;
  --flix-header-color: #ffffff;
  --flix-header-nav-bg-color-tablet: #202020;
  --flix-input-border-color: #c8c8c8;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-grid-gutter-2: 12px;
  --flix-grid-gutter-4: 24px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-base: 1rem;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.875rem;
  --flix-font-size-fineprint: 0.75rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.125rem;
  --flix-line-height-fineprint: 0.938rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-color-scheme: dark;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 500;
  --flix-font-weight-bold: 700;
}

.flix-theme-flix-dark {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-base);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  color-scheme: var(--flix-color-scheme);
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  .flix-theme-flix-dark {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: var(--flix-font-family-primary);
  }
}

.flix-theme-high-contrast {
  --flix-brand-primary-color: #ffff00;
  --flix-ui-primary-color: #ffffff;
  --flix-ui-primary-light-color: #ffffff;
  --flix-ui-primary-dark-color: #000000;
  --flix-grayscale-0-color: #000000;
  --flix-grayscale-10-color: #000000;
  --flix-grayscale-30-color: #000000;
  --flix-grayscale-50-color: #000000;
  --flix-grayscale-70-color: #ffffff;
  --flix-grayscale-90-color: #ffffff;
  --flix-grayscale-100-color: #ffffff;
  --flix-neutral-color: #ffff00;
  --flix-success-color: #ffff00;
  --flix-success-dark-color: #ffff00;
  --flix-warning-color: #ffff00;
  --flix-warning-dark-color: #ffff00;
  --flix-danger-color: #ffff00;
  --flix-danger-dark-color: #ffff00;
  --flix-bg-primary-color: #000000;
  --flix-bg-primary-transparent-color: rgba(0, 0, 0, 0.88);
  --flix-bg-secondary-color: #000000;
  --flix-box-bg-color: #000000;
  --flix-highlight-color: #000000;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #ffff00;
  --flix-content-primary-color: #ffffff;
  --flix-content-secondary-color: #ffffff;
  --flix-heading-font-color: #ffffff;
  --flix-link-color: #ffff00;
  --flix-line-primary-color: #ffffff;
  --flix-icon-primary-color: #ffffff;
  --flix-icon-secondary-color: #ffffff;
  --flix-box-shadow-color: rgba(255, 255, 255, 0.18);
  --flix-box-shadow-subtle-color: rgba(255, 255, 255, 0.06);
  --flix-button-primary-color: #ffffff;
  --flix-button-label-color: #000000;
  --flix-header-bg-color: #000000;
  --flix-header-color: #ffffff;
  --flix-header-nav-bg-color-tablet: #000000;
  --flix-input-border-color: #ffffff;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-grid-gutter-2: 12px;
  --flix-grid-gutter-4: 24px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-base: 1rem;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.875rem;
  --flix-font-size-fineprint: 0.75rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.125rem;
  --flix-line-height-fineprint: 0.938rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: inset -2px -2px 0 #fff, inset 2px 2px 0 #fff;
  --flix-color-scheme: normal;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 500;
  --flix-font-weight-bold: 700;
}

.flix-theme-high-contrast {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-base);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  color-scheme: var(--flix-color-scheme);
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  .flix-theme-high-contrast {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: var(--flix-font-family-primary);
  }
}

.flix-theme-kamil {
  --flix-brand-primary-color: #004b8d;
  --flix-ui-primary-color: #082967;
  --flix-ui-primary-light-color: #3eb5f1;
  --flix-ui-primary-dark-color: #00003c;
  --flix-grayscale-0-color: #ffffff;
  --flix-grayscale-10-color: #f7f7f7;
  --flix-grayscale-30-color: #e1e1e1;
  --flix-grayscale-50-color: #c8c8c8;
  --flix-grayscale-70-color: #646464;
  --flix-grayscale-90-color: #444444;
  --flix-grayscale-100-color: #353535;
  --flix-neutral-color: #016ae7;
  --flix-success-color: #228f00;
  --flix-success-dark-color: #136b00;
  --flix-warning-color: #ff5704;
  --flix-warning-dark-color: #c42d00;
  --flix-danger-color: #dd2828;
  --flix-danger-dark-color: #b31414;
  --flix-bg-primary-color: #ffffff;
  --flix-bg-primary-transparent-color: rgba(255, 255, 255, 0.88);
  --flix-bg-secondary-color: #f7f7f7;
  --flix-box-bg-color: #ffffff;
  --flix-highlight-color: #e3ebfa;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #353535;
  --flix-content-secondary-color: #646464;
  --flix-heading-font-color: #353535;
  --flix-link-color: #0047de;
  --flix-line-primary-color: #c8c8c8;
  --flix-icon-primary-color: #8b8b8b;
  --flix-icon-secondary-color: #082967;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #082967;
  --flix-button-label-color: #ffffff;
  --flix-header-bg-color: #082967;
  --flix-header-color: #ffffff;
  --flix-header-nav-bg-color-tablet: #00003c;
  --flix-input-border-color: #8b8b8b;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-grid-gutter-2: 12px;
  --flix-grid-gutter-4: 24px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-base: 1rem;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.875rem;
  --flix-font-size-fineprint: 0.75rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.125rem;
  --flix-line-height-fineprint: 0.938rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-color-scheme: normal;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 500;
  --flix-font-weight-bold: 700;
}

.flix-theme-kamil {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-base);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  color-scheme: var(--flix-color-scheme);
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  .flix-theme-kamil {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: var(--flix-font-family-primary);
  }
}

.flix-theme-kamil-dark {
  --flix-brand-primary-color: #004b8d;
  --flix-ui-primary-color: #3eb5f1;
  --flix-ui-primary-light-color: #0597dd;
  --flix-ui-primary-dark-color: #95d8f8;
  --flix-grayscale-0-color: #151515;
  --flix-grayscale-10-color: #252525;
  --flix-grayscale-30-color: #444444;
  --flix-grayscale-50-color: #646464;
  --flix-grayscale-70-color: #8b8b8b;
  --flix-grayscale-90-color: #c8c8c8;
  --flix-grayscale-100-color: #ffffff;
  --flix-neutral-color: #039fd5;
  --flix-success-color: #44b300;
  --flix-success-dark-color: #cef38d;
  --flix-warning-color: #ff8206;
  --flix-warning-dark-color: #ffe393;
  --flix-danger-color: #fd5a5a;
  --flix-danger-dark-color: #ffc9ce;
  --flix-bg-primary-color: #151515;
  --flix-bg-primary-transparent-color: rgba(53, 53, 53, 0.88);
  --flix-bg-secondary-color: #121212;
  --flix-box-bg-color: #252525;
  --flix-highlight-color: #00003c;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #ffffff;
  --flix-content-secondary-color: #c8c8c8;
  --flix-heading-font-color: #ffffff;
  --flix-link-color: #3bd0de;
  --flix-line-primary-color: #646464;
  --flix-icon-primary-color: #ffffff;
  --flix-icon-secondary-color: #0597dd;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #3eb5f1;
  --flix-button-label-color: #252525;
  --flix-header-bg-color: #252525;
  --flix-header-color: #ffffff;
  --flix-header-nav-bg-color-tablet: #202020;
  --flix-input-border-color: #c8c8c8;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-grid-gutter-2: 12px;
  --flix-grid-gutter-4: 24px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-base: 1rem;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.875rem;
  --flix-font-size-fineprint: 0.75rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.125rem;
  --flix-line-height-fineprint: 0.938rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-color-scheme: dark;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 500;
  --flix-font-weight-bold: 700;
}

.flix-theme-kamil-dark {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-base);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  color-scheme: var(--flix-color-scheme);
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  .flix-theme-kamil-dark {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: var(--flix-font-family-primary);
  }
}

.flix-theme-neptune {
  --flix-brand-primary-color: #004963;
  --flix-ui-primary-color: #004963;
  --flix-ui-primary-light-color: #00759c;
  --flix-ui-primary-dark-color: #003547;
  --flix-grayscale-0-color: #ffffff;
  --flix-grayscale-10-color: #f7f7f7;
  --flix-grayscale-30-color: #e1e1e1;
  --flix-grayscale-50-color: #c8c8c8;
  --flix-grayscale-70-color: #646464;
  --flix-grayscale-90-color: #444444;
  --flix-grayscale-100-color: #353535;
  --flix-neutral-color: #016ae7;
  --flix-success-color: #228f00;
  --flix-success-dark-color: #136b00;
  --flix-warning-color: #ff5704;
  --flix-warning-dark-color: #c42d00;
  --flix-danger-color: #dd2828;
  --flix-danger-dark-color: #b31414;
  --flix-bg-primary-color: #ffffff;
  --flix-bg-primary-transparent-color: rgba(255, 255, 255, 0.88);
  --flix-bg-secondary-color: #f7f7f7;
  --flix-box-bg-color: #ffffff;
  --flix-highlight-color: #aee9fc;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #353535;
  --flix-content-secondary-color: #646464;
  --flix-heading-font-color: #004963;
  --flix-link-color: #bd5604;
  --flix-line-primary-color: #c8c8c8;
  --flix-icon-primary-color: #8b8b8b;
  --flix-icon-secondary-color: #ef782c;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #004963;
  --flix-button-label-color: #ffffff;
  --flix-header-bg-color: #004963;
  --flix-header-color: #ffffff;
  --flix-header-nav-bg-color-tablet: #003547;
  --flix-input-border-color: #8b8b8b;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-grid-gutter-2: 12px;
  --flix-grid-gutter-4: 24px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-base: 1rem;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.875rem;
  --flix-font-size-fineprint: 0.75rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.125rem;
  --flix-line-height-fineprint: 0.938rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-color-scheme: normal;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 500;
  --flix-font-weight-bold: 700;
}

.flix-theme-neptune {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-base);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  color-scheme: var(--flix-color-scheme);
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  .flix-theme-neptune {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: var(--flix-font-family-primary);
  }
}

.flix-theme-neptune-dark {
  --flix-brand-primary-color: #004963;
  --flix-ui-primary-color: #00a6de;
  --flix-ui-primary-light-color: #005c7b;
  --flix-ui-primary-dark-color: #21c5fc;
  --flix-grayscale-0-color: #151515;
  --flix-grayscale-10-color: #252525;
  --flix-grayscale-30-color: #444444;
  --flix-grayscale-50-color: #646464;
  --flix-grayscale-70-color: #8b8b8b;
  --flix-grayscale-90-color: #c8c8c8;
  --flix-grayscale-100-color: #ffffff;
  --flix-neutral-color: #039fd5;
  --flix-success-color: #44b300;
  --flix-success-dark-color: #cef38d;
  --flix-warning-color: #ff8206;
  --flix-warning-dark-color: #ffe393;
  --flix-danger-color: #fd5a5a;
  --flix-danger-dark-color: #ffc9ce;
  --flix-bg-primary-color: #151515;
  --flix-bg-primary-transparent-color: rgba(53, 53, 53, 0.88);
  --flix-bg-secondary-color: #121212;
  --flix-box-bg-color: #252525;
  --flix-highlight-color: #003547;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #ffffff;
  --flix-content-secondary-color: #c8c8c8;
  --flix-heading-font-color: #ffffff;
  --flix-link-color: #ffaf6e;
  --flix-line-primary-color: #646464;
  --flix-icon-primary-color: #ffffff;
  --flix-icon-secondary-color: #ef782c;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #004963;
  --flix-button-label-color: #ffffff;
  --flix-header-bg-color: #252525;
  --flix-header-color: #ffffff;
  --flix-header-nav-bg-color-tablet: #202020;
  --flix-input-border-color: #c8c8c8;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-grid-gutter-2: 12px;
  --flix-grid-gutter-4: 24px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-base: 1rem;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.875rem;
  --flix-font-size-fineprint: 0.75rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.125rem;
  --flix-line-height-fineprint: 0.938rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-color-scheme: dark;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 500;
  --flix-font-weight-bold: 700;
}

.flix-theme-neptune-dark {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-base);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  color-scheme: var(--flix-color-scheme);
  /* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {
  .flix-theme-neptune-dark {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font: -apple-system-body;
    font-family: var(--flix-font-family-primary);
  }
}