// ----------------------------------------------
// header-bar (.[prefix-]header-bar)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &header-bar {
    @include cssvars((
      container-content-color: cssvar(header-color),
    ));
    z-index: z('header');
    background: cssvar(header-bg-color);

    &--fixed {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
    }

    &__content {
      display: flex;
      position: relative;
      align-items: center;
      width: 100%;
      min-width: cssvar(page-min-width);
      max-width: cssvar(page-max-width);
      min-height: cssvar(spacing-10);
      margin: 0 auto;
      padding: 0 cssvar(spacing-2);
    }

    // disables the max-width limit
    &--fullwidth &__content {
      max-width: none;
    }
  }
}
