// ----------------------------------------------
// Number (quantity) input (.[prefix-]quantity)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &quantity {
    $quantity: &;
    @include input-info-text;

    &__label {
      display: block;
      margin-bottom: cssvar(spacing-half);
      color: cssvar(content-primary-color);

      @include on-bp(xl) {
        color: cssvar(content-secondary-color);
      }
    }

    // apply disabled style for all the elements inside when the root element has --disabled modifier
    &--disabled {
      opacity: cssvar(disabled-element-opacity);

      #{$quantity}__input,
      #{$quantity}__button {
        opacity: 1; // remove component specific opacity to avoid doubling down on the disabled state
        cursor: not-allowed;
      }
    }

    // apply error style for input element inside when the root element has --error modifier
    &--error {
      #{$quantity}__input {
        border-color: cssvar(danger-color);
        @include error-state-background-style;

        &:enabled:hover {
          @include error-state-background-style($with-hover: true);
        }
      }
    }

    &__picker {
      display: flex;
      align-items: stretch;

      + #{$quantity}__info {
        margin-top: cssvar(spacing-1);
      }
    }

    // number input shares common input styles mostly
    &__input {
      @include input-basic;
      align-self: center;
      width: cssvar(spacing-10);
      min-height: cssvar(input-height-mobile);
      border: 1px solid cssvar(input-border-color);
      border-radius: 0;
      background-color: cssvar(box-bg-color);
      text-align: center;

      // A bit of normalization for input[type=number] to sort out browser rendering differences on a Shadow DOM layer
      // @todo find a way to include to a general normalize kit maybe
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -moz-appearance: textfield; // Removes controls from Firefox

      @include input-on-breakpoint-xl {
        min-height: cssvar(input-height-desktop);
      };

      @include input-placeholder;
      @include hover-state;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        margin: 0; // Removes leftover margin
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-appearance: none; // Removes controls from Safari and Chrome
      }

      #{$quantity}--active &,
      &:focus-visible {
        @include focus-state-style($force-round-corners: true);
        z-index: z('base') + 1; // lifting up z-index in order to fight negative margins and make the whole outline visible
      }

      &[readonly] {
        border-width: 0 1px;
        font-weight: cssvar(font-weight-bold);

        &:focus {
          border-radius: 0;
          outline: none;
          box-shadow: none;
        }
      }
    }

    // increasing the specificity intentionally to override possible style ordering issues
    // e.g. when the button partial gets loaded after the quantity one
    & &__button {
      z-index: z('base'); // making sure button outline is clearly visible and not overlapped by input
      border-color: cssvar(input-border-color);

      // use icon SVGs for controls
      &::after {
        content: '';
      }

      &:first-of-type::after,
      &--minus::after {
        @include flix-icon-minus;
      }

      &:first-of-type {
        border-right: 0;
        border-radius: cssvar(primary-border-radius) 0 0 cssvar(primary-border-radius);
      }

      &:last-of-type::after,
      &--plus::after {
        @include flix-icon-plus;
      }

      &:last-of-type {
        border-left: 0;
        border-radius: 0 cssvar(primary-border-radius) cssvar(primary-border-radius) 0;
      }
    }

    &--inline {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: cssvar(spacing-half) cssvar(spacing-1);

      #{$quantity}__label {
        flex: 1;
        align-self: center;
        margin-bottom: 0;
        color: cssvar(content-primary-color);
        font-size: cssvar(font-size-primary);
        font-weight: cssvar(font-weight-bold);
        line-height: cssvar(line-height-primary);

        small {
          display: block;
          font-size: cssvar(font-size-small);
          font-weight: cssvar(font-weight-normal);
          line-height: cssvar(line-height-small);
        }
      }

      & #{$quantity}__picker  {
        justify-content: end;

        & + #{$quantity}__info:not(:empty) {
          margin-top: cssvar(spacing-half);
        }
      }

      // text needs to be aligned to the right in this variation
      & #{$quantity}__info {
        width: 100%;
        text-align: right;

        &:not(:empty) {
          margin-top: 0;
        }
      }
    }
  }
}
