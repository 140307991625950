// ------------------------------------
// Responsive visibility helpers
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// mimics bootstrap 3 utility classes, meant to achieve the compatibility with old flixbus layout
#{$config-class-prefix}hidden {
  display: none;
}

@include on-bp(zero, 'only') {
  #{$config-class-prefix}hidden-zero {
    display: none;
  }
}

@include on-bp(xs, 'only') {
  #{$config-class-prefix}hidden-xs {
    display: none;
  }
}

@include on-bp(sm, 'only') {
  #{$config-class-prefix}hidden-sm {
    display: none;
  }
}

@include on-bp(md, 'only') {
  #{$config-class-prefix}hidden-md {
    display: none;
  }
}

@include on-bp(lg, 'only') {
  #{$config-class-prefix}hidden-lg {
    display: none;
  }
}

@include on-bp(xl) {
  #{$config-class-prefix}hidden-xl {
    display: none;
  }
}
