// ------------------------------------
// Table and Data Table common styles
// ------------------------------------
/// @access private
// ------------------------------------

@use 'cssvars' as *;

@mixin table-caption {
  caption {
    padding: cssvar(spacing-2);
    caption-side: bottom;
    border-top: 1px solid cssvar(line-primary-color);
    color: cssvar(content-secondary-color);
    text-align: center;
  }
}

@mixin table-cell($side-paddings: cssvar(spacing-2)) {
  padding: cssvar(spacing-2) $side-paddings;
  border: solid cssvar(line-primary-color);
  border-width: 0 0 1px;
  text-align: left;
}
