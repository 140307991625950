@use '../common/config/all' as *;
@use '../common/utils/all' as *;

// ----------------------------------------------
// Fineprint (.[prefix-]fineprint)
// ----------------------------------------------

small#{$config-class-prefix}fineprint {
  @include show-as-fineprint;
  display: inline-block;
  margin: 0 0 cssvar(spacing-2);

  &--space-flush {
    margin-bottom: 0;
  }
}
