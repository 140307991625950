// ------------------------------------
// Language switcher - toggle and popup
// ------------------------------------
// .[prefix-]language-switcher
// .[prefix-]language-switcher-popup
// ------------------------------------
@use "sass:map";

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  // TOGGLE
  &language-switcher {
    display: flex;
    align-items: center;
    height: 100%;

    &__toggle {
      color: inherit;
      gap: cssvar(spacing-1);
      @include header-link;
    }

    // full width when it's used inside of burger menu widgets area
    #{$config-class-prefix}header-burger-menu__widgets &__toggle {
      width: 100%;
      padding: cssvar(spacing-1);
    }

    &__flag {
      display: inline-block;
      width: cssvar(spacing-4);
      height: cssvar(spacing-4);
      border-radius: 100%;
    }
  }

  // POPUP
  &language-switcher-popup {
    $language-switcher-popup: &;
    display: none;
    position: fixed;
    z-index: z('popup');
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-width: cssvar(page-min-width);
    max-width: cssvar(page-max-width);
    margin: 0 auto;
    padding: 0 cssvar(spacing-2);

    &:not([hidden]) {
      display: grid;
      grid-column-gap: cssvar(spacing-2);
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 100vh auto;
      align-items: center;

      @include on-bp(lg) {
        grid-template-columns: repeat(12, 1fr);
      }
    }

    &__body {
      display: grid;
      position: relative;
      z-index: z('popup') + 1;
      grid-column: 1 / span 4;
      grid-template-columns: auto min-content;
      height: fit-content;
      max-height: 80vh;
      padding: cssvar(spacing-4);
      overflow-y: auto;
      border-radius: cssvar(primary-border-radius);
      background: cssvar(box-bg-color);

      @include on-bp(lg) {
        grid-column: 2 / span 10;
      }
    }

    &__region {
      grid-column: 1;

      &:not(:last-child) {
        margin-bottom: cssvar(spacing-2);
        padding-bottom: cssvar(spacing-2);
        border-bottom: 1px solid cssvar(line-primary-color);
      }
    }

    &__list {
      @include list-reset;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: cssvar(spacing-1);

      &:not(:last-child) {
        margin-bottom: cssvar(spacing-2);
      }

      @include on-bp(md) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &__region--columns {
      #{$language-switcher-popup}__list {
        display: block;
        column-count: 2;

        @include on-bp(md) {
          column-count: 4;
        }
      }

      #{$language-switcher-popup}__item {
        // display flex somehow makes the items to be distributed nicely through the columns
        display: flex;
        // this prevents elements to be "teleported" partially between two columns in Safari
        break-inside: avoid;
      }
    }

    &__link:is(button) {
      @include btn-reset($padding: false);
    }

    &__link {
      @include nav-side-link;
    }

    &__close {
      top: 0;
      right: 0;
      grid-column: 2;
      grid-row: 1;
      align-self: start;

      &#{&} {
        // increased specificity
        position: sticky;
      }

      &::before {
        content: '';
        @include flix-icon-close($size: $icon-size-small-rem);
        @include flix-mask-icon-limits($icon-min-size: $icon-size-small);
      }
    }
  }
}
