// ----------------------------------------------
// accordion (.[prefix-]accordion)
// ----------------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &accordion {
    display: block;
    border: 1px solid cssvar(line-primary-color);
    font-family: inherit;

    // rounding top corners
    &:first-of-type {
      border-radius: cssvar(primary-border-radius) cssvar(primary-border-radius) 0 0;
    }

    // rounding bottom corners
    &:last-of-type {
      border-radius: 0 0 cssvar(primary-border-radius) cssvar(primary-border-radius);
    }

    // rounding all corners
    &:only-of-type {
      border-radius: cssvar(primary-border-radius);
    }

    &__title {
      @include show-as-h4;
      display: block;
      position: relative;
      padding: cssvar(spacing-2) cssvar(spacing-6) cssvar(spacing-2) cssvar(spacing-2);
      outline: 0;
      cursor: pointer;

      #{$config-class-prefix}icon {
        margin-right: cssvar(spacing-half);

        &::before {
          margin: 0; // reset default icon left margin
        }
      }

      // removing webkit's default arrow marker
      &::-webkit-details-marker {
        display: none;
      }

      &::after {
        @include flix-icon-arrow-down;
        content: '';
        position: absolute;
        top: 50%;
        right: cssvar(spacing-2);
        transform: translateY(-50%);
      }

      @include hover-state($enabled-only: false);
      @include pressed-state;
      @include focus-state($force-round-corners: true);
    }

    // "open" attribute is used in native <details>/<summary> HTML implementation
    // adding an "--open" modifier class as well for customizations and 3rd party scripts
    &[open],
    &--open {
      > #{$config-class-prefix}accordion__title::after {
        transform: translateY(-50%) rotate(180deg); // optimizing the file size achieving the same icon with transform
      }

      > #{$config-class-prefix}accordion__content {
        display: block;
      }
    }

    &__content {
      display: none;
      padding: cssvar(spacing-1) cssvar(spacing-2) cssvar(spacing-2);
    }

    & + & {
      border-top: 0;
    }
  }
}
