// ------------------------------------
// Header navigation
// ------------------------------------
// .[prefix-]header-burger-menu
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix}header-burger-menu {
  grid-area: burger;
  align-self: center;

  @include on-bp(xl) {
    position: absolute;
    left: $header-side-paddings;
  }

  &__overlay {
    display: none;
  }

  & > &__toggle {
    &::after {
      @include flix-icon-burger($color: $header-text-color, $size: cssvar(spacing-4));
      content: '';
    }
  }

  &__panel {
    position: fixed;
    z-index: z('header');
    top: 0;
    bottom: 0;
    left: -$panel-side-narrow-width;
    width: 85%;
    max-width: $panel-side-narrow-width;
    overflow-y: auto;
    border-radius: 0 $panel-border-radius $panel-border-radius 0;
    background: $panel-bg-color;
    color: cssvar(content-secondary-color);
    // improves scrolling on touch devices
    -webkit-overflow-scrolling: touch;
    @include transition(left);
  }

  // the toggle inside the panel has a height equal to the header min height on default font size.
  // btn--md size + spacing-2 top + spacing-2 bot
  &__panel > &__toggle {
    margin: cssvar(spacing-2);

    &::before {
      content: '';
      @include flix-icon-close($size: $icon-size-small-rem);
      @include flix-mask-icon-limits($icon-min-size: $icon-size-small);
    }
  }

  // expanded menu panel is controlled by aria-expanded value
  &__toggle[aria-expanded='true'] {
    & + #{$config-class-prefix}header-burger-menu__panel:not([hidden]) {
      left: 0;
    }

    & ~ #{$config-class-prefix}header-burger-menu__overlay {
      display: block;
      z-index: z('header') - 1;
    }
  }

  &__nav {
    position: initial;
    width: 100%;
  }

  &__list {
    @include list-reset;
  }

  &__item {
    border-top: 1px solid cssvar(line-primary-color);
  }

  &__link:is(button) {
    @include btn-reset($padding: false);
  }

  &__link {
    width: 100%;
    padding: cssvar(spacing-3) cssvar(spacing-4);
    color: cssvar(content-secondary-color);
    font-size: cssvar(font-size-primary);
    font-weight: cssvar(font-weight-semibold);
    line-height: cssvar(line-height-primary);
    text-align: left;
    text-decoration: none;
    @include icon-label;
    @include header-expandable-menu;

    &[aria-expanded]::after {
      background-color: cssvar(icon-primary-color);
    }

    @include selected-state;
    @include hover-state($enabled-only: false);
    @include pressed-state($enabled-only: false);

    &:focus-visible {
      @include focus-state-style($force-round-corners: true, $outline-offset: -2px);

      &::before {
        // active line has `position: absolute;` it always shows over the outline
        // so we hide the line when is focused
        opacity: 0;
      }
    }
  }

  &__text {
    display: block;
    width: 100%;
  }

  &__widgets--has-container {
    padding: cssvar(spacing-2) cssvar(spacing-4);
    border-top: 1px solid cssvar(line-primary-color);
  }
}

#{$config-class-prefix}header-burger-menu-subnav {
  display: none;
  padding: 0 cssvar(spacing-4) cssvar(spacing-2) cssvar(spacing-7);

  &__item {
    display: block;
  }

  &__link:is(button) {
    @include btn-reset($padding: false);
  }

  &__link {
    @include nav-side-link;
  }
}
