@use 'icon-label' as *;
@use 'states' as *;
@use 'cssvars' as *;
@use 'typography' as *;

@mixin nav-side-link {
  @include show-as-interactive-text;
  position: relative;
  width: stretch;
  margin: cssvar(spacing-half) 0;
  padding: cssvar(spacing-1) cssvar(spacing-half);
  border-radius: cssvar(primary-border-radius);
  text-decoration: none;
  cursor: pointer;

  @include icon-label;
  @include hover-state($enabled-only: false);
  @include pressed-state;
  @include focus-state($outline-offset: 0);

  &[aria-current] {
    padding-left: cssvar(spacing-2);
    @include selected-state-style($position: left);
  }
}
