// ------------------------------------
// choice field utilities
// ------------------------------------
/// @access private
// ------------------------------------

@use 'sass:map';
@use 'icons' as *;
@use 'cssvars' as *;
@use 'states' as *;
@use 'transitions' as *;

// ------------------------------------
// style variables
// ------------------------------------
$choice-sizes: ('sm': cssvar(spacing-3), 'md': cssvar(spacing-5));
$choice-danger-bg-shadow: inset 0 0 0 100vh cssvar(bg-primary-transparent-color), inset 0 0 0 100vh cssvar(danger-color);

// centralizes choice with first line based on line height and choice size
// max() function for finesse adjustment for browsers that support it in case we get negative result
@mixin centralize-with-first-line($choice-size) {
  margin-top: calc((#{cssvar(line-height-primary)} - #{$choice-size}) / 2);

  @supports (margin-top: max(0px, calc((#{cssvar(line-height-primary)} - #{$choice-size}) / 2))) {
    margin-top: max(0px, calc((#{cssvar(line-height-primary)} - #{$choice-size}) / 2));
  }
}

// ------------------------------------
// defines basic choice form elements styles
// ------------------------------------
@mixin basic-choice(
  $checkmark,
  $checkmark-indent: cssvar(spacing-1),
  $border-radius: cssvar(primary-border-radius)
) {
  position: relative;

  @each $size, $value in $choice-sizes {
    // md is our default size, no-modifier needed
    @if $size == 'md' {
      &__label {
        display: inline-flex;
        position: relative;
        align-items: center;
        min-height: $value; // matches checkbox height
        margin: calc(-1 * #{cssvar(spacing-half)});
        padding: cssvar(spacing-half);
        border-radius: cssvar(primary-border-radius);
        color: cssvar(content-primary-color);
        font-family: inherit;
        vertical-align: middle;
        cursor: pointer;
        gap: cssvar(spacing-2);

        &::before,
        &::after {
          content: '';
          display: block;
          flex-shrink: 0;
          align-self: flex-start;
        }

        &::before {
          width: $value;
          height: $value;
          border: 2px solid cssvar(input-border-color);
          border-radius: $border-radius;
          @include transition(border-color);
          @include centralize-with-first-line($value);
        }

        &::after {
          @include flix-icon-mask(
            $path: $checkmark,
            $color: cssvar(input-border-color),
            $width: $value,
            $height: $value,
            $maskSize: calc(#{$value} - #{$checkmark-indent}),
          );
          position: absolute;
          top: cssvar(spacing-half);
          left: cssvar(spacing-half);
          transform: scale(0);
          pointer-events: none;
          @include transition(transform, background-color);
          @include centralize-with-first-line($value);
        }
      }

      // makes labeless clickable
      &__input {
        width: $value;
        height: $value;
        @include centralize-with-first-line($value);
      }

      &__input:checked + &__label::after {
        transform: scale(1);
      }
    } @else {
      &--#{$size} &__label {
        min-height: $value; // matches checkbox height
        border-radius: cssvar(primary-border-radius);
        @include hover-state($enabled-only: false);

        &::after {
          mask-size: calc(#{$value} - #{$checkmark-indent});
        }
      }

      &--#{$size} &__input,
      &--#{$size} &__label::before,
      &--#{$size} &__label::after {
        width: $value;
        height: $value;
        @include centralize-with-first-line($value);
      }
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &__input:focus-visible + &__label::before {
    @include focus-state-style;
  }

  &__input:enabled + &__label:hover,
  &__input:enabled:hover + &__label {
    @include hover-state-style;
  }

  // coloring for checked state
  &__input:checked + &__label::before {
    border-color: cssvar(ui-primary-color);
  }

  &__input:checked + &__label::after {
    background-color: cssvar(ui-primary-color);
  }

  // disabled state, same logic as for other inputs in here
  &--disabled &__label {
    opacity: cssvar(disabled-element-opacity);
    cursor: not-allowed;
  }

  // error state colors
  &--error &__label::before {
    border-color: cssvar(danger-color);
  }

  &--error &__input:not(:checked) + &__label::before {
    // using box-shadow workaround in here to not override checkbox or radio checkmark images
    // @todo cleanup after this changes to be a bg-color
    box-shadow: $choice-danger-bg-shadow;
    @include transition(box-shadow);
  }

  // box-shadow workaround to apply double-outline for focused state
  // @todo cleanup after this changes to be a bg-color
  &--error &__input:focus-visible:not(:checked) + &__label::before {
    box-shadow: $choice-danger-bg-shadow, 0 0 0 4px cssvar(grayscale-0-color);
  }
}
