// ----------------------------------------------
// Overlay
// ----------------------------------------------
// .[prefix-]overlay

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix} {
  &overlay {
    position: fixed;
    // overlay z-index is just bellow the popup
    z-index: z('popup'); // this way it can be used with both popup and side panels
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: cssvar(overlay-bg-color);
  }
}
