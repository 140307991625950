// ------------------------------------
// Header nav
// ------------------------------------
// .[prefix-]header-nav
// ------------------------------------

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

$header-nav-height-tablet: cssvar(spacing-8);

#{$config-class-prefix}header-nav {
  grid-area: nav;
  grid-column: nav-start / -1; // making nav to extend to the last column when needed
  margin: 0 calc(#{cssvar(spacing-2)} * -1);
  background: cssvar(header-nav-bg-color-tablet);

  @include on-bp(xl) {
    margin: 0;
    background: none;

    // finest adjustment, better fit the nav to respect widgets space if browser supports it
    @at-root #{$config-class-prefix}header__inner:has(> #{$config-class-prefix}header-widgets) & {
      grid-column: nav-start / nav-end;
    }
  }

  &__list {
    @include list-reset($margin: 0 auto);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include on-bp(xl) {
      justify-content: flex-start;
      max-width: none;
      margin: 0;
    }
  }

  &__item {
    position: relative;
  }

  &__link {
    min-height: $header-nav-height-tablet;
    color: $header-text-color;
    @include header-link($padding: cssvar(spacing-2));

    @include on-bp(xl) {
      min-height: $header-min-height;
      padding: cssvar(spacing-3) cssvar(spacing-2);
    }

    @include selected-state($position: bottom, $highlight-bg: false, $color: $header-text-color, $border-color: $header-text-color);

    // when has expandable content
    &[aria-expanded]::after {
      align-self: stretch;
      height: auto;
    }
  }
}

#{$config-class-prefix}header-nav-subnav {
  // header submenu dropdown is closer to the item
  @include dropdown-items-bottom($top: 75%);

  &--left {
    @include dropdown-items-left;
  }

  &--right {
    @include dropdown-items-right;
  }

  &__item {
    @include dropdown-item;
  }

  &__link {
    text-align: left;
    @include dropdown-link-content;

    &:is(a, button) {
      @include dropdown-link-interactions;
    }
  }
}
