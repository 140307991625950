// ----------------------------------------------
// Table
// ----------------------------------------------
// .[prefix-]table
// .[prefix-]table__wrap

@use '../common/config/all' as *;
@use '../common/utils/all' as *;

#{$config-class-prefix}table {
  border-spacing: cssvar(spacing-4) 0;
  border-collapse: separate;

  @include table-caption;

  &__wrap {
    display: inline-block;
    margin-bottom: cssvar(spacing-2);
    padding: cssvar(spacing-2) 0;
    border: 1px solid cssvar(line-primary-color);
    border-radius: cssvar(primary-border-radius);
  }

  caption {
    padding-bottom: 0;
  }

  th,
  td {
    @include table-cell(0);
    @include show-as-text;
  }

  &__header,
  &__footer {
    th,
    td {
      @include table-cell(0);
      @include show-as-h4;
    }
  }

  tr:last-child {
    th,
    td {
      border-width: 0;
    }
  }

  & &__header tr th,
  & &__header tr td {
    border-width: 0 0 1px;
  }

  & &__footer tr th,
  & &__footer tr td {
    border-width: 1px 0 0;
  }
}
