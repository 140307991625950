// ------------------------------------
// Balloon utilities
// Used by Tooltip, Dropdown and Header components
// ------------------------------------
/// @access private
// ------------------------------------

@use '../config/all' as *;
@use 'icons' as *; // pulls close icon
@use 'cssvars' as *;
@use 'positioning' as *;

$balloon-arrow-width: calc(cssvar(spacing-1) + 1px);
$balloon-width-small: 15.75rem;   // 252px = 6 * 42
$balloon-width-medium: 23.25rem; // 372px = 6 * 62
$balloon-width-large: 30.75rem;   // 492px = 6 * 82
$balloon-height: 44px;

%balloon {
  @include cssvars((
    balloon-width: $balloon-width-small,
    balloon-height: $balloon-height,
    balloon-background: cssvar(box-bg-color),
    balloon-color: cssvar(content-primary-color),
    balloon-close-color: cssvar(icon-primary-color),
    balloon-content-margin: 0,
    balloon-close-margin: calc(-1 * #{cssvar(spacing-1)}) calc(-1 * #{cssvar(spacing-1)}) calc(-1 * #{cssvar(spacing-1)}) cssvar(spacing-2),
  ));
  position: relative;
  z-index: z('dropdown');
  justify-content: space-between;
  width: fit-content;
  min-width: cssvar(balloon-width);
  min-height: cssvar(balloon-height);
  color: cssvar(balloon-color);
  font-family: inherit;
  font-size: cssvar(font-size-primary);
  text-decoration: none; // prevents underlined text for tooltips assigned to a link
}

%balloon-display {
  display: flex;
}

// Balloon layered-faux-background
// Use padding as Balloon spacing without affecting the background.
// Can't use margin in order to enable mouse over events
%balloon-faux-background {
  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: cssvar(primary-border-radius);
    background: cssvar(balloon-background);
    box-shadow: cssvar(primary-box-shadow);
  }
}

%balloon-content {
  position: relative;
  z-index: z(base);
  flex: auto;
  margin: cssvar(balloon-content-margin);
  text-align: left;
}

%balloon-close {
  position: relative;
  z-index: z(base);
  align-self: flex-start;
  margin: cssvar(balloon-close-margin);

  &::before {
    content: '';
    @include flix-icon-close($color: cssvar(balloon-close-color), $size: cssvar(spacing-3));
  }
}

%balloon-danger {
  @include cssvars((
    balloon-background: cssvar(danger-color),
    balloon-color: cssvar(grayscale-0-color),
    balloon-close-color: cssvar(grayscale-0-color),
  ))
}

/** Size rules **/
// --content-fit makes the balloon width content aware
// it also halves the spacing between content and borders
// it does not allow line breaks
%balloon-content-fit {
  @include cssvars((
    balloon-width: auto,
    balloon-height: auto,
    // on small balloons the padding should be halved,
    // this hack reduces half the size of the padding from the margin
    // so we don't need to rewrite all of the paddings for each
    // positioning variable :big_brain:
    balloon-content-margin: calc(-1 * #{cssvar(spacing-1)}),
    balloon-close-margin: calc(-1 * #{cssvar(spacing-2)}) calc(-1 * #{cssvar(spacing-2)}) calc(-1 * #{cssvar(spacing-2)}) cssvar(spacing-2),
  ));
  white-space: nowrap;
}

%balloon-medium {
  @include cssvars((
    balloon-width: $balloon-width-medium,
  ));
}

%balloon-large {
  @include cssvars((
    balloon-width: $balloon-width-large,
  ));
}

/** Poisition rules **/
%balloon-position-right {
  padding: cssvar(spacing-2) cssvar(spacing-2) cssvar(spacing-2) cssvar(spacing-4);

  &::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: cssvar(spacing-2);
  }
}

%balloon-position-left {
  padding: cssvar(spacing-2) cssvar(spacing-4) cssvar(spacing-2) cssvar(spacing-2);

  &::before {
    top: 0;
    right: cssvar(spacing-2);
    bottom: 0;
    left: 0;
  }
}

%balloon-position-top {
  padding: cssvar(spacing-2) cssvar(spacing-2) cssvar(spacing-4);

  &::before {
    top: 0;
    right: 0;
    bottom: cssvar(spacing-2);
    left: 0;
  }
}

%balloon-position-bottom {
  padding: cssvar(spacing-4) cssvar(spacing-2) cssvar(spacing-2);

  &::before {
    top: cssvar(spacing-2);
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/** Arrow rules **/
%balloon-arrow {
  content: '';
  position: absolute;
  z-index: 0;
  width: 0;
  height: 0;
}

// left, right and vertical alignment
%balloon-arrow-left {
  inset: auto auto auto cssvar(spacing-1);
  border-top: $balloon-arrow-width solid transparent;
  border-right: $balloon-arrow-width solid cssvar(balloon-background);
  border-bottom: $balloon-arrow-width solid transparent;
  border-left: 0;
}

%balloon-arrow-right {
  inset: auto cssvar(spacing-1) auto auto;
  border-top: $balloon-arrow-width solid transparent;
  border-right: 0;
  border-bottom: $balloon-arrow-width solid transparent;
  border-left: $balloon-arrow-width solid cssvar(balloon-background);
}

%balloon-arrow-center-y {
  top: 50%;
  transform: translateY(-50%);
}

%balloon-arrow-start-y {
  top: min(25%, cssvar(spacing-3));
  transform: none;
}

%balloon-arrow-end-y {
  top: auto;
  bottom: min(25%, cssvar(spacing-3));
  transform: none;
}

// up, down and horizontal alignment
%balloon-arrow-up {
  inset: cssvar(spacing-1) auto auto;
  border-top: 0;
  border-right: $balloon-arrow-width solid transparent;
  border-bottom: $balloon-arrow-width solid cssvar(balloon-background);
  border-left: $balloon-arrow-width solid transparent;
}

%balloon-arrow-down {
  inset: auto auto cssvar(spacing-1);
  border-top: $balloon-arrow-width solid cssvar(balloon-background);
  border-right: $balloon-arrow-width solid transparent;
  border-bottom: 0;
  border-left: $balloon-arrow-width solid transparent;
}

%balloon-arrow-center-x {
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}

%balloon-arrow-start-x {
  left: min(25%, cssvar(spacing-3));
  transform: none;
}

%balloon-arrow-end-x {
  right: min(25%, cssvar(spacing-3));
  left: auto;
  transform: none;
}
